import React, { Component, Fragment, createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { Paper, Typography, FormControl,
    FormLabel, FormGroup, TextField } from '@material-ui/core'
import {Visibility as VisibilityIcon } from '@material-ui/icons'
import '../templates/FhP_Bootstrap_Template_v1.0/stylesheets/bootstrap.css'
import ImageAnnotator from '../image-annotations-component/src/ImageAnnotator'
import { updateAcquisition, getAcquisitionImagesCount, fetchAllImageSamples, getRawImageBlob, getNoWholeImageAnnotationsCount, getImageSample, updateSampleMetadataObservations, updateSampleMetadataTags } from '../requests/acquisitions'
import { annotationOptions, wholeImageAnnotationOptions, MESSAGES, AcquisitionOptions, requiredNrWholeImageAnnotations } from '../constants'
import { getAnnotationsCount, createUpdateAnnotation, deleteAnnotation, fetchAnnotations, deleteImageAnnotation } from '../requests/annotations'
import { deSerializeAnnotation } from '../utils/AnnotationParser'
import { stringToArray } from '../utils/other'

let resumeOption = false
const paginationSize = 100

class PaginatedAnnotatorContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            loadingText: '',
            imageTags: [],
            currentImageIndex: 0,
            observations: '',
            emptyRequiredField: true,
            canGoToNextImage: false
        }

        this.annotatorData = []
        this.imagesOnTheLoad = []
        this.pagination = {
            currentPage: 0,
            count: 0,
            totalPages: 0
        }
        this.startingImage = 0
        this.processingChanges = 0
        this.timer = 0
        this.imageAnnotator = createRef()

    }

    UNSAFE_componentWillMount () {
        resumeOption = true
        this.setState({loadingText: 'Loading Acquisition...'})
        this.loadImageSamples(this.props.acquisitionId)
    }

    componentWillUnmount () {
        clearInterval(this.timer)
        this.setState({
            imageTags: [],
            observations: ''
        })
        this.annotatorData = []
        this.imagesOnTheLoad = []
        this.pagination = {}
    }

    loadImageSamples (acqId) {
        getNoWholeImageAnnotationsCount(acqId)
            .then(nonAnnotatedCount => {
                getAcquisitionImagesCount(acqId)
                    .then(count => {
                        if (count !== 0) {
                            this.pagination.count = count;
                            this.pagination.totalPages = Math.ceil(count / paginationSize)
                            this.startingImage = count - nonAnnotatedCount
                            this.fetchImageSamples.bind(this)(0)
                            if (this.startingImage !== 0) {
                                for (let i = 1; i < Math.ceil((this.startingImage + 3) / paginationSize); i++) {
                                    this.fetchImageSamples.bind(this)(i)
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(`ERROR: Could not obtain number of image samples (${error.message})`)
                        throw error
                    })
            })
            .catch(error => {
                console.log(`ERROR: Could not obtain number of annotated image samples (${error.message})`)
                throw error
            })
        
    }

    fetchImageSamples (page) {
        fetchAllImageSamples (this.props.acquisitionId, {page, size: paginationSize, sort: 'id'})
            .then(this.loadImages.bind(this))
            .catch(error => {
                console.log(`ERROR: Could not obtain image samples (${error.message})`)
                throw error
            })
    }

    loadImages (images) {
        images = images.filter(img => !img.tags.includes('hemogram'))
        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                let index = (images[i].tags.includes('hemogram')
                    ? -1
                    : this.annotatorData.push({
                        acquisitionId: this.props.acquisitionId,
                        imageId: images[i].id,
                        imageFilename: images[i].metadata['filename'],
                        imageMetadata: images[i].metadata,
                        annotations: []
                    })
                )
                if (i === 0) {
                    this.loadImageData(index - 1)
                }
            }
        }
    }

    loadImageData (imageIndex) {
        if (imageIndex >= this.annotatorData.length || this.imagesOnTheLoad.includes(imageIndex))
            return
        this.imagesOnTheLoad.push(imageIndex)

        if (imageIndex === 0)
            this.setState({ loadingText: "Loading first image..." })

        let getRawImageCallback = (blob) => {
            this.imagesOnTheLoad = this.imagesOnTheLoad.filter(i => i !== imageIndex)
            this.annotatorData[imageIndex].imagePath = blob
            this.loadSampleAnnotations(imageIndex)
            if (this.imageAnnotator.current) {
                this.imageAnnotator.current.addPaginatedImage(imageIndex, this.annotatorData[imageIndex])
            }
        }

        getRawImageBlob(this.props.acquisitionId, this.annotatorData[imageIndex].imageId)
            .then(getRawImageCallback)
            .catch(error => {
                console.log(`ERROR: Could not get Raw Image (${error.message})`)
                throw error
            })
    }

    loadSampleAnnotations (imageIndex) {
        let getAnnotationsCountCallback = count => {
            this.annotatorData[imageIndex].annotationsCount = count
            if (count === 0) {
                if (imageIndex === 0)
                    this.setState({loading: false})
                return;
            } else {
                this.setState({loadingText: 'Loading image annotations ...'})
                fetchAnnotations(this.props.acquisitionId, this.annotatorData[imageIndex].imageId, 0, count)
                    .then(annotations => {
                        this.handleAnnotationsFetchEnd.bind(this)(imageIndex, annotations)
                    })
                    .catch(error => {
                        console.log(`ERROR: Could not fetch image sample annotatios (${error.message})`)
                        throw error
                    })
            }
        }

        getAnnotationsCount(this.props.acquisitionId, this.annotatorData[imageIndex].imageId)
            .then(count => getAnnotationsCountCallback(count))
    }

    handleAnnotationsFetchEnd (imageIndex, jsonAnnotations) {
        this.annotatorData[imageIndex].annotations = []
        for (let i = 0; i < jsonAnnotations.length; i++) {
            let annotation = deSerializeAnnotation(jsonAnnotations[i])
            if (annotation !== null) {
                this.annotatorData[imageIndex].annotations.push(annotation)
                if (this.imageAnnotator.current)
                    this.imageAnnotator.current.addPaginatedImage(imageIndex, this.annotatorData[imageIndex])
            }
        }
        if (imageIndex === 0) {
            if (this.annotatorData[imageIndex].annotations.length === this.annotatorData[imageIndex].annotationsCount) {
                this.setState({loading: false})
            } else {
                this.setState({loading: false, loadingText: 'Could not load all the annotations'})
            }
        }
    }

    loadNextImage (newImageIndex) {
        this.setState({ currentImageIndex: newImageIndex })

        let imagesBeforeNextPageLoad = 3
        if (newImageIndex + imagesBeforeNextPageLoad < this.pagination.count &&
            newImageIndex + imagesBeforeNextPageLoad >= this.annotatorData.length &&
            Math.ceil((newImageIndex + imagesBeforeNextPageLoad) / paginationSize) > this.pagination.currentPage) { //Load next batch of images
                this.fetchImageSamples.bind(this)(++this.pagination.currentPage)
        }

        // loading the next 2 images
        for (let i = 0; i <= 4; i++) {
            if (newImageIndex + i >= this.annotatorData.length || this.isSampleLoaded(newImageIndex + i))
                continue;
            this.loadImageData(newImageIndex + i)
        }

        // loading the previous 2 images
        for (let i = 1; i <= 4; i++) {
            if (newImageIndex - i < 0)
                break;
            if (newImageIndex - i >= this.annotatorData.length || this.isSampleLoaded(newImageIndex - i))
                continue;
            this.loadImageData(newImageIndex - i)
        }
    }

    isSampleLoaded(imageIndex) {
        return this.annotatorData[imageIndex] &&
            this.annotatorData[imageIndex] !== undefined &&
            this.annotatorData[imageIndex].imagePath !== undefined &&
            this.annotatorData[imageIndex].imagePath !== ""
    }

    isOptionSelected(optKeyName) {
        if (this.state.imageTags.includes(optKeyName))
            return true
        return false
    }

    emptyRequiredFields(selectedOptions) {
        const requiredFields = AcquisitionOptions.filter(obj => obj.required >= 1)

        for (let i = 0; i < requiredFields.length; i++) {
            let hasOption = false
            for (let j = 0; j < requiredFields[i].options.length; j++) {
                if (selectedOptions.includes(requiredFields[i].options[j].keyName))
                    hasOption = true
            }
            if (!hasOption)
                return true
        }
        return false
    }

    hasMinAnnotations(annotations, tags) {
        let nrGps = tags.map(el => el.split("_")[0])
        nrGps = [...new Set(nrGps)]
        if (nrGps.length >= requiredNrWholeImageAnnotations)
            return true
        else return false
    }

    handleAnnotationChanges (annotator) {
        if (this.props.watchMode)
            return;

        let nextAvailability = (annots) => {
            if (this.hasMinAnnotations(annots.resultingList, this.state.imageTags))
                this.setState({canGoToNextImage: true})
            else
                this.setState({canGoToNextImage: false})
        }

        annotator.annotations.forEach((changedAnnot, indx) => {
            if (changedAnnot.shape === 'IMAGECATEGORY') {
                let cgtnI = this.hasMinAnnotations(annotator.internalAnnotations, this.state.imageTags)
                this.setState({canGoToNextImage: cgtnI})
            }
     
            if (annotator.action === 'ADD') {
                createUpdateAnnotation(this.props.acquisitionId, changedAnnot, this.annotatorData[this.state.currentImageIndex].imageId)
                .then(async (jsonAnnotation) => {
                    annotator.internalAnnotations[indx].setAnnotation(deSerializeAnnotation(jsonAnnotation[0]))
                })
                .then(nextAvailability(annotator))
            } else if (annotator.action === 'UPDATE') {
                createUpdateAnnotation(this.props.acquisitionId, changedAnnot, this.annotatorData[this.state.currentImageIndex].imageId)
                .then(nextAvailability(annotator))
            } else if (annotator.action === 'DELETE') {
                if (changedAnnot.shape === 'IMAGECATEGORY')
                    deleteImageAnnotation(this.props.acquisitionId, changedAnnot, this.annotatorData[this.state.currentImageIndex].imageId)
                    .then(nextAvailability(annotator))
                else
                    deleteAnnotation(this.props.acquisitionId, changedAnnot, this.annotatorData[this.state.currentImageIndex].imageId)
                    .then(nextAvailability(annotator))
            }
        })

        
    }

    handleImageChange (imgIndex) {
        this.loadNextImage(imgIndex)
        if (resumeOption && this.startingImage !== 0) {
            resumeOption = false
            if (window.confirm(MESSAGES.resumeAnnotation)) {
                if (this.imageAnnotator && this.annotatorData.length >= (this.startingImage - 1)) {
                    this.loadNextImage(this.startingImage-1)
                    this.timer = setInterval(() => {
                        if (this.annotatorData[this.startingImage-1].annotations.length > 0) {
                            this.imageAnnotator.current.changeImage(this.startingImage-1)
                            clearInterval(this.timer)
                        }
                    }, 500)
                }
            }
        }

        let cgtnI = this.hasMinAnnotations(this.annotatorData[imgIndex].annotations, stringToArray(this.annotatorData[imgIndex].imageMetadata['tags']))
        this.setState({
            currentImageIndex: imgIndex,
            imageTags: stringToArray(this.annotatorData[imgIndex].imageMetadata['tags']),
            observations: this.annotatorData[imgIndex].imageMetadata['observations'] || '',
            canGoToNextImage: cgtnI || (keycloak.hasRealmRole('admin') && this.props.watchMode)
        })
    }

    render () {
        return (
            <Fragment>
                {this.state.loading && (
                    <p>{this.state.loadingText ? this.state.loadingText : 'Loading ...'}</p>
                )}
                {!this.state.loading && this.annotatorData.length > 0 && (
                    <Fragment>
                        {this.props.watchMode && (
                            <Fragment>
                                <Paper className="watchMode_label">
                                    <VisibilityIcon /> 
                                    <Typography variant="h5"> Watch Mode </Typography>
                                </Paper>
                                <p>Current Image's {this.annotatorData[this.state.currentImageIndex]['imageFilename'] &&
                                    `Filename: ${this.annotatorData[this.state.currentImageIndex]['imageFilename']}    |    `
                                }</p>
                                <p>{this.annotatorData[this.state.currentImageIndex]['imageId'] &&
                                    `ID: ${this.annotatorData[this.state.currentImageIndex]['imageId']}`
                                }</p>
                            </Fragment>
                        )}
                        

                        <Paper style={{display: 'flex', flexDirection: 'column', padding: '1rem', paddingRight: 0, marginBottom: '1rem'}}>
                            <div id='imageMetadata'>
                                {Object.entries(this.annotatorData[this.state.currentImageIndex]['imageMetadata']).map((attr, idx) => {
                                    if (!["sampleId", "tags", "observations"].includes(attr[0])) {
                                        return (
                                            <Typography>
                                                {attr[0] === "filename" ? (<strong>{"Nome do Ficheiro: "}</strong>) : (
                                                    <strong>{attr[0] + ": "}</strong>
                                                )}
                                                <p>{attr[1]}</p>
                                            </Typography>
                                        )
                                    }
                                })}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div id='annotationContainer'>
                                    <ImageAnnotator
                                        ref={this.imageAnnotator}
                                        data={this.annotatorData}
                                        dataLength={this.pagination.count}
                                        annotationShapes={["Draw", "Polygon"]}
                                        // check this
                                        imageToolsEnabled={false} // allows to change brightness and contrast
                                        annotationCategories={annotationOptions}
                                        wholeImageAnnotationCategories={wholeImageAnnotationOptions}
                                        editEnabled={true}
                                        drawingEnabled={!this.watchMode}
                                        enabledNextImage={
                                            (keycloak.hasRealmRole('admin') && this.props.watchMode) || (this.state.canGoToNextImage)
                                        }
                                        nextImageTooltip={MESSAGES.missingImageQuality}
                                        notifyChangesCallback={this.handleAnnotationChanges.bind(this)}
                                        // check this: rotates the image according to EXIF info
                                        dontUseExifOrientation={false}
                                        onImageChanged={this.handleImageChange.bind(this)}
                                        onFinish={() => {
                                            const newMetadata = {
                                                ...this.props.acqMetadata,
                                                done: 'true'
                                            }

                                            if (this.state.canGoToNextImage) {
                                                updateAcquisition(
                                                    this.props.acquisitionId,
                                                    {
                                                        metadata: newMetadata,
                                                        tags: []
                                                    }
                                                ).then(() => {
                                                    this.props.history.push(`/acquisitions`)
                                                })
                                            } else {
                                                if (!window.confirm(MESSAGES.missingImageQuality)) {
                                                    return; 
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <Fragment>
                                    <Paper id="annotationSideContainer">
                                        <Fragment>
                                            {AcquisitionOptions.map((elem, ind) => (
                                                <div key={ind} style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginBottom: '0.5rem'
                                                }}>
                                                    <FormControl disabled={this.props.watchMode}>
                                                        <FormLabel className="annotateAcquisition_label" >
                                                            {elem.required ? (elem.group + ' *') : elem.group}
                                                        </FormLabel>
                                                        <FormGroup className={ elem.options.length <= 2 ? "acquisitionOptions_row_list" : '' }>
                                                            {elem.options.map((opt, jnd) => {
                                                                if (elem.required && elem.maxSelections === 1) {
                                                                    return (
                                                                        <div>
                                                                            <input type="radio"
                                                                                className="acquisitionOption"
                                                                                id={opt.keyName}
                                                                                name={opt.keyName}
                                                                                value={opt.keyName}
                                                                                checked={this.isOptionSelected(opt.keyName)}
                                                                                onChange={(event) => {
                                                                                    if (!this.state.imageTags.includes(opt.keyName)) {
                                                                                        let newTags = this.state.imageTags
                                                                                        newTags = newTags.filter(tag => !elem.options.map(opt => opt.keyName).includes(tag))
                                                                                        newTags.push(opt.keyName)

                                                                                        let sampleId = this.annotatorData[this.state.currentImageIndex].imageId
                                                                                        let sTags = newTags.join()

                                                                                        updateSampleMetadataTags(this.props.acquisitionId, sampleId, sTags)
                                                                                        .then(() => {
                                                                                            getImageSample(this.props.acquisitionId, sampleId).then((uSample) => {
                                                                                                this.loadSampleAnnotations(this.state.currentImageIndex)
                                                                                                let nImageTags = stringToArray(uSample.metadata['tags'])
                                                                                                let cgtnI = this.hasMinAnnotations(this.annotatorData[this.state.currentImageIndex].annotations, nImageTags)
                                                                                                
                                                                                                this.setState({
                                                                                                    imageTags: nImageTags,
                                                                                                    canGoToNextImage: cgtnI
                                                                                                })
                                                                                                this.annotatorData[this.state.currentImageIndex].imageMetadata = {
                                                                                                    ...this.annotatorData[this.state.currentImageIndex].imageMetadata,
                                                                                                    'tags': sTags
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                        
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <label for={opt.keyName}>{opt.name}</label>
                                                                        </div>
                                                                    
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div className="checkboxItem">
                                                                            <input type="checkbox"
                                                                                className='clinicalDecision_checkbox'
                                                                                checked={this.isOptionSelected(opt.keyName)}
                                                                                value={opt.keyName}
                                                                                onChange={(event) => {
                                                                                    let newTags = this.state.imageTags
                                                                                    if (this.state.imageTags.includes(opt.keyName)) {
                                                                                        newTags = newTags.filter(obj => obj !== opt.keyName)
                                                                                    } else {
                                                                                        newTags = [...this.state.imageTags, opt.keyName]
                                                                                        if (opt.exclusive && newTags != undefined) {
                                                                                            // removes remaining options of the same group
                                                                                            newTags = newTags.filter(el => el.split("_")[0] != opt.keyName.split("_")[0])
                                                                                            newTags.push(opt.keyName)
                                                                                        } else {
                                                                                            // get group of selected option
                                                                                            let gp = opt.keyName.split("_")[0]
                                                                                            // remove exclusive options within the same group on newTags if they exist
                                                                                            AcquisitionOptions.forEach(grp => {
                                                                                                if (grp.options[0].keyName.split("_")[0] == gp) {
                                                                                                    let exclusiveOpt = grp.options.filter(exc => exc.exclusive === true)
                                                                                                    exclusiveOpt.map(exclusiveOption => {
                                                                                                        if (newTags.includes(exclusiveOption.keyName)) {
                                                                                                            newTags = newTags.filter(tag => tag != exclusiveOption.keyName)
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    }

                                                                                    let sampleId = this.annotatorData[this.state.currentImageIndex].imageId
                                                                                    let sTags = newTags.join()

                                                                                    updateSampleMetadataTags(this.props.acquisitionId, sampleId, sTags)
                                                                                    .then(() => {
                                                                                        getImageSample(this.props.acquisitionId, sampleId).then((uSample) => {
                                                                                            this.loadSampleAnnotations(this.state.currentImageIndex)
                                                                                            let nImageTags = stringToArray(uSample.metadata['tags'])
                                                                                            let cgtnI = this.hasMinAnnotations(this.annotatorData[this.state.currentImageIndex].annotations, nImageTags)

                                                                                            this.setState({
                                                                                                imageTags: nImageTags,
                                                                                                canGoToNextImage: cgtnI
                                                                                            })
                                                                                            this.annotatorData[this.state.currentImageIndex].imageMetadata = {
                                                                                                ...this.annotatorData[this.state.currentImageIndex].imageMetadata,
                                                                                                'tags': sTags
                                                                                            }
                                                                                        })
                                                                                    })

                                                                                }}
                                                                            />
                                                                            <label for={opt.keyName}>{opt.name}</label>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </FormGroup>
                                                    </FormControl>
                                                    {elem.observations && (
                                                        <Fragment>
                                                            <FormLabel className="annotateAcquisition_label"
                                                                style={{marginTop: '1.5rem'}}
                                                            >
                                                                Observações:
                                                            </FormLabel>
                                                            <textarea
                                                                label='Observations'
                                                                id='text_observations'
                                                                rows={4}
                                                                style={{width: '100%'}}
                                                                value={this.state.observations}
                                                                disabled={this.props.watchMode}
                                                                onChange={event => this.setState({observations: event.target.value})}
                                                                onBlur={event => {
                                                                    let observ = this.state.observations
                                                                    let sampleId = this.annotatorData[this.state.currentImageIndex].imageId

                                                                    updateSampleMetadataObservations(this.props.acquisitionId, sampleId, observ)
                                                                    .then(() => {
                                                                        this.annotatorData[this.state.currentImageIndex].imageMetadata = {
                                                                            ...this.annotatorData[this.state.currentImageIndex].imageMetadata,
                                                                            'observations': observ
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </Fragment>
                                                    )}
                                                </div>
                                            ))}
                                        </Fragment>
                                    </Paper>
                                </Fragment>
                            </div>
                        </Paper>
                    </Fragment>
                )}
            </Fragment>
        )
    }
}

export default withRouter(PaginatedAnnotatorContainer)