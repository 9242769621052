import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom"
import { AppBar, Toolbar, Typography, MenuItem, MenuList, Button, IconButton, ButtonGroup, Popper, Grow, Paper, ClickAwayListener } from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@material-ui/icons'


export const Header = () => (
    <AppBar id="appHeader">
        <Toolbar>
            <Typography>
                Wounds Annotator
            </Typography>
            <MenuList id="menuList">
                {keycloak.hasRealmRole('admin') &&            
                    <MenuItem>
                        <Link to="/datasets">Datasets</Link>
                    </MenuItem>
                }
                <MenuItem>
                    <Link to="/acquisitions">Acquisitions</Link>
                </MenuItem>
                {keycloak.hasRealmRole('admin') &&
                    <MenuItem>
                        <Link to="/users/">Users</Link>
                    </MenuItem>
                }
                <LogoutBtn />
            </MenuList>
        </Toolbar>
    </AppBar>
)

class LogoutBtn extends Component {
    constructor (props) {
        super(props)
        this.state = {open: false}
        this.anchorRef = React.createRef()
    }

    render () {
        return (
            <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '2rem'}}>
                <ButtonGroup ref={this.anchorRef}>
                    <Button style={{border: 'none'}} disabled>
                        {keycloak.idTokenParsed.name ? (
                            <Typography variant='body1' style={{color: 'white'}}>
                                {keycloak.idTokenParsed.name}
                            </Typography>
                        ) : (
                            <Typography variant='body1' style={{color: 'white'}}>
                                {keycloak.idTokenParsed.preferred_username}
                            </Typography>
                        )}
                    </Button>
                    <Button style={{border: 'none'}} onClick={() => this.setState({open: !this.state.open})}>
                        {this.state.open
                            ? <ArrowDropUpIcon style={{color: '#fff'}} />
                            : <ArrowDropDownIcon style={{color: '#fff'}} />
                        }
                    </Button>
                </ButtonGroup>
                <Popper open={this.state.open} anchorEl={this.anchorRef.current} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper id="menu-list-grow">
                                <MenuList>
                                    <MenuItem onClick={() => keycloak.logout()}>
                                        Log Out
                                    </MenuItem>
                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        )
    }
}
