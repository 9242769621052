
export const stringToArray = function (string) {
    if (string != undefined && string != "")
        return string.split(",")
    else
        return []
}

export const excelDate2JSDate = function (val) {
    const res = (val - 25569) * 24 * 60 * 60 * 1000
    let options = { year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute: 'numeric', second: 'numeric' }
    const dt = new Date(res)
    return dt.toLocaleString('en-GB', options)
}
