import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';

function ZoomToolButtons (props) {
  const { zoomComponentRef, zoomAmount } = props;

  const onZoomIn = () => zoomComponentRef.current.updateScale(true, zoomAmount);
  const onZoomOut = () => zoomComponentRef.current.updateScale(false, zoomAmount);

  return (
    <div className="zoomToolButtonsContainer" >
      <label htmlFor="ZoomInTool" className={'btn btn-default btn-sm'} title="Zoom in">
        <input id="ZoomInTool" className="form-control" type="radio" value="ZoomInTool" name={'ZoomInTool'}
          onClick={onZoomIn}
          readOnly checked
        />
        <AiOutlineZoomIn />
      </label>
      <label htmlFor="ZoomOutTool" className={'btn btn-default btn-sm'} title="Zoom out">
        <input id="ZoomOutTool" className="form-control" type="radio" value="ZoomOutTool" name={'annotationShape'}
          onClick={onZoomOut}
          readOnly checked
        />
        <AiOutlineZoomOut />
      </label>
    </div>
  );
}

ZoomToolButtons.defaultProps = {
  zoomAmount: 1.2
};
ZoomToolButtons.propTypes = {
  zoomComponentRef: PropTypes.object.isRequired,
  zoomAmount: PropTypes.number.isRequired
};
export default ZoomToolButtons;
