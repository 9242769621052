import {BASE_URL} from '../constants/index'
import { serializeAnnotation } from '../utils/AnnotationParser'


export async function createUpdateAnnotation(acqId, annotation, annotatedSampleId) {
    let resp = undefined
    const bodyReq = JSON.stringify([serializeAnnotation(annotation, annotatedSampleId)])
    await fetch(`${BASE_URL}acquisitions/${acqId}/annotations/save`, {
        method: 'POST',
        body: bodyReq,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {
        resp = data
    })
    .catch(err => err)
    
    return resp
}

export async function deleteImageAnnotation(acqId, annotation, annotatedSampleId) {
    const bodyReq = JSON.stringify([serializeAnnotation(annotation, annotatedSampleId)])
    await fetch(`${BASE_URL}acquisitions/${acqId}/annotations`, {
        method: 'DELETE',
        body: bodyReq,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    })
    .then()
    .then(data => data)
    .catch(err =>  {
        console.log(err)
        return err
    })
}

export async function deleteAnnotation(acqId, annotation, annotatedSampleId) {
    const bodyReq = JSON.stringify([serializeAnnotation(annotation, annotatedSampleId)])
    await fetch(`${BASE_URL}acquisitions/${acqId}/annotations`, {
        method: 'DELETE',
        body: bodyReq,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => data)
    .catch(err =>  {
        console.log(err)
        return err
    })
}

export const getAnnotationsCount = (acqId, annotId) => {
    return fetch(`${BASE_URL}acquisitions/${acqId}/annotations/count?`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then()
    .then(response => response.json())
    .then(count => Number(count))
    .catch(error => {
        console.error('error:', error)
        throw error
    })
}

export const fetchAnnotations = (acqId, sampleId, page, size) => {
    return fetch(`${BASE_URL}acquisitions/${acqId}/annotations?page=${page}&size=${size}&annotatedSampleId=${sampleId}`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then()
    .then(resp => resp.json())
    .catch(error => {
        console.log(`ERROR: `, error)
        throw error
    })
}

export const getAnnotations = async (acqId, sampleId) => {
    return fetch(`${BASE_URL}acquisitions/${acqId}/annotations?size=-1&annotatedSampleId=${sampleId}`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then()
    // .then(data => data.json())
    .then(annotations => annotations)
    .catch(error => {
        console.log(`ERROR: ${error}`)
        throw error;
    })
}