import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { ContextMenu, MenuItem } from 'react-contextmenu';
import './react-contextmenu.css';

import { observer } from 'mobx-react';
import store from './../../models/annotations-store';
import appPropStore from './../../models/app-properties-store';

import '../prompts/PromptPlugins';
import Popup from 'react-popup';

@observer
class AnnotatorContextMenu extends Component {
  static propTypes = {
    menuId: PropTypes.string,
    updateAnnotationsInfo: PropTypes.func,
    deleteAnnotations: PropTypes.func
  };

  constructor () {
    super();

    this.state = {
      hide: false
    };

    this.handleToggleSelectionClick = this.handleToggleSelectionClick.bind(this);
    this.editSelected = this.editSelected.bind(this);
    this.deleteSelected = this.deleteSelected.bind(this);
  }

  // componentWillReceiveProps () {
  //   //TODO: not the finest hack to show/hide contextmenu after clicks
  //   if (this.state.hide) this.setState({ hide: false });
  // }

  componentDidUpdate () {
    //TODO: not the finest hack to update the show/hide contextmenu after clicks
    if (this.state.hide) this.setState({ hide: false });
  }

  handleToggleSelectionClick (e, data) {
    this.toggleSelection(data.selectValue);
  }

  toggleSelection (selectValue) {
    for (const annotation of store.annotations) {
      annotation.toggleSelected(selectValue);
    }
  }

  editSelected (e) {
    let pointerPosition = { x: e.clientX, y: e.clientY };
    Popup.plugins().multipleAnnotationsSelected(
      pointerPosition,
      store.annotationCategories,
      (notes, annotationType) => this.updateAllAnnotations(notes, annotationType),
      () => this.deleteSelected(),
      () => { }
    );
  }

  deleteSelected () { this.props.deleteAnnotations(this._getSelectedAnnotations()); }

  updateAllAnnotations (notes, annotationType) {
    const selectedAnnotations = this._getSelectedAnnotations();
    this.props.updateAnnotationsInfo(selectedAnnotations, notes, annotationType);
    selectedAnnotations.forEach(ant => { ant.toggleSelected(false); });
  }

  _getSelectedAnnotations = () => store.annotations.filter(ant => ant.isSelected);

  render () {
    let isAnAnnotationSelected = false;
    for (const annotation of store.annotations) {
      if (annotation.isSelected) { isAnAnnotationSelected = true; break; }
    }

    return (
      <Fragment>
        { !this.state.hide && appPropStore.isEditEnabled &&
        <ContextMenu id={this.props.menuId} hideOnLeave onHide={() => this.setState({ hide: true })} >
          <MenuItem data={{ selectValue: true }} onClick={this.handleToggleSelectionClick} preventClose >
            Select all annotations
          </MenuItem>
          <MenuItem data={{ selectValue: null }} onClick={this.handleToggleSelectionClick} preventClose>
            Invert selection
          </MenuItem>
          <MenuItem data={{ selectValue: false }} onClick={this.handleToggleSelectionClick}>
            Clear selection
          </MenuItem>
          <MenuItem divider />
          <MenuItem disabled={!isAnAnnotationSelected} onClick={this.editSelected}>
            Edit selected
          </MenuItem>
          <MenuItem disabled={!isAnAnnotationSelected} onClick={this.deleteSelected}>
            Delete selected
          </MenuItem>
        </ContextMenu>
        }
      </Fragment>
    );
  }
}

AnnotatorContextMenu.propTypes = AnnotatorContextMenu.propTypes;
export default AnnotatorContextMenu;
