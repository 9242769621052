import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  confirmButton: {
    'color': 'white',
    'backgroundColor': 'rgb(20, 134, 107)',
    '&:hover': {
      backgroundColor: 'rgb(20, 134, 107)'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(20, 134, 107)',
      borderColor: 'white'
    }
  }
}));

export default function AlertDialog ({ title, description, handleCancel, handleConfirm }) {
  const classes = useStyles();
  return (
    <Dialog
      open
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} autoFocus className={classes.confirmButton}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
