import React from 'react';
import { RectangleAnnotator, calculateRectViewDimensions } from './Rectangle/RectangleAnnotator';
import { CircleAnnotator, calculateCircleViewDimensions } from './Circle/CircleAnnotator';
import { EllipseAnnotator, calculateEllipseViewDimensions } from './Ellipse/EllipseAnnotator';
import { PolygonAnnotator, calculatePolygonViewDimensions } from './Polygon/PolygonAnnotator';
import { DrawAnnotator, calculateDrawViewDimensions } from './Draw/DrawAnnotator';
import { PointAnnotator, calculatePointViewDimensions } from './Point/PointAnnotator';
import { RectangleAnnotation } from './Rectangle/RectangleAnnotation';
import { CircleAnnotation } from './Circle/CircleAnnotation';
import { EllipseAnnotation } from './Ellipse/EllipseAnnotation';
import { PolygonAnnotation } from './Polygon/PolygonAnnotation';
import { DrawAnnotation } from './Draw/DrawAnnotation';
import { PointAnnotation } from './Point/PointAnnotation';
import { RectangleEditor } from './Rectangle/RectangleEditor';
import { CircleEditor } from './Circle/CircleEditor';
import { EllipseEditor } from './Ellipse/EllipseEditor';
import { PolygonEditor } from './Polygon/PolygonEditor';
import { PointEditor } from './Point/PointEditor';
import { DrawEditor } from './Draw/DrawEditor';
import SelectionTool from './SelectionTool/SelectionTool';
import { BsSquare, BsPencil, BsCircle, BsCircleHalf, BsDot, BsCursor } from 'react-icons/bs';
import { FiHexagon } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

const AnnotationFactory = {

  calculateViewDimensions (annotation, windowDims, imageDims) {
    if (annotation.annotation !== undefined && annotation.annotation.shape !== undefined) {
      switch (annotation.annotation.shape) {
      case 'Rect':
        return (calculateRectViewDimensions(annotation, windowDims, imageDims));
      case 'Circle':
        return (calculateCircleViewDimensions(annotation, windowDims, imageDims));
      case 'Ellipse':
        return (calculateEllipseViewDimensions(annotation, windowDims, imageDims));
      case 'Polygon':
        return (calculatePolygonViewDimensions(annotation, windowDims, imageDims));
      case 'Draw':
        return (calculateDrawViewDimensions(annotation, windowDims, imageDims));
      case 'Point':
        return (calculatePointViewDimensions(annotation, windowDims, imageDims));
      default:
        console.error(`Invalid annotation shape '${ annotation.shape }'`);
      }
    }
  },

  createAnnotationComponent (annotation) {
    if (annotation == null || annotation === undefined || annotation === {} || annotation.isHidden || Object.keys(annotation.annotation).length === 0) return;
    switch (annotation.annotation.shape) {
    case 'Rect':
      return (<RectangleAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} />);
    case 'Circle':
      return (<CircleAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} />);
    case 'Ellipse':
      return (<EllipseAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} />);
    case 'Polygon':
      return (<PolygonAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} />);
    case 'Draw':
      return (<DrawAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} />);
    case 'Point':
      return (<PointAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} />);
    case 'IMAGECATEGORY':
      return;
    default:
      console.error(`Invalid annotation shape '${ annotation.shape }' on render`);
    }
  },

  createEditableAnnotationComponent (annotation, notifyChangesCallback, updateAnnotationInfo) {
    if (annotation == null ||
      annotation.isHidden ||
      annotation.annotation == null ||
      annotation.annotation === undefined ||
      annotation.annotation.shape === undefined ||
      Object.keys(annotation.annotation).length === 0) return;
    switch (annotation.annotation.shape) {
    case 'IMAGECATEGORY':
      return;
    case 'Rect':
      return (<RectangleEditor annotation={annotation} notifyChangesCallback={notifyChangesCallback} updateAnnotationInfo={updateAnnotationInfo} key={uuidv4()} />);
    case 'Circle':
      return (<CircleEditor annotation={annotation} notifyChangesCallback={notifyChangesCallback} updateAnnotationInfo={updateAnnotationInfo} key={uuidv4()} />);
    case 'Ellipse':
      return (<EllipseEditor annotation={annotation} notifyChangesCallback={notifyChangesCallback} updateAnnotationInfo={updateAnnotationInfo} key={uuidv4()} />);
    case 'Polygon':
      return (<PolygonEditor annotation={annotation} notifyChangesCallback={notifyChangesCallback} updateAnnotationInfo={updateAnnotationInfo} key={uuidv4()} />);
    case 'Draw':
      return (<DrawEditor annotation={annotation} notifyChangesCallback={notifyChangesCallback} updateAnnotationInfo={updateAnnotationInfo} key={uuidv4()} />);
    case 'Point':
      return (<PointEditor annotation={annotation} notifyChangesCallback={notifyChangesCallback} updateAnnotationInfo={updateAnnotationInfo} key={uuidv4()} />);
    default:
      console.error(`Invalid annotation shape '${ annotation.shape }' on render`);
    }
  },

  createPreviewAnnotationComponent (annotation) {
    if (annotation == null || annotation === undefined || annotation === {} || annotation.isHidden || Object.keys(annotation.annotation).length === 0) return;
    switch (annotation.annotation.shape) {
    case 'Rect':
      return (<RectangleAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} color="white" stroke={annotation.annotation.color || 'white'} opacity={0.5} />);
    case 'Circle':
      return (<CircleAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} color="white" stroke={annotation.annotation.color || 'white'} opacity={0.5} />);
    case 'Ellipse':
      return (<EllipseAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} color="white" stroke={annotation.annotation.color || 'white'} opacity={0.5} />);
    case 'Polygon':
      return (<PolygonAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} color="white" stroke={annotation.annotation.color || 'white'} opacity={0.5} />);
    case 'Draw':
      return (<DrawAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} color="white" stroke={annotation.annotation.color || 'white'} opacity={0.5} />);
    case 'Point':
      return (<PointAnnotation {...annotation} internalAttributes={annotation.internalAttributes} key={uuidv4()} color="white" stroke={annotation.annotation.color || 'white'} opacity={0.5} />);
    case 'IMAGECATEGORY':
      return;
    default:
      console.error(`Invalid annotation shape '${ annotation.shape }' on render of static algorith annotation`);
    }
  },

  createCanvasComponent (annotationShape, annotationTypes, callbacks, shapesLayerRef) {
    if (annotationShape === 'RectangleAnnotator') {
      return (<RectangleAnnotator annotationTypes={annotationTypes} addAnnotation={callbacks.add} />);
    } else if (annotationShape === 'CircleAnnotator') {
      return (<CircleAnnotator annotationTypes={annotationTypes} addAnnotation={callbacks.add} />);
    } else if (annotationShape === 'EllipseAnnotator') {
      return (<EllipseAnnotator annotationTypes={annotationTypes} addAnnotation={callbacks.add} />);
    } else if (annotationShape === 'PolygonAnnotator') {
      return (<PolygonAnnotator annotationTypes={annotationTypes} addAnnotation={callbacks.add} />);
    } else if (annotationShape === 'DrawAnnotator') {
      return (<DrawAnnotator annotationTypes={annotationTypes} addAnnotation={callbacks.add} />);
    } else if (annotationShape === 'PointAnnotator') {
      return (<PointAnnotator annotationTypes={annotationTypes} addAnnotation={callbacks.add} />);
    } else if (annotationShape === 'SelectionTool') {
      return (<SelectionTool annotationTypes={annotationTypes} updateAnnotationInfo={callbacks.update} removeAnnotation={callbacks.remove} shapesLayerRef={shapesLayerRef}/>);
    } else if (annotationShape === 'DeleteTool') {
      return;
    } else {
      console.error(`Invalid annotator type '${ annotationShape }'`);
    }
  },

  createAnnotationShapesSelectors (annotationTypes, onclickHandler, selectionTool, activeTool, isDrawingKeyDown, isDeleteKeyDown) {
    var shapes = [];
    if (annotationTypes === undefined || annotationTypes.includes('Rect')) {
      shapes.push(
        <label key="Rect" htmlFor="Rect" className={`btn ${activeTool === 'RectangleAnnotator' && !isDeleteKeyDown ? 'btn-primary' : 'btn-default'} btn-sm`} title="Rectangle" style={{ opacity: isDrawingKeyDown && activeTool === 'RectangleAnnotator' ? null : 0.4 }} >
          <input id="Rect" className="form-control" onClick={onclickHandler} type="radio" name="annotationShape" value="RectangleAnnotator" readOnly checked={activeTool === 'RectangleAnnotator'} />
          <BsSquare />
        </label>
      );
    }
    if (annotationTypes === undefined || annotationTypes.includes('Circle')) {
      shapes.push(
        <label key="Circle" htmlFor="Circle" className={`btn ${activeTool === 'CircleAnnotator' && !isDeleteKeyDown ? 'btn-primary' : 'btn-default'} btn-sm`} title="Circle" style={{ opacity: isDrawingKeyDown && activeTool === 'CircleAnnotator' ? null : 0.4 }}>
          <input id="Circle" className="form-control" onClick={onclickHandler} type="radio" name="annotationShape" value="CircleAnnotator" readOnly checked={activeTool === 'CircleAnnotator'} />
          <BsCircle />
        </label>
      );
    }
    if (annotationTypes === undefined || annotationTypes.includes('Ellipse')) {
      shapes.push(
        <label key="Ellipse" htmlFor="Ellipse" className={`btn ${activeTool === 'EllipseAnnotator' && !isDeleteKeyDown ? 'btn-primary' : 'btn-default'} btn-sm`} title="Ellipse" style={{ opacity: isDrawingKeyDown && activeTool === 'EllipseAnnotator' ? null : 0.4 }} >
          <input id="Ellipse" className="form-control" onClick={onclickHandler} type="radio" name="annotationShape" value="EllipseAnnotator" readOnly checked={activeTool === 'EllipseAnnotator'} />
          <BsCircleHalf />
        </label>
      );
    }
    if (annotationTypes === undefined || annotationTypes.includes('Polygon')) {
      shapes.push(
        <label key="Polygon" htmlFor="Polygon" className={`btn ${activeTool === 'PolygonAnnotator' && !isDeleteKeyDown ? 'btn-primary' : 'btn-default'} btn-sm`} title="Polygon" style={{ opacity: isDrawingKeyDown && activeTool === 'PolygonAnnotator' ? null : 0.4 }}>
          <input id="Polygon" className="form-control" onClick={onclickHandler} type="radio" name="annotationShape" readOnly value="PolygonAnnotator" checked={activeTool === 'PolygonAnnotator'} />
          <FiHexagon />
        </label>
      );
    }
    if (annotationTypes === undefined || annotationTypes.includes('Draw')) {
      shapes.push(
        <label key="Draw" htmlFor="Draw" className={`btn ${activeTool === 'DrawAnnotator' && !isDeleteKeyDown ? 'btn-primary' : 'btn-default'} btn-sm`} title="Free Drawing" style={{ opacity: isDrawingKeyDown && activeTool === 'DrawAnnotator' ? null : 0.4 }}>
          <input id="Draw" className="form-control" onClick={onclickHandler} type="radio" name="annotationShape" readOnly value="DrawAnnotator" checked={activeTool === 'DrawAnnotator'} />
          <BsPencil />
        </label>
      );
    }
    if (annotationTypes === undefined || annotationTypes.includes('Point')) {
      shapes.push(
        <label key="Point" htmlFor="Point" className={`btn ${activeTool === 'PointAnnotator' && !isDeleteKeyDown ? 'btn-primary' : 'btn-default'} btn-sm`} title="Point" style={{ opacity: isDrawingKeyDown && activeTool === 'PointAnnotator' ? null : 0.4 }}>
          <input id="Point" className="form-control" onClick={onclickHandler} type="radio" name="annotationShape" readOnly value="PointAnnotator" checked={activeTool === 'PointAnnotator'} />
          <BsDot/>
        </label>
      );
    }

    if (selectionTool) {
      shapes.push(
        <div key="SelectionTool">
          <div style={{ fontSize: 'x-small', marginTop: '10px', marginBottom: '10px' }}><b style={{ fontSize: 'x-small' }}>Tools</b></div>
          <label htmlFor="SelectionTool" className={`btn ${activeTool === 'SelectionTool' && !isDeleteKeyDown ? 'btn-primary' : 'btn-default'} btn-sm`} title="Selection Tool" style={{ opacity: isDrawingKeyDown && activeTool === 'SelectionTool' ? null : 0.4 }}>
            <input id="SelectionTool" className="form-control" onClick={onclickHandler} type="radio" name="annotationShape" readOnly value="SelectionTool" checked={activeTool === 'SelectionTool'} />
            <BsCursor />
          </label>
        </div>
      );
    }

    return shapes;
  },

  createEditorComponent (annotation) {
    if (annotation == null) return;
    switch (annotation.shape) {
    case 'Rect':
      return (<RectangleEditor annotation={annotation} />);
    case 'Circle':
      return (<CircleEditor annotation={annotation} />);
    case 'Ellipse':
      return (<EllipseEditor annotation={annotation} />);
    case 'Polygon':
      return (<PolygonEditor annotation={annotation} />);
    case 'Draw':
      return (<DrawEditor annotation={annotation} />);
    case 'Point':
      return (<PointEditor annotation={annotation} />);
    default:
      console.error(`Invalid annotation shape '${ annotation.shape }' on render`);
    }
  },

  calculateZoomAndScaleForAnnotationFocus (annotation, annotatorBoundingRect, relativeMarginForAnnotationFocus, pointZoomAmount, fixedScale) {
    var scale, offset, center;
    if (annotation.annotation.shape.toUpperCase() === 'RECT') {
      let x = annotation.internalAttributes.x + annotation.internalAttributes.width / 2;
      let y = annotation.internalAttributes.y + annotation.internalAttributes.height / 2;
      let zoomX = Math.abs(annotatorBoundingRect.width / annotation.internalAttributes.width * relativeMarginForAnnotationFocus);
      let zoomY = Math.abs(annotatorBoundingRect.height / annotation.internalAttributes.height * relativeMarginForAnnotationFocus);

      scale = Math.min(zoomX, zoomY);
      center = { x: x, y: y };
    } else if (annotation.annotation.shape.toUpperCase() === 'CIRCLE') {
      center = { x: annotation.internalAttributes.x, y: annotation.internalAttributes.y };
      scale = annotatorBoundingRect.height / annotation.internalAttributes.radius * 0.5 * relativeMarginForAnnotationFocus;
    } else if (annotation.annotation.shape.toUpperCase() === 'POINT') {
      center = { x: annotation.internalAttributes.x, y: annotation.internalAttributes.y };
      scale = pointZoomAmount;
    } else if (annotation.annotation.shape.toUpperCase() === 'POLYGON' || annotation.annotation.shape.toUpperCase() === 'DRAW') {
      let calculatePolygonCenter = function (arr) {
        var minX, maxX, minY, maxY;
        for (var i = 0; i < arr.length; i++) {
          minX = (arr[i][0] < minX || minX == null) ? arr[i][0] : minX;
          maxX = (arr[i][0] > maxX || maxX == null) ? arr[i][0] : maxX;
          minY = (arr[i][1] < minY || minY == null) ? arr[i][1] : minY;
          maxY = (arr[i][1] > maxY || maxY == null) ? arr[i][1] : maxY;
        }
        return { center: [(minX + maxX) / 2, (minY + maxY) / 2], maxDistances: { x: maxX - minX, y: maxY - minY } };
      };

      // Convert array of point coords to array of points [x,y]
      let points = [];
      for (let i = 0; i + 1 < annotation.internalAttributes.points.length; i += 2) {
        points.push([annotation.internalAttributes.points[i], annotation.internalAttributes.points[i + 1]]);
      }

      let polygonInfo = calculatePolygonCenter(points);
      let c = polygonInfo.center;
      let zoomX = Math.abs(annotatorBoundingRect.width / polygonInfo.maxDistances.x * relativeMarginForAnnotationFocus);
      let zoomY = Math.abs(annotatorBoundingRect.height / polygonInfo.maxDistances.y * relativeMarginForAnnotationFocus);

      scale = Math.min(zoomX, zoomY);
      center = { x: c[0], y: c[1] };
    } else {
      scale = 1;
      center = { x: 0, y: 0 };
    }


    if (scale < 1.0) scale = 1;
    if (fixedScale) scale = fixedScale;

    if (center) {
      offset = {
        x: (center.x * scale - (annotatorBoundingRect.width) / 2),
        y: (center.y * scale - (annotatorBoundingRect.height) / 2)
      };
    } else {
      offset = { x: 0, y: 0 };
    }
    return { scale: scale, offset: offset };
  },

  isPointInsideAnnotation (annotationShape, annotationInternalAttributes, pointCoords) {
    if (annotationShape === 'Rect') {
      const X = annotationInternalAttributes.x;
      const A = annotationInternalAttributes.x + annotationInternalAttributes.width;
      const Y = annotationInternalAttributes.y;
      const B = annotationInternalAttributes.y + annotationInternalAttributes.height;

      return X < pointCoords.x && A > pointCoords.x && Y < pointCoords.y && B > pointCoords.y;
    } else if (annotationShape === 'Circle') {
      return Math.pow(pointCoords.x - annotationInternalAttributes.x, 2) +
        Math.pow(pointCoords.y - annotationInternalAttributes.y, 2) <
        Math.pow(annotationInternalAttributes.radius, 2);
    } else if (annotationShape === 'Ellipse') {
      return (
        (Math.pow((pointCoords.x - annotationInternalAttributes.x), 2) / Math.pow(annotationInternalAttributes.radius.x, 2)) +
        (Math.pow((pointCoords.y - annotationInternalAttributes.y), 2) / Math.pow(annotationInternalAttributes.radius.y, 2))
      ) < 1;
    } else if (annotationShape === 'Polygon' || annotationShape === 'Draw') {
      const vs = annotationInternalAttributes.points;
      var x = pointCoords.x; var y = pointCoords.y;
      var inside = false;

      for (var i = 0, j = vs.length - 2; i < vs.length - 1; j = i, i = i + 2) {
        var xi = vs[i]; var yi = vs[i + 1];
        var xj = vs[j]; var yj = vs[j + 1];

        var intersect = ((yi > y) !== (yj > y)) &&
          (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
      }
      return inside;
    } else {
      console.error(`Not implemented for shape '${ annotationShape}'`);
      return false;
    }
  }

};

export default AnnotationFactory;
