import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ImageToolsFactory from './ImageToolsFactory';
import appPropStore from '../../../models/app-properties-store';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  contentRoot: {
    width: 150,
    margin: theme.spacing(3)
  },
  warningText: {
    color: '#f0ad4e'
  }
}));

export default (props) => {
  const { anchorEl, onClose } = props;
  const { tools } = props;

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const id = open ? 'image-tools-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <div className={classes.contentRoot} >
        <Typography
          className={classes.warningText}
          variant="caption"
          gutterBottom
        >
          Warning: Applying filters may produce a small degradation on the image quality
        </Typography>

        {tools.map(tool => ImageToolsFactory.getToolSlider(tool, appPropStore))}
      </div>
    </Popover>
  );
};
