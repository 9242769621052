import React, { Component } from 'react';
import NewAnnotationCategoryForm from './NewAnnotationCategoryForm';
import appPropStore from '../../models/app-properties-store';
import { groupBy } from '../../utils/Utils';

class AnnotationsPrompt extends Component {
  constructor (props) {
    super(props);

    let noteValue = "";
    if (this.props.annotation) {
      noteValue = this.props.annotation.annotation.notes ? this.props.annotation.annotation.notes : "";
    }

    this.state = {
      noteValue: noteValue,
      annotationType: this.props.selectedType || "",
      expandedGroup: this.props.selectedType && this.props.selectedType['group'] || '',
      showNewAnnotationForm: false
    };

    this.onChange = (e) => this._onChange(e);
  }

  componentDidUpdate (prevProps, prevState) {
    this.props.onChange(this.state.noteValue, this.state.annotationType);
  }

  _onChange (e) {
    let noteValue = e.target.value;
    this.setState({ noteValue: noteValue });
  }

  setAnnotationType (annotationType) {
    this.setState({ annotationType: annotationType });
  }

  groupedAnnotationTypes () {
    let annotationOptions = [];
    let groups = groupBy(this.props.annotationTypes, item => item.group);
    for (let [key, values] of groups.entries()) {
      annotationOptions.push(
        <div
          key={key || 'Ungrouped'}
          className="annotationGroup"
          onClick={() => {
            if (this.state.expandedGroup === key) {
              this.setState({ expandedGroup: undefined });
            } else {
              this.setState({ expandedGroup: key });
            }
          }}
        >
          <div className="annotationGroup_Header">
            <h5>{<span>▶</span>}</h5>
            <h5>{key || 'Ungrouped'}</h5>
          </div>
          <div className={'annotationGroup_Body expanded'}>
            {values.map(val => this.annotationElement(val))}
          </div>
        </div>
      );
    }
    return annotationOptions;
  }

  annotationElement (annotationType) {
    return (
      <li key={annotationType.keyName} className="radio">
        <label htmlFor={annotationType.keyName}>
          <input
            onClick={() => this.setAnnotationType(annotationType)}
            id={annotationType.keyName}
            type="radio" name="annotationtype"
            value={annotationType}
            defaultChecked={this.props.annotation && annotationType.keyName === this.props.annotation.annotation.annotationType}
          />
          <span style={{ color: annotationType.color }}>■</span> {annotationType.friendlyName + (annotationType.notesRequired ? " (notes required)" : "") }
        </label>
      </li>
    );
  }

  render () {
    let listAnnotationTypes = this.props.annotationTypes.find(obj => obj.hasOwnProperty('group')) ?
      this.groupedAnnotationTypes() :
      this.props.annotationTypes.map(annotationType => this.annotationElement(annotationType));

    return (
      <div className="popup-radios">

        {this.state.showNewAnnotationForm && <NewAnnotationCategoryForm onClose={(newAnnotationCategory) => this.setState({ annotationType: newAnnotationCategory, showNewAnnotationForm: false })} /> }

        {!this.state.showNewAnnotationForm &&
            <div>
              <ul>
                { listAnnotationTypes }
                { appPropStore.allowNewCategories &&
                  <li><a href="javascript:undefined" onClick={() => this.setState({ showNewAnnotationForm: true })} >Add new category</a></li>
                }
              </ul>
              <input type="text" placeholder="Notas adicionais" className="mm-popup__input" value={this.state.noteValue} onChange={this.onChange} />
            </div>
        }
      </div>
    );
  }
}

export default AnnotationsPrompt;
