import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Rect, Group } from 'react-konva';
import { getNaturalCoords, getPointerPositionThoughAnnotator } from '../../../utils/Utils';
import { CircleAnnotation } from './CircleAnnotation';
import Popup from 'react-popup';
import '../../prompts/AnnotationPrompt';

import store from './../../../models/annotations-store';
import appPropStore from './../../../models/app-properties-store';

class CircleAnnotator extends Component {
  constructor () {
    super();
    this.state = {
      coordDown: null,
      tempShape: null
    };
    this._onMouseDown = this._onMouseDown.bind(this);
    this._onMouseUp = this._onMouseUp.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onTouchStart = this._onTouchStart.bind(this);
    this._onTouchEnd = this._onTouchEnd.bind(this);
    this._onTouchMove = this._onTouchMove.bind(this);

    // Values to aid the calculation of the popup position
    this.center = null;
  }

  restartDrawing () {
    this.setState({
      coordDown: null,
      tempShape: null
    });
  }

  //Mouse Inputs
  _onMouseDown (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.button !== 0) {
      return;
    }

    // Update positions for the calculation of the popup
    this.center = { x: e.evt.clientX, y: e.evt.clientY };

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    this.setState({
      coordDown: { x: pointerPosition.x, y: pointerPosition.y }
    });
  }

  _onMouseUp (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.button !== 0 || this.state.tempShape == null || this.state.coordDown == null) {
      this.restartDrawing();
      return;
    }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);

    let xCenter = pointerPosition.x - this.state.coordDown.x;
    let yCenter = pointerPosition.y - this.state.coordDown.y;

    let radius = Math.round(Math.sqrt(xCenter * xCenter + yCenter * yCenter) * 100) / 100;

    //Radius must be bigger than 2
    if (Math.abs(radius) <= 5) {
      this.restartDrawing();
      return;
    }

    let internalAttributes = {
      x: this.state.coordDown.x, y: this.state.coordDown.y,
      radius: radius
    };

    //Calculate the real coordinates
    let coordX = pointerPosition.x;
    let coordY = pointerPosition.y;
    //Calculate rect atributes for the real image
    let naturalCenter = getNaturalCoords(internalAttributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let naturalMousePos = getNaturalCoords({ x: coordX, y: coordY }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let xNaturalCenter = naturalMousePos.x - naturalCenter.x;
    let yNaturalCenter = naturalMousePos.y - naturalCenter.y;
    let naturalRadius = Math.round(Math.sqrt(xNaturalCenter * xNaturalCenter + yNaturalCenter * yNaturalCenter) * 100) / 100;

    let naturalAttributes = {
      ...naturalCenter,
      naturalRadius
    };

    //Send to parent
    let circle = {
      annotation: { naturalAttributes: naturalAttributes }
    };

    let popupPosition = this.calculatePopupPosition(internalAttributes);
    // Open the popup
    Popup.plugins().promptForAdditionalInfo(popupPosition, this.props.annotationTypes, ((notes, annotationType, isUpdate) => this.props.addAnnotation('Circle', circle, notes, annotationType, isUpdate)), (() => this.restartDrawing()));
  }

  calculatePopupPosition (internalAttributes) {
    let position = {
      top: this.center.y - 125,
      left: this.center.x + internalAttributes.radius + 10
    };

    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (position.left + 360 > window.innerWidth) {
      if ((this.center.x - 360 - internalAttributes.radius) > 0) {
        position.left = this.center.x - 360 - internalAttributes.radius;
      } else {
        position.left = this.center.x - (360 / 2);//window.innerWidth - 360 - internalAttributes.radius;
      }
    }
    if (position.top + promptSize > window.innerHeight) {
      position.top = Math.max(0, window.innerHeight - promptSize);
    }
    return position;
  }

  _onMouseMove (e) {
    if (!appPropStore.isDrawingKeyDown) {
      this.restartDrawing();
      return;
    }
    if (this.state.coordDown == null) return;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);

    let xCenter = pointerPosition.x - this.state.coordDown.x;
    let yCenter = pointerPosition.y - this.state.coordDown.y;

    let radius = Math.sqrt(xCenter * xCenter + yCenter * yCenter);

    if (Math.abs(radius) <= 2) return;

    this.setState({
      tempShape: {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          radius: radius
        },
        color: "white"
      }
    });
  }

  //Touch Inputs
  _onTouchStart (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.touches.length !== 1) { return; }

    // Update positions for the calculation of the popup
    this.center = { x: e.evt.clientX, y: e.evt.clientY };

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    this.setState({
      coordDown: { x: pointerPosition.x, y: pointerPosition.y }
    });
  }

  _onTouchEnd (e) {
    if (!appPropStore.isDrawingKeyDown || this.state.tempShape == null || this.state.coordDown == null) {
      this.restartDrawing();
      return;
    }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);

    let xCenter = pointerPosition.x - this.state.coordDown.x;
    let yCenter = pointerPosition.y - this.state.coordDown.y;

    let radius = Math.round(Math.sqrt(xCenter * xCenter + yCenter * yCenter) * 100) / 100;

    //Radius must be bigger than 2
    if (Math.abs(radius) <= 5) {
      this.restartDrawing();
      return;
    }

    let internalAttributes = {
      x: this.state.coordDown.x, y: this.state.coordDown.y,
      radius: radius
    };

    //Calculate the real coordinates
    let coordX = pointerPosition.x;
    let coordY = pointerPosition.y;
    //Calculate rect atributes for the real image
    let naturalCenter = getNaturalCoords(internalAttributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let naturalMousePos = getNaturalCoords({ x: coordX, y: coordY }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let xNaturalCenter = naturalMousePos.x - naturalCenter.x;
    let yNaturalCenter = naturalMousePos.y - naturalCenter.y;
    let naturalRadius = Math.round(Math.sqrt(xNaturalCenter * xNaturalCenter + yNaturalCenter * yNaturalCenter) * 100) / 100;

    let naturalAttributes = {
      ...naturalCenter,
      naturalRadius
    };

    //Send to parent
    let circle = {
      annotation: { naturalAttributes: naturalAttributes }
    };

    let popupPosition = this.calculatePopupPosition(internalAttributes);
    // Open the popup
    Popup.plugins().promptForAdditionalInfo(popupPosition, this.props.annotationTypes, ((notes, annotationType, isUpdate) => this.props.addAnnotation('Circle', circle, notes, annotationType, isUpdate)), (() => this.restartDrawing()));
  }

  _onTouchMove (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.touches.length !== 1) {
      this.restartDrawing();
      return;
    }
    if (this.state.coordDown == null) return;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);

    let xCenter = pointerPosition.x - this.state.coordDown.x;
    let yCenter = pointerPosition.y - this.state.coordDown.y;

    let radius = Math.sqrt(xCenter * xCenter + yCenter * yCenter);

    if (Math.abs(radius) <= 2) return;

    this.setState({
      tempShape: {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          radius: radius
        },
        color: "white"
      }
    });
  }

  render () {
    return (
      <Group>
        {/*Temp Rect*/}
        {this.state.tempShape != null &&
            <CircleAnnotation {...this.state.tempShape} />
        }
        {/*Renders a canvas for drawing (listening to mouse inputs)*/}
        <Rect
          {...appPropStore.userImageDimensions}
          onMouseMove={this._onMouseMove} onMouseDown={this._onMouseDown} onMouseUp={this._onMouseUp}
          onTouchMove={this._onTouchMove} onTouchStart={this._onTouchStart} onTouchEnd={this._onTouchEnd}
        />
      </Group>
    );
  }
}

CircleAnnotator.propTypes = {
  addAnnotation: PropTypes.func.isRequired //Callback to return the annotations
};

function calculateCircleViewDimensions (circle, viewDimensions, naturalImageDimensions) {
  let internalAttributes = {
    x: (circle.annotation.naturalAttributes.x * viewDimensions.width / naturalImageDimensions.width),
    y: (circle.annotation.naturalAttributes.y * viewDimensions.height / naturalImageDimensions.height),
    radius: (circle.annotation.naturalAttributes.naturalRadius * viewDimensions.width / naturalImageDimensions.width)
  };

  return internalAttributes;
}

function calculateCircleListViewDimensions (list, viewDimensions, naturalImageDimensions) {
  list.map(listValue => (
    listValue = calculateCircleViewDimensions(listValue, viewDimensions, naturalImageDimensions)
  ));
  return list;
}

export { CircleAnnotator, calculateCircleViewDimensions, calculateCircleListViewDimensions };
