import React, { Component } from 'react';
//import ReactDom from 'react-dom';
import Popup from 'react-popup';
import './annotationPrompt.css';

class NewWholeImageAnnotationCategoryPrompt extends Component {
  constructor (props) {
    super(props);

    this.state = {
      value: ""
    };

    this.onChange = (e) => this._onChange(e);
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      this.props.onChange(this.state.value);
    }
  }

  _onChange (e) {
    let value = e.target.value;

    this.setState({ value: value });
  }

  render () {
    return <input type="text" placeholder={this.props.placeholder} className="mm-popup__input" value={this.state.value} onChange={this.onChange} />;
  }
}

/** Prompt plugin */
Popup.registerPlugin('prompt', function (defaultValue, placeholder, callback) {
  let promptValue = null;
  let promptChange = function (value) {
    promptValue = value;
  };

  this.create({
    title: 'New category',
    content: <NewWholeImageAnnotationCategoryPrompt onChange={promptChange} placeholder={placeholder} value={defaultValue} />,
    buttons: {
      left: ['cancel'],
      right: [{
        text: 'Save',
        key: '⌘+s',
        className: 'success',
        action: function () {
          if (promptValue === null || promptValue.trim() === "") {
            Popup.alert(<p style={{ color: "red" }}>Category name can't be empty</p>, "Invalid field", true);
          } else {
            callback(promptValue.trim());
            Popup.close();
          }
        }
      }]
    }
  });
});
