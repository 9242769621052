/*
export const BASE_URL = 'http://159.89.108.175:82/api/'
export const KEYCLOAK_URL = 'http://159.89.108.175/auth/admin/realms/clare'
*/
export const BASE_URL = 'https://wounds.cws.fhpannotator.com/api/' // 'http://161.35.19.96:80/api/'
export const KEYCLOAK_URL = 'https://fhpannotator.com/auth/admin/realms/wounds' // 'http://161.35.19.96/auth/admin/realms/wounds'

export const annotationOptions = [
    { friendlyName: 'Necrótico *', keyName: 'necrotico', color: '#000000', group: 'Tecido no leito da ferida' },
    { friendlyName: 'Desvitalizado *', keyName: 'desvitalizado', color: '#ffff00', group: 'Tecido no leito da ferida' },
    { friendlyName: 'Granulação *', keyName: 'granulacao', color: '#e2001a', group: 'Tecido no leito da ferida' },

    { friendlyName: 'Maceração ', keyName: 'maceracao', color: '#f57514', group: 'Alterações da pele perilesional' },
    { friendlyName: 'Eczema', keyName: 'eczema', color: '#71000d', group: 'Alterações da pele perilesional' },
    { friendlyName: 'Eritema', keyName: 'eritema', color: '#179c7d', group: 'Alterações da pele perilesional' },
    { friendlyName: 'Escoriação', keyName: 'escoriacao', color: '#cc0cf2', group: 'Alterações da pele perilesional' },
    { friendlyName: 'Calo', keyName: 'calo', color: '#1f82c0', group: 'Alterações da pele perilesional' },
    
    { friendlyName: 'Epitelial', keyName: 'epitelial', color: '#ff8d9a', group: 'Outros' },
    { friendlyName: 'Ferida Aberta *', keyName: 'ferida_aberta', color: '#f5751400', group: 'Outros' },
    { friendlyName: 'Outro', keyName: 'outro', color: '#525252', group: 'Outros' },
    { friendlyName: 'Marcador', keyName: 'Marcador', color: '#9a9a9a', group: 'Outros' }
]

export const AcquisitionOptions = [
    {
        group: 'Imagem Focada',
        options: [
            { name: 'Sim', keyName: 'focus_sim' },
            { name: 'Não', keyName: 'focus_nao' },
        ],
        required: 1,
        maxSelections:1,
        observations: false
    }, {
        group: 'Artefactos de Iluminação',
        options: [
            { name: 'Ausente(s)', keyName: 'lightartefacts_none', exclusive: true },
            { name: 'Sombra(s)', keyName: 'lightartefacts_sombra', exclusive: false },
            { name: 'Reflexão(ões)', keyName: 'lightartefacts_reflexao', exclusive: false },
        ],
        required: 1,
        maxSelections: 2,
        observations: false
    }, {
        group: 'Alterações de pele perilesional',
        options: [
            { name: 'Impossível de Avaliar', keyName: 'perilesional_na', exclusive: true },
            { name: 'Ausente', keyName: 'perilesional_ausente', exclusive: true },
            { name: 'Maceração', keyName: 'perilesional_maceracao', exclusive: false },
            { name: 'Escoriação', keyName: 'perilesional_escoriacao', exclusive: false },
            { name: 'Seca', keyName: 'perilesional_seca_descamativa', exclusive: false },
            { name: 'Hiperqueratose', keyName: 'perilesional_hiperqueratose', exclusive: false },
            { name: 'Calo', keyName: 'perilesional_calo', exclusive: false },
            { name: 'Eczema', keyName: 'perilesional_eczema', exclusive: false },
            { name: 'Eritema', keyName: 'perilesional_eritema', exclusive: false },
            { name: 'Edema', keyName: 'perilesional_edema', exclusive: false }
        ],
        required: 1,
        maxSelections: 9,
        observations: false
    }, {
        group: 'Bordos',
        options: [
            { name: 'Impossível de Avaliar', keyName: 'bordos_na', exclusive: true },
            { name: 'Intactos, aderentes e nivelados com o leito da ferida', keyName: 'bordos_intactos', exclusive: true },
            { name: 'Macerados', keyName: 'bordos_macerados', exclusive: false },
            { name: 'Desidratados', keyName: 'bordos_desidratados', exclusive: false },
            { name: 'Soltos com presença de loca', keyName: 'bordos_soltos', exclusive: false },
            { name: 'Enrolados/Espessados', keyName: 'bordos_enrolados', exclusive: false }
        ],
        required: 1,
        maxSelections: 5,
        observations: false
    }, {
        group: 'Sinais sugestivos de infeção',
        options: [
            { name: 'Sim', keyName: 'infecao_sim' },
            { name: 'Não', keyName: 'infecao_nao' }
        ],
        required: 1,
        maxSelections: 1,
        observations: false
    }, {
        group: 'Sinais sugestivos de Isquemia',
        options: [
            { name: 'Sim', keyName: 'isquemia_sim' },
            { name: 'Não', keyName: 'isquemia_nao' }
        ],
        required: 1,
        maxSelections: 1,
        observations: false
    }, {
        group: 'Tecidos atingidos',
        options: [
            { name: 'Impossível de Avaliar', keyName: 'targetTissue_na'},
            { name: 'Pele intacta regenerada/cicatrizada ou com equimose', keyName: 'targetTissue_intacta' },
            { name: 'Atingimento da derme e epiderme', keyName: 'targetTissue_dermeEpiderme' },
            { name: 'Atingimento do tecido celular subcutâneo', keyName: 'targetTissue_celular' },
            { name: 'Atingimento muscular', keyName: 'targetTissue_muscular' },
            { name: 'Atingimento ósseo e/ou tecidos anexos (tendões, ligamentos, cápsula articular ou necrose que não permite visualizar os tecidos subjacentes)', keyName: 'targetTissue_osseo' }
        ],
        required: 1,
        maxSelections: 1,
        observations: true
    }
]

export const requiredNrWholeImageAnnotations = 7 //per image sample

export const wholeImageAnnotationOptions = [
]

export const EMPTY_ACQUISITION = {
    datasetId: '',
    ownerId: '',
    sampleId: ''
}

export const ACQUISITION_DEFAULT_METADATA = {
    "ID do Paciente": "", // CodigoUtente
    "ID da Ferida": "", // CodigoFerida
    "Fototipo da pele": "", // FototipoPele
    "Tipologia da ferida": "", // TipologiaFerida
    "Localização da ferida": "", // ZonaAnatomica
    "Ferida Presente desde": "", // FeridaPresenteDesde
    "Data da Primeira Monitorização": "", // DataRegistoFerida
    "ID da instituição": "" // LocalRegisto
}

export const IMAGE_SAMPLE_METADATA = {
    "Data da Monitorização": "01/01/1991" // , // DataRegistoIntervencao
    // "Tipo de penso": "" // only for Pensos
}

export const MESSAGES = {
    clinicalDecision: 'Finish Sample Annotation',
    disabledClinicalDecision: 'All Images must be annotated',
    missingImageQuality: 'Todos os campos obrigatórios para a anotação devem estar preenchidos!',
    emptyClinicalDecision: '\t\t ATTENTION ! \r\n No Clinical Decision Selected. \r\n\r\n Are you sure you want to finish ?',
    emptyRequiredField: 'One or more of the required fields are empty !',
    resumeAnnotation: 'Want to Resume the Annotation from the last annotated image? \r\n(This action may take a while)',
    finishButton: '\t\tIn order to conclude the annotation, \r\n Please use the \" FINISH SAMPLE ANNOTATION \" Button.'
}
