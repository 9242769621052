import React from 'react';
import Popup from 'react-popup';
import NewAnnotationCategoryForm from './NewAnnotationCategoryForm';
import './annotationPrompt.css';
import store from '../../models/annotations-store';
import appPropStore from '../../models/app-properties-store';
import { groupBy } from '../../utils/Utils';

/** The prompt content component */
class Prompt extends React.Component {
  constructor (props) {
    super(props);

    let noteValue = "";
    if (appPropStore.wizardMode) {
      noteValue = store.currentAnnotation && store.currentAnnotation.annotation.notes ? store.currentAnnotation.annotation.notes : "";
    }

    this.state = {
      noteValue: noteValue,
      annotationType: this.props.selectedType || "",
      expandedGroup: this.props.selectedType && this.props.selectedType['group'] || '',
      showNewAnnotationForm: false
    };

    this.onChange = (e) => this._onChange(e);
  }

  componentDidUpdate (prevProps, prevState) {
    this.props.onChange(this.state.noteValue, this.state.annotationType);
  }

  _onChange (e) {
    let noteValue = e.target.value;
    this.setState({ noteValue: noteValue });
  }

  setAnnotationType (annotationType) {
    this.setState({ annotationType: annotationType });
  }

  groupedAnnotationTypes () {
    let annotationOptions = [];
    let groups = groupBy(this.props.annotationTypes, item => item.group);
    for (let [key, values] of groups.entries()) {
      annotationOptions.push(
        <div
          key={key || 'Ungrouped'}
          className="annotationGroup"
          onClick={() => {
            if (this.state.expandedGroup === key) {
              this.setState({ expandedGroup: undefined });
            } else {
              this.setState({ expandedGroup: key });
            }
          }}
        >
          <div className="annotationGroup_Header">
            <h5>{<span>▶</span>}</h5>
            <h5>{key || 'Ungrouped'}</h5>
          </div>
          <div className={'annotationGroup_Body expanded'}>
            {values.map(val => this.annotationElement(val))}
          </div>
        </div>
      );
    }
    return annotationOptions;
  }


  annotationElement (annotationType) {
    return (
      <li key={annotationType.keyName} className="radio">
        <label htmlFor={annotationType.keyName}>
          <input
            onClick={() => this.setAnnotationType(annotationType)}
            id={annotationType.keyName}
            type="radio" name="annotationtype"
            value={annotationType}
            defaultChecked={appPropStore.wizardMode && store.currentAnnotation ? annotationType.keyName === store.currentAnnotation.annotation.annotationType : annotationType.defaultChecked}
          />
          <span style={{ color: annotationType.color }}>■</span> {annotationType.friendlyName + (annotationType.notesRequired ? " (notes required)" : "") }
        </label>
      </li>
    );
  }

  render () {
    let listAnnotationTypes = this.props.annotationTypes.find(obj => obj.hasOwnProperty('group')) ?
      this.groupedAnnotationTypes() :
      this.props.annotationTypes.map(annotationType => this.annotationElement(annotationType));

    return (
      <div className="popup-radios">

        {this.state.showNewAnnotationForm && <NewAnnotationCategoryForm onClose={(newAnnotationCategory) => this.setState({ annotationType: newAnnotationCategory, showNewAnnotationForm: false })} /> }

        {!this.state.showNewAnnotationForm &&
          <div>
            <ul>
              { listAnnotationTypes }
              { appPropStore.allowNewCategories &&
                <li><a href="javascript:undefined" onClick={() => this.setState({ showNewAnnotationForm: true })} >Add new category</a></li>
              }
            </ul>
            <input type="text" placeholder="Notas adicionais" className="mm-popup__input" value={this.state.noteValue} onChange={this.onChange} />
          </div>
        }
      </div>
    );
  }
}

/** Additional Annotation Info Prompt plugin **/
Popup.registerPlugin('promptForAdditionalInfo', function (position, annotationTypes, callbackSave, callbackClose) {
  //Find the default annotation type
  let defaultChecked = annotationTypes.filter(obj => obj.defaultChecked === true)[0];
  if (!defaultChecked && annotationTypes.length > 0) {
    annotationTypes[0]['defaultChecked'] = true;
    defaultChecked = annotationTypes[0];
  }

  let noteValue = "";
  if (appPropStore.wizardMode) {
    noteValue = store.currentAnnotation && store.currentAnnotation.annotation.notes ? store.currentAnnotation.annotation.notes : "";
  }
  let promptValue = noteValue;
  let annotationType = defaultChecked;

  let promptChange = function (noteValue, annotationValue) {
    promptValue = noteValue;
    annotationType = annotationValue;
  };

  let buttons = {
    left: [{
      text: 'Cancel',
      action: function () {
        typeof callbackClose === 'function' && callbackClose();
        Popup.clearQueue();
        Popup.close();
      }
    }],
    right: [{
      text: 'Save New',
      className: 'success',
      action: function () {
        if (annotationType == null) {window.alert("Choose a category to create the annotation"); return; }
        if (annotationType.notesRequired && promptValue === "") {window.alert("Write a note first"); return; }
        typeof callbackClose === 'function' && callbackClose();
        typeof callbackSave === 'function' && callbackSave(promptValue, annotationType.keyName, false);
        Popup.clearQueue();
        Popup.close();
      }
    }]
  };

  if (appPropStore.wizardMode && store.annotations.length > 0) {
    buttons.right.unshift({
      text: 'Update Current',
      className: 'success',
      action: function () {
        typeof callbackClose === 'function' && callbackClose();
        typeof callbackSave === 'function' && callbackSave(promptValue, annotationType.keyName, true);
        Popup.clearQueue();
        Popup.close();
      }
    });
  }

  this.create({
    title: 'Anotação da Imagem',
    closeOnOutsideClick: false, // Should a click outside the popup close it? (default is closeOnOutsideClick property on the component)
    noOverlay: false, // hide overlay layer (default is false, overlay visible),
    content: <Prompt onChange={promptChange} annotationTypes={annotationTypes} selectedType={defaultChecked ? defaultChecked : null} />,
    buttons: buttons,
    position: function (box) {
      box.style.top = position.top + 'px';
      box.style.left = position.left + 'px';
      box.style.margin = 0;
      box.style.opacity = 1;
    }
  });
});

/** Save With no Annotations Prompt plugin **/
Popup.registerPlugin('promptConfirmSaveImageWithNoAnnotations', function (callbackSave, callbackContinue) {
  let buttons = {
    left: [{
      text: 'No',
      action: function () {
        typeof callbackContinue === 'function' && callbackContinue();
        Popup.clearQueue();
        Popup.close();
      }
    }],
    right: [{
      text: 'Yes',
      className: 'success',
      action: function () {
        typeof callbackSave === 'function' && callbackSave();
        typeof callbackContinue === 'function' && callbackContinue();
        Popup.clearQueue();
        Popup.close();
      }
    }]
  };

  this.create({
    title: 'No Annotations',
    closeOnOutsideClick: false,
    noOverlay: false,
    content: <div>You have not created any annotations on the current image, do you want to submit anyway?</div>,
    buttons: buttons
  });
});

/** Save With no Annotations Prompt plugin **/
Popup.registerPlugin('promptConfirmSaveAnnotations', function (callbackSave, callbackContinue, title, content) {
  let buttons = {
    left: [{
      text: 'No',
      action: function () {
        typeof callbackContinue === 'function' && callbackContinue();
        Popup.clearQueue();
        Popup.close();
      }
    }],
    right: [{
      text: 'Yes',
      className: 'success',
      action: function () {
        typeof callbackSave === 'function' && callbackSave();
        // typeof callbackContinue === 'function' && callbackContinue(); // Hack? (was finishing without having time to save the required annotation on malariascope)
        Popup.clearQueue();
        Popup.close();
      }
    }]
  };

  this.create({
    title: title || 'Save annotations',
    closeOnOutsideClick: false,
    noOverlay: false,
    content: <div>{ content || 'Do you want to save the annotations of this image?' }</div>,
    buttons: buttons
  });
});
