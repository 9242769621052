import React from 'react';

const defaultOptions = {
  primaryColor: '#179c7d',
  secondaryColor: '#14866b',
  secondaryColorRGB: '#14866b'
};

const defaultStyles = {
  beacon: {
    color: defaultOptions.primaryColor
  },
  beaconInner: {
    backgroundColor: defaultOptions.primaryColor
  },
  beaconOuter: {
    backgroundColor: `rgba(${defaultOptions.secondaryColorRGB}, 0.2)`,
    border: `2px solid ${defaultOptions.secondaryColor}`
  },
  buttonNext: {
    backgroundColor: defaultOptions.primaryColor
  },
  buttonBack: {
    color: defaultOptions.primaryColor
  }
};

export default {
  styles: defaultStyles,
  steps: [
    {
      title: 'Image Diagnosis',
      content: (
        <div>
          <p>You can specify an image diagnosis with the checkboxes on this side.</p>
          <p style={{ fontSize: '85%' }}>All the changes will be immediately saved on the server.</p>
        </div>
      ),
      target: '.wholeImageAnnotationsDiv',
      styles: { beacon: { transform: 'translateY(20px)' } },
      placementBeacon: 'top',
      placement: 'left'
    },
    {
      title: 'Specific annotations',
      content: (
        <div>
          <p>You can also create annotations for specific areas of the image by drawing on the image.</p>
        </div>
      ),
      target: '.annotatorDiv',
      styles: { beacon: { transform: 'translateY(-10px)' } },
      placementBeacon: 'top',
      placement: 'bottom'
    },
    {
      title: 'Shape selector',
      content: (
        <div>
          <p>You can switch the shape of the annotations anytime with these buttons.</p>
          <p>The selected shape/tool will be highlighted when activated.</p>
        </div>
      ),
      target: '.toolsPanel',
      styles: { beacon: { transform: 'translateY(-10px)' } },
      placementBeacon: 'top',
      placement: 'right'
    },
    {
      title: 'Creating Annotations',
      content: (
        <div>
          <p>To create your annotations just click and drag your mouse while holding <b>'{getDrawingKeyName()}'</b> on your keyboard.</p>
          <i style={{ fontSize: '85%' }}>(Instead of holding <b>'{getDrawingKeyName()}'</b>, you can also double click on the desired shape on the left tools panel in order to stay on the drawing mode.)</i>
          <p style={{ fontSize: '85%' }}>All the changes will be immediately saved on the server.</p>
        </div>
      ),
      target: '.annotatorDiv',
      styles: { beacon: { transform: 'translateY(-10px)' } },
      placementBeacon: 'top'
    },
    {
      title: 'Deleting Annotations',
      content: (<p>If you need to delete an annotation, just hold <b>'Shift'</b> on your keyboard and click with the left mouse button on the annotation that you want to delete.</p>),
      target: '.annotatorDiv.well',
      styles: { beacon: { transform: 'translateY(-10px)' } },
      placementBeacon: 'top'
    },
    {
      title: 'Zooming',
      content: 'To zoom in and zoom out use your mouse scroll wheel',
      target: '.annotatorDiv',
      styles: { beacon: { transform: 'translateY(-10px)' } },
      placementBeacon: 'top'
    },
    {
      title: 'Moving through the images/samples',
      content: 'Use these buttons to move through the available images/samples and to finish your work at the end.',
      target: '.annotatorControls',
      placementBeacon: 'bottom'
    }
  ]
};

function getDrawingKeyName () { return navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? 'command' : 'Ctrl'; }
