import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Rect, Group } from 'react-konva';
import { PointAnnotation } from './PointAnnotation';
import { getNaturalCoords, getPointerPositionThoughAnnotator } from '../../../utils/Utils';
import Popup from 'react-popup';
import '../../prompts/AnnotationPrompt';

import store from './../../../models/annotations-store';
import appPropStore from './../../../models/app-properties-store';

class PointAnnotator extends Component {
  constructor () {
    super();
    this.state = {
      tempShape: null
    };

    this._onMouseUp = this._onMouseUp.bind(this);
    this._onTouchEnd = this._onTouchEnd.bind(this);
  }

  restartDrawing () {
    this.setState({
      tempShape: null
    });
  }

  //Mouse Inputs
  _onMouseUp (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.button !== 0) {
      return;
    }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    let internalAttributes = {
      x: pointerPosition.x, y: pointerPosition.y
    };

    this.setState({ tempShape: { internalAttributes: internalAttributes, color: "white" } });

    //Calculate the coordinates for the real image
    let naturalCenter = getNaturalCoords(internalAttributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);

    //Send to parent
    let point = {
      annotation: { naturalAttributes: naturalCenter }
    };

    let popupPosition = this.calculatePopupPosition(e);
    // Open the popup
    Popup.plugins().promptForAdditionalInfo(popupPosition, this.props.annotationTypes, ((notes, annotationType, isUpdate) => this.props.addAnnotation('Point', point, notes, annotationType, isUpdate)), (() => this.restartDrawing()));
  }

  calculatePopupPosition (e) {
    let position = {
      top: e.evt.clientY,
      left: e.evt.clientX + appPropStore.dotSize * 2 + 10
    };
    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (position.left + 360 > window.innerWidth) {
      position.left = e.evt.clientX - appPropStore.dotSize * 2 - 360;
    }
    if (position.top + promptSize > window.innerHeight) {
      position.top = Math.max(0, position.top - promptSize);
    }
    return position;
  }

//Touch Inputs
_onTouchEnd (e) {
  if (!appPropStore.isDrawingKeyDown) {
    return;
  }

  let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
  let internalAttributes = {
    x: pointerPosition.x, y: pointerPosition.y
  };

  this.setState({ tempShape: { internalAttributes: internalAttributes, color: "white" } });

  //Calculate the coordinates for the real image
  let naturalCenter = getNaturalCoords(internalAttributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);

  //Send to parent
  let point = {
    annotation: { naturalAttributes: naturalCenter }
  };

  let popupPosition = this.calculatePopupPosition(e);
  // Open the popup
  Popup.plugins().promptForAdditionalInfo(popupPosition, this.props.annotationTypes, ((notes, annotationType, isUpdate) => this.props.addAnnotation('Point', point, notes, annotationType, isUpdate)), (() => this.restartDrawing()));
}

  render () {
    return (
      <Group>
        {/*Temp Shape*/}
        {this.state.tempShape != null &&
            <PointAnnotation {...this.state.tempShape} />
        }
        {/*Renders a canvas for drawing (listening to mouse inputs)*/}
        <Rect
          {...appPropStore.userImageDimensions}
          onMouseUp={this._onMouseUp}
          onTouchEnd={this._onTouchEnd}
        />
      </Group>
    );
  }
}

PointAnnotator.propTypes = {
  addAnnotation: PropTypes.func.isRequired //Callback to return the annotations
};

function calculatePointViewDimensions (point, viewDimensions, imageNaturalDimensions) {
  let internalAttributes = {
    x: (point.annotation.naturalAttributes.x * viewDimensions.width / imageNaturalDimensions.width),
    y: (point.annotation.naturalAttributes.y * viewDimensions.height / imageNaturalDimensions.height)
  };

  return internalAttributes;
}

export { PointAnnotator, calculatePointViewDimensions };
