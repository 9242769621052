import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import store from './../models/annotations-store';
import { Annotation } from './../models/annotations-store';
import appPropStore from './../models/app-properties-store';
import imageToolsStore from './../models/image-tools-store';
import { Stage, Layer, Text, Rect } from 'react-konva';
import { getBoundingRect, getPointerPositionThoughAnnotator, base64ImageToBinaryMatrix, base64ImageToJpgImage } from './../utils/Utils';
import AnnotationFactory from './annotations/AnnotationFactory';
import Img from './Img';
import Popup from 'react-popup';
import DeleteTool from './DeleteTool/DeleteTool';

import { ContextMenuTrigger } from 'react-contextmenu';
import AnnotatorContextMenu from './ContextMenu/AnnotatorContextMenu';

@observer
class Annotator extends Component {
  static propTypes = {
    childImagePath: PropTypes.string.isRequired,
    annotationTypes: PropTypes.array,
    changesCallback: PropTypes.func,
    notifyChangesCallback: PropTypes.func.isRequired,
    onAnnotationChanged: PropTypes.func,
    updateWizard: PropTypes.func,
    onImageChanged: PropTypes.func,
    subsequentImageSrc: PropTypes.string
  };

  constructor (props) {
    super(props);
    this.state = {
      childrenLoaded: false,
      mouseOverImageDiv: false,

      isUserDrawing: false
    };

    this.lastResize = { width: 0, height: 0 };

    this.shapesLayerRef = React.createRef();
    this.handleSetNaturalImageDimensions = this.handleSetNaturalImageDimensions.bind(this);
  }

  //Add event listener
  componentDidMount () {
    this.canvasLayer.show();
    this.canvasLayer.batchDraw();
    this.imageDiv.focus();

    this.imgLayer.listening(false); // perf improvement
  }

  componentDidUpdate () {
    this.imageDiv.focus();
  }

  getAnnotations () {
    return store.cleanAnnotations;
  }

  getBinaryMatrix (parentCallback) {
    var shapesLayer = this.shapesLayerRef.current;

    // gets the matrix and sends to parent thought the callback function
    var callback = (function (img) {
      var binaryMatrix = base64ImageToBinaryMatrix(img, appPropStore.naturalImageDimensions);
      parentCallback(binaryMatrix);
    });

    shapesLayer.toImage({
      ...appPropStore.userImageDimensions,
      callback: callback.bind(this)
    });
  }

  getJpgImage (parentCallback, width, height) {
    if (width === -1 && height === -1) {
      console.error('No width or height defined.');
      return -1;
    } else if (width === undefined && height === undefined) {
      width = appPropStore.stageDimensions.width;
      height = appPropStore.stageDimensions.height;
    } else if (width === -1) { // Calculate the matching width/height in case one is not defined
      width = height * appPropStore.naturalImageDimensions.width / appPropStore.naturalImageDimensions.height;
    } else if (height === -1) {
      height = width * appPropStore.naturalImageDimensions.height / appPropStore.naturalImageDimensions.width;
    }

    var shapesLayer = this.shapesLayerRef.current;
    //callback for the  "get background img"
    var callbackImg = (function (backgroundImg) {
      //callback for the  "get shapes layer img"
      var callback = (function (img) {
        var jpgImg = base64ImageToJpgImage(backgroundImg, img, { width: width, height: height });
        parentCallback(jpgImg);
      });
      // get the shapes layer img
      shapesLayer.toImage({
        ...appPropStore.stageDimensions,
        callback: callback.bind(this)
      });
    });

    // get the background img
    this.imgLayer.toImage({
      ...appPropStore.stageDimensions,
      callback: callbackImg.bind(this)
    });
  }

  getFullJpgImage (parentCallback, width, height) {
    var tempScale = appPropStore.scale;
    var tempOffset = appPropStore.offset;
    appPropStore.setScale(1);
    appPropStore.setOffset({ x: 0, y: 0 });

    var shapesLayer = this.shapesLayerRef.current;
    //callback for the  "get background img"
    var callbackImg = (function (backgroundImg) {
      //callback for the  "get shapes layer img"
      var callback = (function (img) {
        var jpgImg = base64ImageToJpgImage(backgroundImg, img, appPropStore.naturalImageDimensions);
        appPropStore.setScale(tempScale);
        appPropStore.setOffset(tempOffset);

        parentCallback(jpgImg);
      });
      // get the shapes layer img
      shapesLayer.toImage({
        ...appPropStore.userImageDimensions,
        callback: callback.bind(this)
      });
    });

    // get the background img
    this.imgLayer.toImage({
      ...appPropStore.userImageDimensions,
      callback: callbackImg.bind(this)
    });
  }

  updateAnnotationsOnStore (newAnnotations) {
    store.setAnnotations(newAnnotations.map(newAnt => new Annotation(newAnt)));
  }

  //Add new annotation
  handleAddAnnotation (annotationShape, annotation, notes, annotationType, isUpdateOfCurrent) {
    this.props.annotationTypes.map((annotationType, index) => (annotationType['defaultChecked'] = false));
    this.props.annotationTypes.filter(obj => obj.keyName === annotationType)[0]['defaultChecked'] = true;

    annotation['annotation']['shape'] = annotationShape;
    annotation['annotation']['annotationType'] = annotationType;
    annotation['annotation']['color'] = this.props.annotationTypes.filter(obj => obj.keyName === annotationType)[0].color;
    annotation['annotation']['notes'] = notes;

    //Create the annotation object for the store
    let annt = new Annotation();
    annt.annotation = annotation.annotation;

    if (isUpdateOfCurrent) {
      store.updateCurrentAnnotation(annt);
      this.props.updateWizard(); //Required to update the text because of the 'observable.ref' on the annotation in mobx (not needed if it was 'observable.deep')
    } else {
      if (store.annotations.length === 1 && store.annotations[0].annotation.shape === 'IMAGECATEGORY' && store.annotations[0].annotation.annotationType.toUpperCase() === 'NOTHING') {this.handleRemoveAnnotation(store.annotations[0].id);} // See if it has an "NOTHING" annotation and remove it
      store.pushAnnotation(annt);
    }

    let action = isUpdateOfCurrent ? 'UPDATE' : 'ADD';
    this.props.notifyChangesCallback(action, annotation.annotation, store.cleanAnnotations, annt);
    // this.forceUpdate(); //NOTE necessary to show the newly updated annotation on wizard mode because of the 'ref' on mobx
  }

  /**
   * handleUpdateAnnotationInfo TO BE DEPRECATED -> replaced by handleUpdateAnnotationsInfo()
   */
  handleUpdateAnnotationInfo (annotation, notes, annotationType) { this.handleUpdateAnnotationsInfo([annotation], notes, annotationType); }
  // Update annotations
  handleUpdateAnnotationsInfo = (annotations, notes, annotationType) => {
    const annotationTypeColor = annotationType != null ? this.props.annotationTypes.filter(obj => obj.keyName === annotationType)[0].color : null;
    const updatedAnnotations = annotations.map(ant => {
      ant.setNotes(notes);
      if (annotationType != null) {
        ant.setAnnotationType(annotationType);
        ant.setColor(annotationTypeColor);
      }
      return ant.annotation;
    });
    this.props.notifyBulkChangesCallback('UPDATE', updatedAnnotations, store.cleanAnnotations);
  }

  /**
   * handleRemoveAnnotation TO BE DEPRECATED -> replaced by handleDeleteAnnotations()
   */
  handleRemoveAnnotation (annotationId) {
    let removedAnnotation = store.removeAnnotationById(annotationId);
    if (removedAnnotation === undefined) return;
    this.clearToolTip();

    this.props.notifyChangesCallback('DELETE', removedAnnotation, store.cleanAnnotations);
    this.props.onAnnotationChanged(); // So it refocus on the new annotation on wizard
  }
  // Delete DeleteAnnotations
  handleDeleteAnnotations = (annotations) => {
    const removedAnnotations = annotations.map(ant => store.removeAnnotationById(ant.id)).filter(ant => ant);
    if (removedAnnotations.length === 0) return;
    this.clearToolTip();

    this.props.notifyBulkChangesCallback('DELETE', removedAnnotations, store.cleanAnnotations, null);
    this.props.onAnnotationChanged(); // So it refocus on the new annotation on wizard
  }

  handleOnClick (e) {
    if (e.evt.shiftKey && (appPropStore.isEditEnabled || appPropStore.isDrawingEnabled) && appPropStore.activeTool !== 'SelectionTool') {
      this.handleRemoveAnnotation.bind(this)(e.target.attrs.id);
      appPropStore.annotatorRef.imageDiv.style.cursor = 'default';
    }
  }

  //handles to callback to receive the natural dimensions of the <img>
  handleSetNaturalImageDimensions (dim) {
    let userImageDimensions = this.getUserImageDimensions.bind(this)(this.imageDiv, dim);

    appPropStore.setNaturalImageDimensions(dim);
    appPropStore.setUserImageDimensions(userImageDimensions);

    this.updateAnnotationsOnStore(this.props.annotations);

    this.props.onImageChanged();
    this.setState({
      childrenLoaded: true
    });
  }

  handleStageMouseMove (e) {
    if (!appPropStore.isDrawingKeyDown && !this.canvasLayer.attrs.visible) {
      this.canvasLayer.hide();
      this.canvasLayer.batchDraw();
      appPropStore.annotatorRef.imageDiv.style.cursor = 'default';
    }

    if (e.evt.buttons !== 1) { // If no mouse button is down show the
      this.showToolTip(e);
    }
  }

  // Called from parent
  handleDrawingKeyDown (e) {
    if (/*this.state.mouseOverImageDiv &&*/ appPropStore.isDrawingEnabled) {
      this.canvasLayer.show();
      this.canvasLayer.moveToTop();
      this.canvasLayer.batchDraw();
      this.setState({ isUserDrawing: true });
    }
  }
  handleDrawingKeyUp (e) {
    if (this.shapesLayerRef.current) {
      this.shapesLayerRef.current.moveToTop();
      this.shapesLayerRef.current.batchDraw();
    }
    this.setState({ isUserDrawing: false });
  }

  showToolTip (e) {
    let shape = store.annotations.filter(obj => obj.id === e.target.attrs.id)[0]; // Gets the selected one
    if (!shape) return; // If it's for example an anchor

    this.tooltip.position({
      ...getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset)
    });


    let annotationTypeName = this.props.annotationTypes.filter(obj => obj.keyName === shape.annotation.annotationType)[0].friendlyName;
    this.tooltip.text(`${shape.annotation.notes }\n(${ annotationTypeName })`);
    this.tooltip.show();
    this.tooltipLayer.batchDraw();
  }

  clearToolTip () {
    this.tooltip.hide();
    this.tooltipLayer.batchDraw();
  }

  /* HANDLE COMPONENT SIZING */
  //Returns them dimensions of component 'imageDiv' having an image with dimensions 'naturalImageDimensions'
  getUserImageDimensions (imageDiv, naturalImageDimensions) {
    var rect = getBoundingRect(imageDiv);
    appPropStore.setAnnotatorBoundingRect(rect);

    var userImageDimensions = {};
    if (naturalImageDimensions.width > naturalImageDimensions.height) {
      userImageDimensions = {
        width: rect.width,
        height: (rect.width * naturalImageDimensions.height / naturalImageDimensions.width)
      };
    } else {
      userImageDimensions = {
        width: (rect.height * naturalImageDimensions.width / naturalImageDimensions.height),
        height: rect.height
      };
    }
    return userImageDimensions;
  }

  handleComponentResize () {
    var userImageDimensions = this.getUserImageDimensions.bind(this)(this.imageDiv, appPropStore.naturalImageDimensions);

    // OPTIMIZATION: So it does not update every time
    //if (Math.abs(userImageDimensions.width - this.lastResize.width + userImageDimensions.height - this.lastResize.height) < 15) return;
    //this.lastResize = userImageDimensions;

    appPropStore.setUserImageDimensions(userImageDimensions);
  }
  /* END HANDLE COMPONENT SIZING */

  getStage () {
    return this.stage.getStage();
  }

  render () {
    return (
      <div id="annotatorDiv" className="annotatorDiv well" ref={instance => { this.imageDiv = instance; }} onMouseEnter={(e) => e.target.focus()}
        style={{ cursor: appPropStore.isDrawingKeyDown && appPropStore.isDrawingEnabled && appPropStore.activeTool !== 'DeleteTool' || appPropStore.isShiftDown && appPropStore.isEditEnabled ? 'crosshair' : 'default' }}>
        <Popup escToClose={false}/>
        <ContextMenuTrigger id="annotator_context_menu" holdToDisplay={-1}>
          <Stage
            ref={instance => { this.stage = instance; }}
            className="innerAnnotatorDiv"
            width={appPropStore.stageDimensions.width} height={appPropStore.stageDimensions.height}
            onMouseEnter={() => {this.setState({ mouseOverImageDiv: true }); if (this.state.isUserDrawing) appPropStore.annotatorRef.imageDiv.style.cursor = 'crosshair';}}
            onMouseLeave={(e) => {
              if (e.evt.toElement != null && e.evt.toElement.localName === 'canvas') return; /*When it fires wrongly changing to its children (HARD FIX) */
              this.setState({ mouseOverImageDiv: false }); appPropStore.annotatorRef.imageDiv.style.cursor = 'default';
            }}
            onMouseMove={this.handleStageMouseMove.bind(this)}
            x={appPropStore.offset.x} y={appPropStore.offset.y} scale={{ x: appPropStore.scale, y: appPropStore.scale }}
          >

            {/* draw the image */}
            <Layer
              ref={instance => { this.imgLayer = instance; }}
              onMouseOver={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'default')}
            >
              <Img
                src={this.props.childImagePath} space="fill"
                width={appPropStore.userImageDimensions.width} height={appPropStore.userImageDimensions.height}
                SetNaturalDimensions={this.handleSetNaturalImageDimensions}
                subsequentImageSrc={this.props.subsequentImageSrc}
                annotatorBoundingRect={appPropStore.annotatorBoundingRect}
                dontUseExifOrientation={appPropStore.dontUseExifOrientation}

                contrast={imageToolsStore.contrastValue}
                brightness={imageToolsStore.brightnessValue}
                grayscale={imageToolsStore.grayscaleValue}
              />
            </Layer>

            {/* draw the canvas */}
            <Layer ref={instance => { this.canvasLayer = instance; }}>
              { appPropStore.isDrawingEnabled && this.state.childrenLoaded ?
                AnnotationFactory.createCanvasComponent(
                  appPropStore.activeTool,
                  this.props.annotationTypes,
                  {
                    add: this.handleAddAnnotation.bind(this),
                    remove: this.handleRemoveAnnotation.bind(this),
                    update: this.handleUpdateAnnotationInfo.bind(this)
                  },
                  this.shapesLayerRef) :
                <Rect {...appPropStore.userImageDimensions} transformsEnabled={'none'}/>
              }
            </Layer>


            {appPropStore.drawAnnotations && /* draw the annotations */
            <Fragment>
              <Layer
                ref={this.shapesLayerRef}
                onClick={this.handleOnClick.bind(this)}
                onMouseOut={this.clearToolTip.bind(this)}
                onMouseDown={this.clearToolTip.bind(this)}
                listening={!this.state.isUserDrawing}
              >
                { this.state.childrenLoaded && !appPropStore.wizardMode && appPropStore.isEditEnabled &&
                  store.annotations.map(function (annotation) {
                    return AnnotationFactory.createEditableAnnotationComponent(annotation, this.props.notifyChangesCallback, this.handleUpdateAnnotationInfo.bind(this));
                  }, this)
                }
                { this.state.childrenLoaded && !appPropStore.wizardMode && !appPropStore.isEditEnabled &&
                  store.annotations.map(AnnotationFactory.createAnnotationComponent)
                }
                { this.state.childrenLoaded && appPropStore.wizardMode && appPropStore.isEditEnabled &&
                  AnnotationFactory.createEditableAnnotationComponent(store.currentAnnotation, this.props.notifyChangesCallback, this.handleUpdateAnnotationInfo.bind(this))
                }
                { this.state.childrenLoaded && appPropStore.wizardMode && !appPropStore.isEditEnabled &&
                  AnnotationFactory.createAnnotationComponent(store.currentAnnotation)
                }
                {this.state.childrenLoaded && store.presetAnnotationsGroupEnabledForPreview &&
                  store.presetAnnotationsGroupEnabledForPreview.annotations.map(AnnotationFactory.createPreviewAnnotationComponent)
                }
              </Layer>

              {/* draw the tooltip */}
              <Layer ref={instance => { this.tooltipLayer = instance; }} listening={false} >
                <Text ref={instance => { this.tooltip = instance; }} fill="white" shadowColor="black"
                  listening={false} hitGraphEnabled={false} visible={false} padding={8}
                  scale={{ x: 1 / appPropStore.scale, y: 1 / appPropStore.scale }} />
              </Layer>
            </Fragment>
            }
            { appPropStore.isEditEnabled && appPropStore.isShiftDown && appPropStore.activeTool !== 'SelectionTool' &&
            <Layer>
              <DeleteTool
                annotationTypes={this.handleAddAnnotation.bind(this)}
                updateAnnotationInfo={this.handleUpdateAnnotationInfo.bind(this)}
                removeAnnotation={this.handleRemoveAnnotation.bind(this)}
                shapesLayerRef={this.shapesLayerRef}
              />
            </Layer>
            }
          </Stage>
        </ContextMenuTrigger>
        <AnnotatorContextMenu menuId={'annotator_context_menu'} updateAnnotationsInfo={this.handleUpdateAnnotationsInfo} deleteAnnotations={this.handleDeleteAnnotations} />
      </div>
    );
  }
}

Annotator.propTypes = Annotator.propTypes;

export default Annotator;
