import {BASE_URL, KEYCLOAK_URL} from '../constants/index'
import { getAllAcquisitions, getAcquisitionImagesCount } from './acquisitions'
import 'isomorphic-fetch'
import { getAllDatasets } from './datasets'


export async function getAllUsers() {
    let results = []
    await fetch(BASE_URL + 'users/', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {
        results = data
    })
    return results
}


export const fetchUsers = async (callback) => {
    return await fetch(`${BASE_URL}keycloak/users`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(function (response) {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
        return
      }
      return response.json()
    })
    .then(users => {
        return users
    })
}

export const getUserInfo = async (userId) => {
    // return fetch(`${KEYCLOAK_URL}/users/${userId}`, {
    return fetch(`${BASE_URL}keycloak/users/${userId}`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(function (response) {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
        return
      }
      return response.json()
    })
    .then(user => {
        return user
    })
}

export async function getUser(userId) {
    let userAcqs = []
    let data = {
        user: undefined,
        datasets: [],
        acquisitions: [],
        user_Acquisitions: []
    }

    await Promise.all([
        await getUserInfo(userId)
        .then(userData => {
            data['user'] = userData
        }),
        await getAllDatasets()
        .then(datasets => {
            data['datasets'] = datasets
        }),
        await getAllAcquisitions()
        .then(allAcqs => {
            userAcqs = allAcqs.filter(acq => acq.ownerId === userId)
            data['acquisitions'] = allAcqs
            data['user_Acquisitions'] = userAcqs
        })
    ]).then(() => {
        return data
    })
    return data
}
