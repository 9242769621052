import React, { Component, Fragment } from 'react'
import { Container, Button, Paper, Typography,
    IconButton, TextField, FormControl, Select,
    InputLabel, MenuItem } from '@material-ui/core'
import { CloseRounded as CloseIcon } from '@material-ui/icons'
import { ACQUISITION_DEFAULT_METADATA, EMPTY_ACQUISITION } from '../constants'
import Loading from './Loading'
import { getAllDatasets } from '../requests/datasets'
import { fetchUsers } from '../requests/users'
import { addNonAnnotationImage } from '../requests/acquisitions'

class AddNewDataset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            description: ''
        }
    }

    render () {
        return (
            <Paper style={{marginBottom: '3rem', padding: '2rem 3rem'}}>
                <form style={{display: 'flex', flexDirection: 'column', marginBottom: '1rem'}}>
                    <TextField
                        label='Name'
                        value={this.state.name}
                        onChange={event => this.setState({name: event.target.value})}
                        margin='normal'
                        variant='outlined'
                    />
                    <TextField
                        label='Description'
                        value={this.state.description}
                        onChange={event => this.setState({description: event.target.value})}
                        margin='normal'
                        variant='outlined'
                        multiline
                        rows='2'
                        rowsMax='4'
                    />
                </form>
                <Button variant='contained'
                    className="coloredButton"
                    onClick={() => {
                        this.props.addNew(this.state)
                        this.props.toggleForm()
                    }}
                >
                    Create new Dataset
                </Button>
            </Paper>
        )
    }

}

class AddNewAcquisition extends Component {
    constructor (props) {
        super (props)
        this.state = {
            ...EMPTY_ACQUISITION,
            ...ACQUISITION_DEFAULT_METADATA,
            loading: true
        }
    }

    async componentDidMount() {
        let datasets = []
        let users = []
        Promise.all([
            await getAllDatasets().then(dts => {
                datasets = dts
            }),
            await fetchUsers().then(usr => {
                users = usr
            })
        ]).then(() => {
            this.setState({
                datasetId: this.props.datasetId || '',
                availableDatasets: datasets,
                availableUsers: users,
                loading: false
            })
        })
    }

    render () {
        if (this.state.loading) {
            return <Loading />
        } else {
            return (
                <Paper className='acquisitionForm' style={{marginBottom: '3rem', padding: '2rem 3rem'}}>
                    <form style={{display: 'flex', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem'}}>
                        <TextField required
                            label='Sample ID'
                            value={this.state.sampleId}
                            onChange={event => this.setState({sampleId: event.target.value})}
                            margin='normal'
                            variant='outlined'
                        />
                        <div className='dropdownElements' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <FormControl variant='outlined'>
                                <InputLabel htmlFor="outlined-datasetId">
                                    Dataset Id
                                </InputLabel>
                                <Select
                                    disableUnderline
                                    value={this.state.datasetId}
                                    onChange={ev => this.setState({datasetId: ev.target.value})}
                                    inputProps={{
                                        name: 'Dataset Id',
                                        id: 'outlined-datasetId',
                                    }}
                                >
                                    <MenuItem value=''><em>None</em></MenuItem>
                                    {this.state.availableDatasets.map(dataset =>
                                        <MenuItem key={dataset.id} value={dataset.id}>
                                            {dataset.name}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>

                            <FormControl variant='outlined'>
                                <InputLabel htmlFor="outlined-ownerId">
                                    owner Id
                                </InputLabel>
                                <Select
                                    disableUnderline
                                    value={this.state.ownerId}
                                    onChange={ev => this.setState({ownerId: ev.target.value})}
                                    inputProps={{
                                        name: 'Dataset',
                                        id: 'outlined-ownerId',
                                    }}
                                >
                                    <MenuItem value=''><em>None</em></MenuItem>
                                    {this.state.availableUsers.map(user =>
                                        <MenuItem key={user.id} value={user.id}>
                                            {(user['firstName'] && user['lastName']) ? (
                                                user['firstName'] + ' ' + user['lastName']
                                            ) : (
                                                user['username']
                                            )}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                    <Button
                        variant='contained'
                        className="formBtn"
                        disabled={this.state.sampleId === ''}
                        onClick={() => {
                            this.props.addNew({
                                datasetId: this.state.datasetId,
                                tags: [],
                                metadata: {
                                    sampleId: this.state.sampleId,
                                    ...ACQUISITION_DEFAULT_METADATA,
                                    done: 'false'
                                }
                            }, this.state.ownerId)
                            this.setState({...EMPTY_ACQUISITION})
                            this.props.toggleForm()
                        }}
                    >
                        Create new Acquisition
                    </Button>
                </Paper>
            )
        }
    }
}

class AddNewImageSample extends Component {
    constructor (props) {
        super (props)
        this.state = {
            images: [],
            files: []
        }
    }

    render () {
        return (
            <Paper style={{marginBottom: '3rem', padding: '2rem 3rem'}}>
                <form>
                    <label htmlFor="contained-button-file"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #000000de',
                            borderRadius: '4px',
                            padding: '1rem',
                            marginBottom: '1rem'
                        }}
                    >
                        <Typography style={{color: '#000000de', opacity: '0.87', marginBottom: '1rem'}}>
                            Acquisition Image Files
                        </Typography>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
                            <Button variant="contained" component="span" style={{marginBottom: '1rem'}}>
                                Upload
                            </Button>
                            {Array.from(this.state.images).map((file, ind) => (
                                <div key={ind} style={{marginLeft: '2rem'}}>
                                    <Typography>{file['name']}</Typography>
                                    
                                </div>
                            ))}
                            <IconButton onClick={() => {
                                // const newFiles = this.state.files
                                // newFiles.splice(ind, 1) // TODO: find splice alternative to FileList
                                this.setState({
                                    images: []
                                })
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        
                    </label>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={event => {
                            this.setState({images: event.target.files})
                        }}
                        id="contained-button-file"
                        multiple
                        type="file"
                    />
                </form>

                <form>
                    <label htmlFor="contained-button-file2"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #000000de',
                            borderRadius: '4px',
                            padding: '1rem',
                            marginBottom: '1rem'
                        }}
                    >
                        <Typography style={{color: '#000000de', opacity: '0.87', marginBottom: '1rem'}}>
                            Acquisition CSV / XLSX Files
                        </Typography>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
                            <Button variant="contained" component="span" style={{marginBottom: '1rem'}}>
                                Upload
                            </Button>
                            {Array.from(this.state.files).map((file, ind) => (
                                <div key={ind} style={{marginLeft: '2rem'}}>
                                    <Typography>{file['name']}</Typography>
                                    
                                </div>
                            ))}
                            <IconButton onClick={() => {
                                // const newFiles = this.state.files
                                // newFiles.splice(ind, 1) // TODO: find splice alternative to FileList
                                this.setState({
                                    files: []
                                })
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        
                    </label>
                    <input
                        accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, .xls'
                        style={{display: "none"}}
                        onChange={event => {
                            this.setState({files: event.target.files})
                        }}
                        id="contained-button-file2"
                        multiple
                        type="file"
                    />
                </form>

                <Button variant='container'
                    className="coloredButton"
                    onClick={() => {
                        this.props.addNew(this.state.images, this.state.files)
                        this.props.toggleForm()
                    }}
                >
                    Add Samples to Acquisition
                </Button>
            </Paper>
        )
    }
}

const AddNewContainer = ({title, addNew, toggleForm, ...props}) => {
    switch (title) {
        case 'Datasets':
            return <AddNewDataset addNew={addNew} toggleForm={toggleForm} />
        case 'Acquisitions':
            return <AddNewAcquisition addNew={addNew} toggleForm={toggleForm} datasetId={props['datasetId']} />
        case 'Samples':
            return <AddNewImageSample addNew={addNew} toggleForm={toggleForm} />
        default:
            return null;
    }
}

export default AddNewContainer