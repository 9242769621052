import React, { Component } from 'react';
import Konva from 'konva';
import { Image } from 'react-konva';
import loadImage from 'blueimp-load-image';
import appPropStore from './../models/app-properties-store';

var imgCache = {
  // brokenImage: document.createElement("img")
};

// var brokenImage = imgCache.brokenImage;
// brokenImage.src = "/broken-image.png";
// brokenImage.onload = function () {
//   //console.log("preloaded broken image");
//   this.brokenImage = true;
// };


class Img extends Component {
  constructor (props) {
    super(props);
    this.state = {
      image: null
    };
    this.subsequentImage = null;
    this.imageRef = React.createRef();
  }

  /**
   * This method was implemented because I found that the component was rerendering without any apparent changes.
   * A possible cause may be on the this.state.image.
   */
  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.src !== nextProps.src ||
		this.props.space !== nextProps.space ||
		this.props.width !== nextProps.width ||
		this.props.height !== nextProps.height ||
		this.props.SetNaturalDimensions !== nextProps.SetNaturalDimensions ||
		this.props.subsequentImageSrc !== nextProps.subsequentImageSrc ||
		this.props.dontUseExifOrientation !== nextProps.dontUseExifOrientation ||
		this.props.contrast !== nextProps.contrast ||
		this.props.brightness !== nextProps.brightness ||
		this.props.grayscale !== nextProps.grayscale ||
		this.state.image !== nextState.image
    ) return true;
    return false;
  }

  componentDidUpdate (prevProps, prevState) {
    const { contrast, brightness, grayscale } = this.props;
    if (this.state.image && (contrast !== 0 || brightness !== 0 || grayscale)) {
      this.imageRef.current.cache({
        // drawBorder: true, // Useful for debug
        imageSmoothingEnabled: false,
        pixelRatio: 4
      });
      // since this update is not handled by "react-konva" and we are using Konva methods directly
      // we have to redraw layer manually
      this.imageRef.current.getLayer().batchDraw();
    } else if (this.state.image) {
      this.imageRef.current.clearCache();
      this.imageRef.current.getLayer().batchDraw();
    }
  }

  UNSAFE_componentWillMount () {
    if (this.props.dontUseExifOrientation) {
      this.preloadImg(
        this.props.src,
        this.props.dontUseExifOrientation,
        () => this.loadImg(imgCache[this.props.src], this.props.SetNaturalDimensions)
      );
    } else { this.loadImg(this.props.src, this.props.SetNaturalDimensions); }

    // Preload subsequent images
    this.subsequentImage = document.createElement('img'); // Create the element to preload the images
    if (this.props.subsequentImageSrc) this.preloadImg(this.props.subsequentImageSrc, this.props.dontUseExifOrientation, () => appPropStore.setPreloadedImage(this.props.subsequentImageSrc)); // Preload subsequent image
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.src !== this.props.src || nextProps.width !== this.props.width || nextProps.height !== this.props.height) {
      if (nextProps.dontUseExifOrientation) {
        if (imgCache[nextProps.src]) this.loadImg(imgCache[nextProps.src], this.props.SetNaturalDimensions);
        else this.preloadImg(nextProps.src, nextProps.dontUseExifOrientation, () => this.loadImg(imgCache[nextProps.src], this.props.SetNaturalDimensions));
      } else { this.loadImg(nextProps.src, this.props.SetNaturalDimensions); }
    }
    if (nextProps.subsequentImageSrc !== this.props.subsequentImageSrc) {
      if (Object.keys(imgCache).length > 3) Reflect.deleteProperty(imgCache, Object.keys(imgCache)[0]); //delete first key
      if (nextProps.subsequentImageSrc && !imgCache[nextProps.src]) this.preloadImg(nextProps.subsequentImageSrc, nextProps.dontUseExifOrientation, () => appPropStore.setPreloadedImage(nextProps.subsequentImageSrc)); // Preload subsequent image
      else appPropStore.setPreloadedImage(nextProps.subsequentImageSrc);
    }
  }

  preloadImg (imgSrc, dontUseExifOrientation, callback) {
    if (!dontUseExifOrientation) { // The browsers are now supporting automatic image orientation
      this.subsequentImage.src = imgSrc;
      if (callback) callback();
    } else {
      loadImage(
        imgSrc,
        (img, data) => img.toBlob((blob) => {
          imgCache[imgSrc] = URL.createObjectURL(blob);
          if (callback) callback();
        }),
        { canvas: true, orientation: 1, imageSmoothingEnabled: false }
      );
    }
  }

  loadImg (src, setNaturalDimensions) {
    if (!src) {
      throw new Error(`Expected image src instead saw ${ typeof src}`);
    }

    var img = imgCache[src];
    if (!img) {
      //console.log("cacheImg...");
      //img = imgCache[src] = document.createElement("img"); //NOTE: this WILL cache the image
      img = document.createElement('img'); //NOTE: this will NOT cache the image
      img.crossOrigin = 'anonymous'; // So we can retrieve the image from other servers
      img.loadFns = [];
      img.errorFns = [];
      img.onerror = function () {
        img.error = true;
        console.error('image error handlers', img.errorFns);
        img.errorFns.forEach(fn => fn.call(img));
      };
      img.onload = () => {
        var hasNH = 'naturalHeight' in img;
        var w = hasNH ? 'naturalWidth' : 'width';
        var h = hasNH ? 'naturalHeight' : 'height';
        var invalidImg = img[w] + img[h] === 0;

        if (invalidImg) {
          console.error('calling image onerror');
          img.onerror();
        } else {
          if (setNaturalDimensions) { setNaturalDimensions({ height: img.naturalHeight, width: img.naturalWidth }); }
          img.loaded = true;
          //console.log("image load handlers", img.loadFns);
          img.loadFns.forEach(fn => fn.call(img));
        }
      };
    }

    if (!img.loaded && !img.error) {
      //console.log("set handlers");
      img.loadFns.push(() => {
        img.loaded = true;
        this.setState({ image: img });
        //console.log("Image loaded", src);
      });

      img.errorFns.push(() => {
        img.error = true;
        this.setState({ image: null }); // this.setState({ error: true, image: brokenImage });
        console.error('Error loading image', src, this.state);
      });
    } else if (img.error) {
      this.setState({ image: null }); // this.setState({ error: true, image: brokenImage });
      console.error('Error previously loading image', src);
    } else {
      this.setState({ image: img });
      console.log('Image pre-loaded', src);
    }

    if (!img.src) {
      //console.log("set img src to", src);
      img.src = src;
    }
  }

	fillRect = (p, c) => {
	  return (c.width / c.height) < (p.width / p.height) ?
	    { width: p.width, height: c.height * (p.width / c.width) } :
	    { height: p.height, width: c.width * (p.height / c.height) };
	};

	fitRect = (p, c) => {
	  return (c.width / c.height) > (p.width / p.height) ?
	    { width: p.width, height: c.height * (p.width / c.width) } :
	    { height: p.height, width: c.width * (p.height / c.height) };
	};

	getDims = (image, width, height, space) => {
	  var selfDims = { width, height };
	  var imageDims = image ? { width: image.width, height: image.height } : selfDims;

	  switch (space) {
	  case 'fill':
	    return this.fillRect(selfDims, imageDims);

	  case 'fit':
	  default:
	    return this.fitRect(selfDims, imageDims);
	  }
	};

	/**
	 * @returns Returns the filters to activate and their properties.
	 * 			To improve performance, does not return filters if their values are the default values.
	 */
	getFilterProps = (contrast, brightness, grayscale) => {
	  const useFilters = [];
	  if (contrast) useFilters.push(Konva.Filters.Contrast);
	  if (brightness) useFilters.push(Konva.Filters.Brighten);
	  if (grayscale) useFilters.push(Konva.Filters.Grayscale);

	  return {
	    filters: useFilters,
	    contrast,
	    brightness
	  };
	};

	render = () => {
	  const { contrast, brightness, grayscale } = this.props;
	  const dims = this.getDims(this.props.image, this.props.width, this.props.height, this.props.space);

	  return (
	    <Image ref={this.imageRef} image={this.state.image} width={dims.width} height={dims.height}
	      listening={false} transformsEnabled={'all'} hitGraphEnabled={false}
	      {...this.getFilterProps(contrast, brightness, grayscale)}
	    />
	  );
	};
}

export default Img;
