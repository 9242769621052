import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ConfirmDialog from '../prompts/ConfirmDialog';
import './index.css';

import store, { Annotation } from '../../models/annotations-store';
import dataStore from '../../models/data-store';
import { BsEye, BsPlus, BsCheck } from 'react-icons/bs';

@observer
class AnnotationsPresets extends Component {
  static propTypes = {
    notifyChangesCallback: PropTypes.func.isRequired,
    shouldDisablePresetAnnotationsGroupFunc: PropTypes.func
  }

  constructor (props) {
    super(props);
    this.state = {
      showAlertDialog: false,
      annotationGroupClickedToAdd: null
    };
  }

  handleAnnotationsShowClick = (antGroup) => {
    const { presetAnnotationsGroupEnabledForPreview } = store;
    if (presetAnnotationsGroupEnabledForPreview?.sourceObject === antGroup) { this.hideAnnotationsPreview(); } else { this.showAnnotationsPreview(antGroup); }
  }

  showAnnotationsPreview = (antGroup) => store.setPresetAnnotationsGroupEnabledForPreviewFromSource(antGroup);
  hideAnnotationsPreview = () => store.setPresetAnnotationsGroupEnabledForPreviewFromSource(null);

  useAnnotations = (antGroup) => {
    const newAnnotations = antGroup.annotations.map(orgAnt => new Annotation(orgAnt));
    newAnnotations.forEach(ant => store.pushAnnotation(ant));
    this.props.notifyChangesCallback('ADD', newAnnotations.map(ant => ({ ...ant.annotation })), store.cleanAnnotations, newAnnotations);
  }

  /**
   * @param antGroup: group of annotations to be selected to add
   * if @param antGroup is null, the adding state will be cleared
   */
  handleAddAnnotationsClick = (antGroup) => {
    this.setState({
      showAlertDialog: antGroup != null,
      annotationGroupClickedToAdd: antGroup
    });
  }
  handleAddAnnotationConfirm = () => {
    const { annotationGroupClickedToAdd } = this.state;
    this.useAnnotations(annotationGroupClickedToAdd);
    this.handleAddAnnotationsClick(null);
    this.hideAnnotationsPreview();
  }

  render () {
    const { showAlertDialog, annotationGroupClickedToAdd } = this.state;
    const { shouldDisablePresetAnnotationsGroupFunc } = this.props;
    const { annotationsPresets } = dataStore.currentImageData;
    const { presetAnnotationsGroupEnabledForPreview } = store;

    return (
      <div className="annotationsPresetsDiv" id={'annotationsPresetsDiv'}>
        <h1 className={'annotationsPresetsTitle'} />
        <div className="row" >
          <ul className="annotationsPresetsList">
            {annotationsPresets?.length > 0 ?
              annotationsPresets.map(antGroup => (
                <li key={antGroup.name}>
                  <label htmlFor={antGroup.name}>
                    <div className="annotationsPresetsGroupContainer">
                      <span className="annotationsPresetsGroupName">{antGroup.name}</span>
                      <div className="buttonsDiv">
                        <label htmlFor="PreviewAnnotations" className={`btn ${antGroup.previewActive ? 'btn-primary' : 'btn-default'} btn-sm`} title="Preview Annotations"
                          style={{ opacity: antGroup === presetAnnotationsGroupEnabledForPreview?.sourceObject ? null : 0.4 }}
                          onMouseDown={() => this.handleAnnotationsShowClick(antGroup)}
                        >
                          <BsEye />
                        </label>
                        {(shouldDisablePresetAnnotationsGroupFunc && shouldDisablePresetAnnotationsGroupFunc(store.cleanAnnotations, antGroup)) ?
                          <label title="Annotations in use. Delete all related annotations if you want to re-add them again.">
                            <BsCheck color="green" />
                          </label> :
                          <label className={'btn btn-default btn-sm'} title="Use Annotations"
                            onClick={() => this.handleAddAnnotationsClick(antGroup)}
                          >
                            <BsPlus />
                          </label>
                        }
                      </div>
                    </div>
                  </label>
                </li>)) :
              <p className={'annotationsPresetsEmptyMessage'} />
            }
          </ul>
        </div>
        {showAlertDialog && <ConfirmDialog
          title="Adding annotations"
          description={`Add annotations from '${annotationGroupClickedToAdd.name}'`}
          handleCancel={() => this.handleAddAnnotationsClick(null)}
          handleConfirm={() => this.handleAddAnnotationConfirm()}
        />}
      </div>
    );
  }
}

export default AnnotationsPresets;
