import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Circle, Group } from 'react-konva';
import { getNaturalCoords } from '../../../utils/Utils';

import appPropStore from './../../../models/app-properties-store';
import store from './../../../models/annotations-store';

@observer
class Anchors extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isDraggingAnchor: false
    };
  }

  _onAnchorDragMove (e) {
    var storeAnnotation = this.props.annotation;
    var points = this.props.annotation.annotation.naturalAttributes.points.slice();

    let coords = getNaturalCoords({ x: e.target.attrs.x, y: e.target.attrs.y }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    points[e.target.attrs.id] = coords.x;
    points[e.target.attrs.id + 1] = coords.y;

    storeAnnotation.setNaturalAttributes({ ...this.props.annotation.annotation.naturalAttributes, points: points });
  }

  // This method would delete a vertex
  // _onAnchorMouseUp (e) {
  //   if (!e.evt.shiftKey) { return; }

  //   var annotation = this.props.annotation;
  //   annotation.annotation.naturalAttributes.points.splice(e.target.attrs.id, 2);

  //   store.updateStoreAnnotation(annotation);
  //   this.props.forceParentUpdate();
  // }

  render () {
    const { isDraggingAnchor } = this.state;

    let anchors = [];
    let annotation = this.props.annotation;
    for (let i = 0; i + 1 < annotation.internalAttributes.points.length; i = i + 2) {
      anchors.push(
        <Circle
          x={annotation.internalAttributes.points[i]} y={annotation.internalAttributes.points[i + 1]} radius={2 / (appPropStore.scale * 0.5)}
          fill="gray" stroke= "black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false}
          id={i} key={i}  strokeWidth={2 / (appPropStore.scale * 0.5)}
          draggable dragOnTop={false} onDragEnd={() => {}}
        />
      );
    }

    return (
      <Fragment>
        {/*Render the anchors */}
        {!appPropStore.isShiftDown &&
        <Group
          onMouseOver={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'pointer')}
          onMouseOut={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'default')}
          // onMouseUp={this._onAnchorMouseUp.bind(this)}
          onDragStart={() => { this.setState({ isDraggingAnchor: true }); }}
          onDragMove={this._onAnchorDragMove.bind(this)}
          onDragEnd={() => { this.setState({ isDraggingAnchor: false }); this.props.notifyChangesCallback('UPDATE', this.props.annotation.annotation, store.cleanAnnotations);}}
          onMouseDown={() => {this.setState({ isDraggingAnchor: true });}}
          onMouseUp={() => {this.setState({ isDraggingAnchor: false });}}
          onTouchStart={() => {this.setState({ isDraggingAnchor: true });}}
          onTouchEnd={() => {this.setState({ isDraggingAnchor: false });}}
        >
          {anchors}
        </Group>
        }
      </Fragment>
    );
  }
}

Anchors.propTypes = {
};

export default Anchors;
