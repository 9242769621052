import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import { Header } from './Header'
// import { hot } from 'react-hot-loader/root'
import './index.css'
import Datasets from './views/DatasetsList'
import Acquisitions from './views/AcquisitionsList'
import Users from './views/UsersList'
import Dataset from './views/Dataset'
import Acquisition from './views/Acquisition'
import User from './views/User'

class App extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Switch>
          <Route exact path="/" component={() => <Acquisitions />} />
          <Route path={`/datasets/:id`} component={Dataset} />
          <Route path={`/datasets`} component={() => <Datasets />} />
          <Route path={`/acquisitions/:id`} component={Acquisition} />
          <Route path={`/acquisitions/`} component={() => <Acquisitions />} />
          <Route path={`/users/:id`} component={User} />
          <Route path={`/users`} component={() => <Users />} />
          {/*<Route path="*" component={() =>  <Acquisitions />} />*/}
        </Switch>
      </Fragment>
    );
  }
}

export default App
