import React, {} from 'react';
import { Rect, Group } from 'react-konva';
// /import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import appPropStore from './../../../models/app-properties-store';

@observer
class RectangleAnnotation extends React.Component {
  render () {
    let fillColor = this.props.color || (this.props.annotation && this.props.annotation.color ? this.props.annotation.color : null) || 'white';
    return (
      <Rect
        x={this.props.internalAttributes.x}
        y={this.props.internalAttributes.y}
        width={this.props.internalAttributes.width}
        height={this.props.internalAttributes.height}
        fill={fillColor}
        stroke={this.props.stroke || 'black'}
        strokeWidth={1 / (appPropStore.scale * 0.5)}
        opacity={this.props.opacity || 0.3}
        draggable={this.props.draggable}
        onDragMove={this.props.onDragMove}
        id={this.props.id}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}

        dashEnabled={this.props.isSelected || false}
        dash={[5 / appPropStore.scale, 5 / appPropStore.scale]}
        // shadowEnabled={this.props.isSelected || false}
        // shadowOpacity={1}
        // shadowOffset={{ x: 5, y: 5 }}
        // shadowColor={"black"}

        fillPriority={this.props.isSelected ? 'radial-gradient' : 'color'}
        fillRadialGradientStartPoint={{
          x: (this.props.internalAttributes.width / 2),
          y: (this.props.internalAttributes.height / 2)
        }}
        fillRadialGradientColorStops={[0, 'white', 1, fillColor]}
        fillRadialGradientEndRadius={Math.abs(Math.max(this.props.internalAttributes.width, this.props.internalAttributes.height))}
      />
    );
  }
}

//Render the list of Rects in props
function RectListRender (props) {
  return (
    <Group>
      {props.list.map(listValue => (
        <RectangleAnnotation
          key={`${ listValue.x }${listValue.y }${listValue.width }${listValue.height}`}
          x={listValue.x} y={listValue.y}
          width={listValue.width} height={listValue.height}
          onClick={(e) => props.onClick(e, listValue.id)}
          color={listValue.color}
          id={listValue.id}
        />
      ))}
    </Group>
  );
}

RectangleAnnotation.propTypes = {
};

export { RectangleAnnotation, RectListRender };
