import { observable, action, computed, configure } from 'mobx';

// configure({ enforceActions: 'observed' }); // don't allow state modifications outside actions
configure({ enforceActions: 'never' });

class ImageData {
  @observable acquisitionId
  @observable annotations = []
  @observable deletedAnnotations = []
  @observable annotationsPresets = []
  @observable imageId
  @observable imagePath
  @observable loaded = false
}

class DataStore {
  @observable imagesData = ImageData = [];
  @observable currentImageIndex = 0;
  @observable isLoadingImage = true;

  @computed get currentImageData () {
    return this.imagesData[this.currentImageIndex];
  }

  @action reset = () => {
    this.imagesData = ImageData = [];
    this.currentImageIndex = 0;
    this.isLoadingImage = true;
  }

  @action setImagesData = data => { this.imagesData = data; }

  @action addImageData = imageData => { this.imagesData.push(imageData); }

  @action setImageDataElement = (imageIndex, value) => { this.imagesData[imageIndex] = value; }

  @action setImageDataElementAttribute = (imageIndex, attribute, value) => { this.imagesData[imageIndex][attribute] = value; }

  @action setCurrentImageIndex = currentImageIndex => {
    this.currentImageIndex = currentImageIndex;
    this.isLoadingImage = true;
  }

  @action setIsLoadingImage = isLoadingImage => { this.isLoadingImage = isLoadingImage; }
}

const dataStore = new DataStore();

export default dataStore;
export { DataStore, ImageData };
