import React, { Component } from 'react';
import appPropStore from './../models/app-properties-store';
import store from './../models/annotations-store';

class RadioButtonGroup extends Component {
  toggleCheckboxChange (e, keyName) {
    if (!appPropStore.isEditEnabled) return;

    if (e.target.checked) {
      if (this.props.uniqueSelection) this.cleanSelections();
      if (!this.allowCreation(keyName)) return;
      this.deleteRestrictedAnnotations(keyName);
      this.props.addAnnotationCallback(keyName);
    } else {
      if (!this.allowDeletion(keyName)) return;
      this.props.removeAnnotationCallback(keyName);
    }
    this.forceUpdate();
  }

  allowCreation (keyName) {
    return this.props.allowWholeImageAnnotationCreation ?
      this.props.allowWholeImageAnnotationCreation(keyName, store.cleanAnnotations, this.props.addAnnotationCallback, this.props.removeAnnotationCallback) :
      true;
  }
  allowDeletion (keyName) {
    return this.props.allowWholeImageAnnotationDeletion ?
      this.props.allowWholeImageAnnotationDeletion(keyName, store.cleanAnnotations, this.props.addAnnotationCallback, this.props.removeAnnotationCallback) :
      true;
  }

  deleteRestrictedAnnotations (newAnnotationKeyName) {
    let categories = this.props.categories.filter(c => c.keyName === newAnnotationKeyName);
    if (categories.length > 0 && categories[0].disable && categories[0].disable.length > 0) {
      for (const key of categories[0].disable) {this.props.removeAnnotationCallback(key);}
    }
  }

  cleanSelections () {
    for (let i = 0; i < this.props.categories.length; i++) {
      if (this.props.annotations.filter(e => e.annotation.annotationType === this.props.categories[i].keyName).length > 0) {
        this.props.removeAnnotationCallback(this.props.categories[i].keyName);
      }
    }
  }

  render () {
    return (
      <div>
        {this.props.category && <h4 style={{ fontWeight: "bold" }} >{this.props.category.replace("_", " ")}</h4>}
        {this.props.categories.map((at) => (
          <div className="checkbox" key={at.keyName}>
            <label>
              <input
                type="checkbox"
                value={at.keyName}
                checked={this.props.annotations.filter(e => e.annotation.annotationType === at.keyName).length > 0}
                onChange={(e) => this.toggleCheckboxChange(e, at.keyName)}
                disabled={this.props.disabledCategories.filter(key => key === at.keyName).length > 0}
              />
              {at.friendlyName}
            </label>
          </div>)
        )}
      </div>
    );
  }
}

export default RadioButtonGroup;
