import React, {} from 'react';
import { Rect } from 'react-konva';
// /import PropTypes from 'prop-types';
import appPropStore from './../../../models/app-properties-store';

class SelectionRectangle extends React.Component {
  render () {
    return (
      <Rect
        x={this.props.internalAttributes.x}
        y={this.props.internalAttributes.y}
        width={this.props.internalAttributes.width}
        height={this.props.internalAttributes.height}
        fill={"white"}
        stroke= {"black"}
        strokeWidth={1 / (appPropStore.scale * 0.5)}
        opacity={0.3}
        perfectDrawEnabled={false}
        dash={[10 / (appPropStore.scale * 0.5), 5 / (appPropStore.scale * 0.5)]}
        shadowForStrokeEnabled={false}
      />
    );
  }
}

SelectionRectangle.propTypes = {
};

export default SelectionRectangle;
