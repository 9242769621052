import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import store from './../../models/annotations-store';
import RadioButtonGroup from './../RadioButtonGroup';
import './index.css';
import Popup from 'react-popup';
import '../prompts/NewWholeImageAnnotationCategoryPrompt';

@observer
class WholeImageAnnotations extends Component {
  static propTypes = {
    categories: PropTypes.array,
    allowNewImageCategories: PropTypes.bool,
    addAnnotationCallback: PropTypes.func,
    removeAnnotationCallback: PropTypes.func,
    addNewAnnotationCategory: PropTypes.func
  }

  groupBy (list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  addCategory (categoryName) {
    let newCategory = {
      friendlyName: categoryName,
      keyName: categoryName.replace(/ /g, "_")
    };
    this.props.addNewAnnotationCategory(newCategory);
  }

  render () {
    let grouped = this.groupBy(this.props.categories, category => category.group);
    let selectedWholeImageAnnotations = store.annotations.filter(a => a.annotation.shape === "IMAGECATEGORY");
    let selectedWholeImageAnnotationsTypes = Array.from(this.groupBy(selectedWholeImageAnnotations, annotation => annotation.annotation.annotationType).keys());
    let disabledCategories = [];
    for (var key of selectedWholeImageAnnotationsTypes) {
      let categories = this.props.categories.filter(c => c.keyName === key);
      if (categories.length > 0 && categories[0].disable && categories[0].disable.length > 0) {
        disabledCategories = disabledCategories.concat(categories[0].disable);
      }
    }

    let radioButtonGroups = [];
    grouped.forEach((groupCategories, category) =>
      radioButtonGroups.push(
        <div className="col-sm-12" key={category ? category : "none"} >
          <RadioButtonGroup
            category ={category}
            categories={groupCategories} annotations={store.annotations}
            uniqueSelection={category !== undefined}
            addAnnotationCallback={this.props.addAnnotationCallback}
            removeAnnotationCallback={this.props.removeAnnotationCallback}
            disabledCategories={disabledCategories}

            allowWholeImageAnnotationCreation={this.props.allowWholeImageAnnotationCreation}
            allowWholeImageAnnotationDeletion={this.props.allowWholeImageAnnotationDeletion}
          />
        </div>
      )
    );

    return (
      <div className="wholeImageAnnotationsDiv well" id={"wholeImageAnnotationsDiv"}>
        <h1 className={"wholeImageAnnotationsTitle"} />
        <div className="row" >
          { radioButtonGroups }
        </div>
        {this.props.allowNewImageCategories &&
          <button className="btn btn-primary btn-sm wholeImageAnnotationsAddButton"
            onClick={() => Popup.plugins().prompt('', 'Category name', this.addCategory.bind(this))}
          >&#x2b; Add Category</button>
        }
      </div>
    );
  }
}


WholeImageAnnotations.propTypes = WholeImageAnnotations.propTypes;

export default WholeImageAnnotations;
