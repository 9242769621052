import React from 'react';
import PatientAndEyeInfoHeader from './PatientAndEyeInfoHeader';

export const headerTypes = ['PatientAndEyeInfo'];

const ImageHeader = (props) => {
  if (!props.headerType) return null;

  switch (props.headerType) {
  case 'PatientAndEyeInfo':
    return <PatientAndEyeInfoHeader {...props} />;
  default:
    throw new Error(`Invalid header type, expected one of [${headerTypes}]`);
  }
};
export default ImageHeader;
