import React, { Fragment } from 'react';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { BsX } from 'react-icons/bs';

import imageToolsStore from '../../../models/image-tools-store';

const CustomSlider = withStyles({
  root: {
    color: '#179c7d'
  }
})(Slider);

const CustomCheckbox = withStyles({
  root: {
    'color': '#179c7d',
    '&$checked': {
      color: '#179c7d'
    },
    'checked': {}
  }
})(props => <Checkbox color="default" {...props} />);

const ValueLabelComponent = ({ children, open, value }) =>
  (<Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
    {children}
  </Tooltip>);


const Label = ({ property, onResetClick }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center'
  }}>
    <Typography
      style={{ flexGrow: '1' }}
      key={`${property}_tool_name`}
      id={`${property}_slider`}
      gutterBottom
    >
      {property}
    </Typography>
    <IconButton color="inherit" title={`Reset ${property}`} onClick={onResetClick} >
      <BsX />
    </IconButton>
  </div>
);

const ContrastSlider = observer(({ property = 'Contrast' }) => (
  <Fragment>
    <Label property={'Contrast'} onResetClick={() => imageToolsStore.setContrastValue(0)} />
    <CustomSlider
      key={`${property}_tool_slider`}
      ValueLabelComponent={ValueLabelComponent}
      aria-labelledby={`${property}_slider`}
      value={imageToolsStore.contrastValue} onChange={(_, val) => imageToolsStore.setContrastValue(val)}
      min={-100}
      max={255}
      marks={[{ value: 0, label: '0' }]}
    />
  </Fragment>
));

const BrightenSlider = observer(({ property = 'Brightness' }) => (
  <Fragment>
    <Label property={property} onResetClick={() => imageToolsStore.setBrightnessValue(0)}/>
    <CustomSlider
      color="secondary"
      key={`${property}_tool_slider`}
      ValueLabelComponent={ValueLabelComponent}
      aria-labelledby={`${property}_slider`}
      value={imageToolsStore.brightnessValue} onChange={(_, val) => imageToolsStore.setBrightnessValue(val)}
      min={-1}
      max={1}
      step={0.01}
      marks={[{ value: 0, label: '0' }]}
    />
  </Fragment>
));

const GrayscaleToggle = observer(({ property = 'Grayscale' }) => (
  <Fragment>
    <FormControlLabel
      control={
        <CustomCheckbox
          checked={imageToolsStore.grayscaleValue}
          onChange={() => imageToolsStore.toggleGrayscale()}
        />
      }
      label={property}
    />
  </Fragment>
));


export default {
  getToolSlider (property) {
    switch (property) {
    case 'Contrast':
      return <ContrastSlider key={`${property}_tool_name`}/>;
    case 'Brighten':
      return <BrightenSlider key={`${property}_tool_name`}/>;
    case 'Grayscale':
      return <GrayscaleToggle key={`${property}_tool_name`}/>;
    default:
      console.error(`Invalid image property '${ property }'`);
    }
  }

};
