import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Circle, Group } from 'react-konva';
import { getNaturalCoords, getPointerPositionThoughAnnotator } from '../../../utils/Utils';

import appPropStore from './../../../models/app-properties-store';
import store from './../../../models/annotations-store';

@observer
class Anchors extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isDraggingAnchor: false
    };

    this.anchorRef = React.createRef();
  }

  onAnchorDragMove (e) {
    var annotation = this.props.annotation;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    // Natural Radius
    let naturalMousePos = getNaturalCoords({ x: pointerPosition.x, y: pointerPosition.y }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let xNaturalCenter = naturalMousePos.x - annotation.annotation.naturalAttributes.x;
    let yNaturalCenter = naturalMousePos.y - annotation.annotation.naturalAttributes.y;
    let naturalRadius = Math.round(Math.sqrt(xNaturalCenter * xNaturalCenter + yNaturalCenter * yNaturalCenter) * 100) / 100;

    annotation.setNaturalAttributes({ ...annotation.annotation.naturalAttributes, naturalRadius: naturalRadius });
  }

  dragBoundFunc = (pos) => {
    const { x, y } = this.anchorRef.current.absolutePosition()
    return { x, y };
  }

  render () {
    const { isDraggingAnchor } = this.state;

    return (
      <Fragment>
        {/*Render the anchors */}
        {!appPropStore.isShiftDown &&
        <Group
          onMouseOver={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'pointer')}
          onMouseOut={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'default')}
          onDragStart={() => { this.setState({isDraggingAnchor: true}) }}
          onDragMove={this.onAnchorDragMove.bind(this)}
          onDragEnd={() => {
            this.setState({isDraggingAnchor: false});
            this.props.notifyChangesCallback('UPDATE', this.props.annotation.annotation, store.cleanAnnotations);
            this.forceUpdate();
          }}
          onMouseDown={() => {this.setState({ isDraggingAnchor: true });}}
          onMouseUp={() => {this.setState({ isDraggingAnchor: false });}}
          onTouchStart={() => {this.setState({ isDraggingAnchor: true });}}
          onTouchEnd={() => {this.setState({ isDraggingAnchor: false });}}
        >
          <Circle
            ref={this.anchorRef}
            x={this.props.annotation.internalAttributes.x} y={this.props.annotation.internalAttributes.y} radius={this.props.annotation.internalAttributes.radius}
            fillEnabled={false} fill="gray" stroke="black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false} strokeWidth={6 / (appPropStore.scale * 0.5)} shadowForStrokeEnabled={false}
            id={"circle-anchor"} key={"circle-anchor"}
            draggable onDragEnd={() => {}} dragBoundFunc={this.dragBoundFunc}
          />
        </Group>
        }
      </Fragment>
    );
  }
}

Anchors.propTypes = {
};

export default Anchors;
