import { observable, action, configure } from 'mobx';

configure({ enforceActions: 'observed' }); // don't allow state modifications outside actions

class ImageToolsStore {
  @observable imageToolsEnabled = false;

  @observable availableImageTools = ['Contrast', 'Brighten', 'Grayscale']

  @observable contrastValue = 0;
  @observable brightnessValue = 0;
  @observable grayscaleValue = false;

  @action setImageToolsEnabled = (imageToolsEnabled) => {
    this.imageToolsEnabled = imageToolsEnabled;
  }

  @action reset = () => {
    this.availableImageTools = ['Contrast', 'Brighten', 'Grayscale'];
    this.contrastValue = 0;
    this.brightnessValue = 0;
    this.grayscaleValue = false;
  }

  @action setContrastValue = (contrastValue) => {
    this.contrastValue = contrastValue;
  }

  @action setBrightnessValue = (brightnessValue) => {
    this.brightnessValue = brightnessValue;
  }

  @action toggleGrayscale = () => {
    this.grayscaleValue = !this.grayscaleValue;
  }
}

const store = new ImageToolsStore();

export default store;
export { ImageToolsStore };
