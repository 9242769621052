import Keycloak from "keycloak-js";
import Config from "./keycloak.json";

// console.log("Keycloak configuration >>> ", Config);

window.keycloak = Keycloak(Config);
keycloak.init({
  onLoad: 'login-required',
  checkLoginIframe: false
}).success(function (authenticated) { // onLoad: 'check-sso' to not require login
  // console.log(authenticated ? 'authenticated' : 'not authenticated');
}).error(function () {
  alert('failed to authenticate');
});

keycloak.onTokenExpired = function () {
  keycloak.updateToken(30).success(function (refreshed) {
    if (refreshed) {
      //console.log('Token was successfully refreshed');
    } else {
      //console.log('Token is still valid');
    }
  }).error(function () {
    //console.log('Failed to refresh the token, or the session has expired');
    keycloak.clearToken();
  });
};
