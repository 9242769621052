import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Rect, Group } from 'react-konva';
import update from 'immutability-helper';
import { getNaturalCoords, getPointerPositionThoughAnnotator } from '../../../utils/Utils';
import { DrawAnnotation } from './DrawAnnotation';
import Popup from 'react-popup';
import '../../prompts/AnnotationPrompt';

import store from './../../../models/annotations-store';
import appPropStore from './../../../models/app-properties-store';

class DrawAnnotator extends Component {
  constructor () {
    super();
    this.state = {
      coordDown: null,
      points: [],
      tempShape: null
    };
    this._onMouseDown = this._onMouseDown.bind(this);
    this._onMouseUp = this._onMouseUp.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onTouchStart = this._onTouchStart.bind(this);
    this._onTouchEnd = this._onTouchEnd.bind(this);
    this._onTouchMove = this._onTouchMove.bind(this);

    // Values to aid the calculation of the popup position
    this.mostLeft = this.mostRight = this.mostTop = this.mostBottom = null;
  }

  restartDrawing () {
    this.mostTop = this.mostLeft = this.mostRight = this.mostBottom = null;
    this.setState({
      coordDown: null,
      points: [],
      tempShape: null
    });
  }

  //Mouse Inputs
  _onMouseDown (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.button !== 0) { return; }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);

    if (this.state.coordDown == null) {
      this.setState({
        coordDown: { x: pointerPosition.x, y: pointerPosition.y },
        points: update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] })
      });
    } else {
      let newPoints = update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] });
      let newtempShape = {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          points: newPoints
        },
        color: "white"
      };
      this.setState({
        points: newPoints,
        tempShape: newtempShape
      });
    }
  }

  _onMouseUp (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.button !== 0 || this.state.tempShape == null || this.state.coordDown === null || this.state.points.length < 3 * 2) { this.restartDrawing(); return; }
    //calculate the points
    var internalPoints = [];
    let naturalPoints = [];
    for (let i = 0; i < this.state.points.length; i++) {
      if (i % 2) { //y
        let y = this.state.points[i];
        internalPoints.push(y);
        naturalPoints.push(getNaturalCoords({ y: y }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions).y);
      } else { //x
        let x = this.state.points[i];
        internalPoints.push(x);
        naturalPoints.push(getNaturalCoords({ x: x }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions).x);
      }
    }

    //Send to parent
    let draw = {
      annotation: {
        naturalAttributes: {
          points: naturalPoints
        }
      }
    };

    let popupPosition = this.calculatePopupPosition();
    // Open the popup
    Popup.plugins().promptForAdditionalInfo(popupPosition, this.props.annotationTypes,
      ((notes, annotationType, isUpdate) => this.props.addAnnotation('Draw', draw, notes, annotationType, isUpdate)), (() => this.restartDrawing()));
  }

  _onMouseMove (e) {
    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);

    if (appPropStore.isDrawingKeyDown && this.state.coordDown != null) {
      let newPoints = update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] });
      let newtempShape = {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          points: newPoints
        },
        color: "transparent"
      };
      this.setState({
        tempShape: newtempShape,
        points: newPoints
      });
    }

    if (appPropStore.isDrawingKeyDown && e.evt.buttons === 1) { // if still drawing
      // Update positions for the calculation of the popup
      if (!this.mostTop || this.mostTop > e.evt.clientY) this.mostTop = e.evt.clientY;
      if (!this.mostBottom || this.mostBottom < e.evt.clientY) this.mostBottom = e.evt.clientY;
      if (!this.mostLeft || this.mostLeft > e.evt.clientX) this.mostLeft = e.evt.clientX;
      if (!this.mostRight || this.mostRight < e.evt.clientX) this.mostRight = e.evt.clientX;
      return;
    }
  }

  calculatePopupPosition () {
    let position = {
      top: this.mostTop,
      left: this.mostRight + 10
    };
    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (this.mostTop + promptSize > window.innerHeight) {
      position.top = Math.max(0, this.mostBottom - promptSize);
    }
    if (this.mostRight + 360 > window.innerWidth) {
      if (this.mostLeft - 360 > 0) {
        position.left = this.mostLeft - 360;
      } else {
        position.left = ((this.mostRight + this.mostLeft) / 2) - 360 / 2;
        if (position.top === this.mostTop) {
          if (this.mostBottom + promptSize < window.innerHeight) { position.top = this.mostBottom + 10;} else {position.top = (this.mostBottom + this.mostTop) / 2 - 360 / 2; }
        }
      }
    }
    return position;
  }

  //Touch Inputs
  _onTouchStart (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.touches.length !== 1) { return; }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);

    if (this.state.coordDown == null) {
      this.setState({
        coordDown: { x: pointerPosition.x, y: pointerPosition.y },
        points: update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] })
      });
    } else {
      let newPoints = update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] });
      let newtempShape = {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          points: newPoints
        },
        color: "white"
      };
      this.setState({
        points: newPoints,
        tempShape: newtempShape
      });
    }
  }

  _onTouchEnd (e) {
    if (!appPropStore.isDrawingKeyDown || this.state.tempShape == null || this.state.coordDown === null || this.state.points.length < 3 * 2) { this.restartDrawing(); return; }
    //calculate the points
    var internalPoints = [];
    let naturalPoints = [];
    for (let i = 0; i < this.state.points.length; i++) {
      if (i % 2) { //y
        let y = this.state.points[i];
        internalPoints.push(y);
        naturalPoints.push(getNaturalCoords({ y: y }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions).y);
      } else { //x
        let x = this.state.points[i];
        internalPoints.push(x);
        naturalPoints.push(getNaturalCoords({ x: x }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions).x);
      }
    }

    //Send to parent
    let draw = {
      annotation: {
        naturalAttributes: {
          points: naturalPoints
        }
      }
    }

    let popupPosition = this.calculatePopupPosition();
    // Open the popup
    Popup.plugins().promptForAdditionalInfo(popupPosition, this.props.annotationTypes,
      ((notes, annotationType, isUpdate) => this.props.addAnnotation('Draw', draw, notes, annotationType, isUpdate)), (() => this.restartDrawing()));
  }

  _onTouchMove (e) {
    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);

    if (appPropStore.isDrawingKeyDown && e.evt.touches.length == 1 && this.state.coordDown != null) {
      let newPoints = update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] });
      let newtempShape = {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          points: newPoints
        },
        color: "transparent"
      };
      this.setState({
        tempShape: newtempShape,
        points: newPoints
      });
    }

    if (appPropStore.isDrawingKeyDown) { // if still drawing
      // Update positions for the calculation of the popup
      if (!this.mostTop || this.mostTop > e.evt.clientY) this.mostTop = e.evt.clientY;
      if (!this.mostBottom || this.mostBottom < e.evt.clientY) this.mostBottom = e.evt.clientY;
      if (!this.mostLeft || this.mostLeft > e.evt.clientX) this.mostLeft = e.evt.clientX;
      if (!this.mostRight || this.mostRight < e.evt.clientX) this.mostRight = e.evt.clientX;
      return;
    }
  }

  render () {
    return (
      <Group>
        {/*Temp shape*/}
        {this.state.tempShape != null &&
          <DrawAnnotation
            {...this.state.tempShape}
            color={"white"}
            closed
          />
        }
        {/*Renders a canvas for drawing (listening to mouse inputs)*/}
        <Rect
          {...appPropStore.userImageDimensions}
          onMouseMove={this._onMouseMove} onMouseDown={this._onMouseDown} onMouseUp={this._onMouseUp}
          onTouchMove={this._onTouchMove} onTouchStart={this._onTouchStart} onTouchEnd={this._onTouchEnd}
        />
      </Group>
    );
  }
}

DrawAnnotator.propTypes = {
  addAnnotation: PropTypes.func.isRequired //Callback to return the annotations
};

function calculateDrawViewDimensions (draw, viewDimensions, naturalImageDimensions) {
  let internalAttributes = {
    points: getViewListPointsCoodsFromNaturalCoords(draw.annotation.naturalAttributes.points, viewDimensions, naturalImageDimensions)
  };

  return internalAttributes;
}

function calculateDrawListViewDimensions (list, viewDimensions, naturalImageDimensions) {
  list.map(listValue => (
    listValue = calculateDrawViewDimensions(listValue, viewDimensions, naturalImageDimensions)
  ));
  return list;
}

function getViewListPointsCoodsFromNaturalCoords (points, viewDimensions, naturalImageDimensions) {
  let newPoints = [];
  for (let i = 0; i < points.length; i++) {
    if (i % 2) newPoints.push((points[i] * viewDimensions.width / naturalImageDimensions.width));
    else newPoints.push((points[i] * viewDimensions.height / naturalImageDimensions.height));
  }
  return newPoints;
}

export { DrawAnnotator, calculateDrawViewDimensions, calculateDrawListViewDimensions };
