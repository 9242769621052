import { observable, action, computed, configure } from 'mobx';

configure({ enforceActions: 'observed' }); // don't allow state modifications outside actions

class AppPropertiesStore {
  // Values marked as 'observable' can be watched by 'observers'
  naturalImageDimensions = {
    width: -1,
    height: -1
  };
  @observable realUserImageDimensions = {
    width: -1,
    height: -1
  };
  @observable annotatorBoundingRect = {
    width: -1,
    height: -1,
    left: -1,
    right: -1,
    top: -1,
    bottom: -1
  };
  @observable scale = 1;
  @observable offset = { x: 0, y: 0 };

  @observable isDrawingEnabled = true;
  @observable wizardMode = false;
  @observable isEditEnabled = true;
  @observable allowNewCategories = true;
  @observable dontUseExifOrientation = false;
  @observable preloadedImage = '';
  @observable allowImageChange = true;
  @observable enabledNextImage = true;

  @observable drawAnnotations = true;

  dotSize = 10;
  annotatorRef = null;

  @observable isShiftDown = false;
  @observable isDrawingKeyDown = false;
  @observable activeTool = 'RectangleAnnotator';

  @computed get userImageDimensions () {
    // Check if the image is "horizontal"
    if (this.realUserImageDimensions.width > this.realUserImageDimensions.height) {
      let width = this.annotatorBoundingRect.width;
      let height = this.realUserImageDimensions.height * width / this.realUserImageDimensions.width;

      // So the image wont "cut" on its height
      if (this.annotatorBoundingRect.height < height && this.realUserImageDimensions.width > this.realUserImageDimensions.height) {
        height = this.annotatorBoundingRect.height;
        width = this.realUserImageDimensions.width * height / this.realUserImageDimensions.height;
      }

      return {
        width: width,
        height: height,
        currentViewWidth: width / this.scale,
        currentViewHeight: this.realUserImageDimensions.height / this.scale
      };
    } else {
      let height = this.annotatorBoundingRect.height;
      let width = this.realUserImageDimensions.width * height / this.realUserImageDimensions.height;

      // So the image wont "cut" on its width
      if (width > this.annotatorBoundingRect.width) {
        width = this.annotatorBoundingRect.width;
        height = this.realUserImageDimensions.height * width / this.realUserImageDimensions.width;
      }

      return {
        width: width,
        height: height,
        currentViewWidth: width / this.scale,
        currentViewHeight: (height / this.scale) / this.scale
      };
    }
  }

  @computed get stageDimensions () {
    return {
      width: Math.min(this.annotatorBoundingRect.width, this.userImageDimensions.width * this.scale),
      height: Math.min(this.annotatorBoundingRect.height, this.userImageDimensions.height * this.scale)
    };
  }

  @action reset = () => {
    this.naturalImageDimensions = { width: -1, height: -1 };
    this.realUserImageDimensions = { width: -1, height: -1 };
    this.annotatorBoundingRect = { width: -1, height: -1, left: -1, right: -1, top: -1, bottom: -1 };
    this.scale = 1;
    this.offset = { x: 0, y: 0 };
    this.isDrawingEnabled = true;
    this.wizardMode = false;
    this.isEditEnabled = true;
    this.allowNewCategories = true;
    this.dontUseExifOrientation = false;
    this.preloadedImage = '';
    this.allowImageChange = true;
    this.enabledNextImage = true;
    this.drawAnnotations = true;
    this.dotSize = 10;
    this.annotatorRef = null;
    this.isShiftDown = false;
    this.isDrawingKeyDown = false;
    this.activeTool = 'RectangleAnnotator';
  }

  @action setNaturalImageDimensions = (naturalImageDimensions) => {
    this.naturalImageDimensions = naturalImageDimensions;
  }

  @action setUserImageDimensions = (userImageDimensions) => {
    this.realUserImageDimensions = userImageDimensions;
  }

  @action setAnnotatorBoundingRect = (annotatorBoundingRect) => {
    this.annotatorBoundingRect = annotatorBoundingRect;
  }

  @action setScale = (scale) => {
    this.scale = scale;
  }

  @action setOffset = (offset) => {
    this.offset = offset;
  }

  @action setDrawingEnabled = (isDrawingEnabled) => {
    this.isDrawingEnabled = isDrawingEnabled;
  }

  @action setWizardMode = (wizardMode) => {
    this.wizardMode = wizardMode;
  }

  @action setEditEnabled = (isEditEnabled) => {
    this.isEditEnabled = isEditEnabled;
  }

  @action setAllowNewCategories = (allowNewCategories) => {
    this.allowNewCategories = allowNewCategories;
  }

  @action setDrawAnnotations = (drawAnnotations) => {
    this.drawAnnotations = drawAnnotations;
  }

  @action setIsShiftDown = (isShiftDown) => {
    this.isShiftDown = isShiftDown;
  }
  @action setIsDrawingKeyDown = (isDrawingKeyDown) => {
    this.isDrawingKeyDown = isDrawingKeyDown;
  }

  @action setActiveTool = (activeTool) => {
    this.activeTool = activeTool;
  }

  @action setdontUseExifOrientation = (dontUseExifOrientation) => {
    this.dontUseExifOrientation = dontUseExifOrientation;
  }

  @action setPreloadedImage = (preloadedImage) => {
    this.preloadedImage = preloadedImage;
  }

  @action setAllowImageChange = (allowImageChange) => {
    this.allowImageChange = allowImageChange;
  }

  @action setEnabledNextImage = (enabledNextImage) => {
    this.enabledNextImage = enabledNextImage;
  }

  setDotSize = (dotSize) => {
    this.dotSize = dotSize;
  }
  setAnnotatorRef = (annotatorRef) => {
    this.annotatorRef = annotatorRef;
  }
}

const store = new AppPropertiesStore();

export default store;
export { AppPropertiesStore };
