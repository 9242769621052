import React, { Component, Fragment } from 'react'
import { Typography, Paper, Container, Button, FormControl, InputLabel, Select, MenuItem, IconButton, LinearProgress, Tooltip } from '@material-ui/core'
import { CloseRounded as CloseIcon } from '@material-ui/icons'
import Loading from '../components/Loading'
import { getUser } from '../requests/users'
import { updateAcquisition } from '../requests/acquisitions'

class User extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            user: undefined,
            datasets: [],
            all_Acquisitions: [],
            user_Acquisitions: []
        }
        this.updateUserAcquisitions = this.updateUserAcquisitions.bind(this)
    }

    async componentDidMount () {
        const userId = this.props.match.params.id
        const { progress } = this.props.location.state

        await getUser(userId)
        .then(data => {
            this.setState({
                user: data.user,
                all_Acquisitions: data.acquisitions || [],
                user_Acquisitions: data.user_Acquisitions || [],
                datasets: data.datasets,
                progress: progress,
                loading: false,
                addingAcquisition: false
            })
        })
    }


    toggleAcquisitionForm() {
        this.setState({addingAcquisition: !this.state.addingAcquisition})
    }

    updateUserAcquisitions (acq) {
        this.setState({
            user_Acquisitions: [...this.state.user_Acquisitions, acq]
        })
    }

    getAcquisitionName(uAcq, datasets) {
        let dataset = datasets.find(el => el.id == uAcq.datasetId)
        if (dataset != null && uAcq['metadata']['sampleId'] != null) {
            return (uAcq['metadata']['sampleId'] + " - " + dataset.name)
        } else if (uAcq['metadata']['sampleId'] != null) {
            return uAcq['metadata']['sampleId']
        } else {
            return uAcq.id
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        } else {
            return (
                <React.Fragment>
                    <Container>
                        <Paper style={{marginTop: '3rem', padding: '2rem 3rem'}}>
                            {(this.state.user['firstName'] !== '' && this.state.user['lastName'] !== '') ? (
                                <Typography variant='h4' style={{marginBottom: '0.5rem'}}>{this.state.user['username']}</Typography>
                            ) : (
                                <Typography variant='h4' style={{marginBottom: '0.5rem'}}>{(this.state.user['firstName'] || '') + ' ' + (this.state.user['lastName'] || '')}</Typography>
                            )}
                            <Typography variant='caption'>{this.state.user['id']}</Typography>
                            <Typography variant='body1'>{this.state.user['email']}</Typography>
                        </Paper>
                        <Paper style={{marginTop: '2rem', padding: '2rem 3rem'}}>
                            <Typography variant='h5'>Acquisitions assigned to this user :</Typography>
                            <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
                                {this.state.user_Acquisitions.map((uAcq, ind) => (
                                    <div key={uAcq.id} style={{display: 'flex', flexDirection: 'row', margin: '1.5rem, auto', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Typography style={{width: '10%'}}>
                                            {this.getAcquisitionName(uAcq, this.state.datasets)}
                                        </Typography>
                                        {this.state.progress && this.state.progress.acquisitions.find(acq => acq.id === uAcq.id) && (
                                            <div style={{display: 'flex', width: '70%', padding: 'auto 5%'}}>
                                                <Tooltip
                                                    title={(100 * Math.min(this.state.progress.acquisitions.find(acq => acq.id === uAcq.id).annotatedSamples/this.state.progress.acquisitions.find(acq => acq.id === uAcq.id).samples, 1)).toFixed(0) + ' %'}
                                                    aria-label={(100 * Math.min(this.state.progress.acquisitions.find(acq => acq.id === uAcq.id).annotatedSamples/this.state.progress.acquisitions.find(acq => acq.id === uAcq.id).samples, 1)).toFixed(0) + ' %'}
                                                >
                                                    <LinearProgress
                                                        variant='determinate'
                                                        value={(100 * Math.min(this.state.progress.acquisitions.find(acq => acq.id === uAcq.id).annotatedSamples/this.state.progress.acquisitions.find(acq => acq.id === uAcq.id).samples, 1))}
                                                        style={{width: '100%', height: '12px', borderRadius: '7px'}}
                                                    />
                                                </Tooltip>
                                            </div>
                                        )}
                                        <IconButton onClick={() => {
                                            if (window.confirm('Are you sure you wish to delete this item?'))
                                                updateAcquisition(uAcq.id, {ownerId: null}).then(() => {
                                                    const newUserAcqs = this.state.user_Acquisitions
                                                    newUserAcqs.splice(ind, 1)
                                                    this.setState({
                                                        all_Acquisitions: [...this.state.all_Acquisitions, uAcq],
                                                        user_Acquisitions: newUserAcqs
                                                    })
                                                })
                                        }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                ))}
                            </div>
                            {this.state.addingAcquisition ? (
                                <AcqAssign userId={this.state.user['id']} datasets={this.state.datasets}
                                    notAssignedAcquisitions={this.state.all_Acquisitions} //.filter(acq => acq['ownerId'] === null)
                                    assignAcq={this.updateUserAcquisitions}
                                />
                            ) : (
                                <Button className="coloredButton" onClick={() => this.toggleAcquisitionForm()}>
                                    Assign new acquisitions to user
                                </Button>
                            )}
                        </Paper>
                    </Container>
                </React.Fragment>
            )
        }

    }
}

class AcqAssign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            structuredAcquisitions: [],
            loading: true
        }
    }

    static getDerivedStateFromProps(props, state) {
        let structuredAcquisitions = []
        const datasets = ['All', ...new Set(props.notAssignedAcquisitions.map(obj => obj.datasetId).filter(dts => dts !== null))]
        datasets.map(dts => {
            if (dts === 'All')
                structuredAcquisitions.push({dataset: dts, acquisitions: [...props.notAssignedAcquisitions.filter(obj => obj.ownerId === null)]})
            else
                structuredAcquisitions.push({dataset: dts, acquisitions: [...props.notAssignedAcquisitions.filter(obj => obj.datasetId === dts && obj.ownerId === null)]})
        })
        
        return {
            ...state,
            structuredAcquisitions: structuredAcquisitions
        }
    }

    componentDidMount () {
        let structuredAcquisitions = []
        const datasets = ['All', ...new Set(this.props.notAssignedAcquisitions.map(obj => obj.datasetId).filter(dts => dts !== null))]
        datasets.map(dts => {
            if (dts === 'All')
                structuredAcquisitions.push({dataset: dts, acquisitions: [...this.props.notAssignedAcquisitions]}) //.filter(obj => obj.ownerId === null)
            else
                structuredAcquisitions.push({dataset: dts, acquisitions: [...this.props.notAssignedAcquisitions.filter(obj => obj.datasetId === dts)]}) //&& obj.ownerId === null
        })
        
        this.setState({
            structuredAcquisitions: structuredAcquisitions,
            selectedDataset: 'All',
            selectedAcquisition: '',
            loading: false
        })
    }

    render() {
        return (
            <Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <div className="acquisitionForm" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline'}}>
                        <div style={{display: 'flex', flexGrow: 1}}>
                            <FormControl variant='outlined' style={{margin: 'auto 2rem auto 0', minWidth: 150}}>
                                <InputLabel htmlFor="outlined-dataset">
                                    Dataset
                                </InputLabel>
                                <Select
                                    value={this.state.selectedDataset}
                                    onChange={ev => this.setState({selectedDataset: ev.target.value})}
                                    inputProps={{
                                        name: 'Dataset',
                                        id: 'outlined-dataset',
                                    }}
                                >
                                    {this.state.structuredAcquisitions.map(entry =>
                                        <MenuItem key={entry.dataset} value={entry.dataset}>
                                            {entry.dataset === 'All' ? (
                                                entry.dataset
                                            ) : (
                                                this.props.datasets.find(dts => dts.id === entry.dataset)['name']
                                            )}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>

                            <FormControl margin='normal' variant='outlined' style={{minWidth: 150}}>
                                <InputLabel htmlFor="outlined-acquisition">
                                    Acquisition
                                </InputLabel>
                                <Select
                                    value={this.state.selectedAcquisition}
                                    onChange={ev => this.setState({
                                        selectedAcquisition: ev.target.value
                                    })}
                                    inputProps={{
                                        name: 'Acquisition',
                                        id: 'outlined-acquisition',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {this.state.structuredAcquisitions
                                    .find(obj => obj.dataset === this.state.selectedDataset)
                                    .acquisitions
                                    .filter(acq => acq.ownerId === null)
                                    .map(acqEntry => (
                                        <MenuItem key={acqEntry.id} value={acqEntry.id}>
                                            {acqEntry['metadata']['sampleId']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <Button className='formBtn'
                            disabled={!this.state.selectedAcquisition}
                            onClick={() => {
                                updateAcquisition(this.state.selectedAcquisition, {ownerId: this.props.userId})
                                .then(() => {
                                    this.props.assignAcq(
                                        this.state.structuredAcquisitions
                                        .find(obj => obj.dataset === this.state.selectedDataset)
                                        .acquisitions
                                        .find(acq => acq.id === this.state.selectedAcquisition)
                                    )
                                })
                            }}
                        >
                            <Typography>
                                Assign the selected acquisition to this user
                            </Typography>
                        </Button>
                    </div>
                )}
            </Fragment>
        )
    }
}

export default User
