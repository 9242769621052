import React, { Component, Fragment } from 'react'
import { Typography, Button, Container, Paper, Checkbox, FormControlLabel } from '@material-ui/core'
import ListLayoutContainer from './ListLayoutContainer'
import GridLayoutContainer from './GridLayoutContainer'
import AddNewContainer from './AddNewContainer'
import { addNewDataset } from '../requests/datasets'


class ListingPage extends Component {
    constructor (props) {
        super(props)
        this.state = {
            addNewActive: false,
            editActive: false,
            ...this.props,
            resultsType: this.props.resultsType || '',
            results: this.props.results || []
        }
        this.toggleAddNewBtn = this.toggleAddNewBtn.bind(this)
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({...nextProps})
    }

    componentWillUnmount () {
        this.setState({addNewActive: false, resultsType: '', results: []})
    }

    toggleAddNewBtn () {
        this.setState({ addNewActive: !this.state.addNewActive })
    }

    toggleEditBtn () {
        this.setState({ editActive: !this.state.editActive })
    }

    render () {
        return (
            <Container style={{paddingTop: '2rem'}}>
                <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'space-between', paddingBottom: '1.5rem' }}>
                    <Typography variant="h5">
                        {this.props.resultsType} {this.props.counter !== undefined && `x${this.props.counter}`}
                    </Typography>
                    {keycloak.hasRealmRole('admin') && this.props.resultsType !== 'Users' && (
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {(this.props.resultsType === 'Acquisitions' && !this.state.addNewActive) && (
                                <Button className="coloredButton"
                                    style={{marginRight: '1.5rem'}}
                                    onClick={() => this.props.exportAllAnnotations()}
                                >
                                    <Typography style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>Download Annotations</Typography>
                                </Button>
                            )}
                            <Button className="coloredButton" onClick={() => this.toggleAddNewBtn()}>
                                <Typography style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                                    {this.state.addNewActive ? 'Cancel' : 'Add New'}
                                </Typography>
                            </Button>
                        </div>
                    )}
                </div>
                {this.props.resultsType === 'Acquisitions' && (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '1rem'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!this.props.hideReady}
                                    onChange={this.props.toggleHideReady}
                                    color="primary"
                                />
                            }
                            label="Show completed acquisitions"
                        />
                    </div>
                )}
                {this.state.addNewActive && keycloak.hasRealmRole('admin') && this.props.resultsType !== 'Users' && (
                    <AddNewContainer
                        title={this.props.resultsType}
                        addNew={this.props.addNew}
                        toggleForm={this.toggleAddNewBtn}
                        {...this.props}
                    />
                )}
                {this.state.resultsType === 'Samples' ? (
                    <GridLayoutContainer
                        title={this.props.resultsType}
                        elements={this.state.results}
                        acquisitionId={this.props.additionalData['acqId']}
                        fetchAllImageSamples={this.props.fetchAllImageSamples}
                    />
                ) : (
                    <ListLayoutContainer
                        title={this.props.resultsType}
                        elements={this.state.results}
                        datasets={this.props.datasets || []}
                        imageSamplesDownload={this.props.imageSamplesDownload}
                        exportAnnot={this.props.exportAnnot}
                        deleteElem={this.props.deleteElement}
                        hideReady={this.props.hideReady}
                    />
                )}
            </Container>
        )
    }
}

export default ListingPage