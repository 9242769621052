import React, {} from 'react';
import { Group, Line } from 'react-konva';
// /import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import appPropStore from './../../../models/app-properties-store';

@observer
class DrawAnnotation extends React.Component {
  render () {
    let fillColor = this.props.color || (this.props.annotation && this.props.annotation.color ? this.props.annotation.color : null) || 'white';

    let vertex = {
      xMin: this.props.internalAttributes.points[0], xMax: this.props.internalAttributes.points[0],
      yMin: this.props.internalAttributes.points[1], yMax: this.props.internalAttributes.points[1]
    };
    if (this.props.isSelected) {
      for (let i = 0; i + 1 < this.props.internalAttributes.points.length; i += 2) {
        vertex.xMax = Math.max(vertex.xMax, this.props.internalAttributes.points[i]);
        vertex.xMin = Math.min(vertex.xMin, this.props.internalAttributes.points[i]);

        vertex.yMax = Math.max(vertex.yMax, this.props.internalAttributes.points[i + 1]);
        vertex.yMin = Math.min(vertex.yMin, this.props.internalAttributes.points[i + 1]);
      }
    }

    return (
      <Line
        draggable={this.props.draggable}
        onDragEnd={this.props.onDragEnd}
        points={this.props.internalAttributes.points}
        closed={this.props.internalAttributes.points.length >= 1}
        fill={fillColor}
        opacity={this.props.opacity || 0.3}
        stroke={this.props.stroke || 'black'}
        strokeWidth={1 / (appPropStore.scale * 0.5)}
        id={this.props.id}
        className= "Draw"
        tension = {1}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}

        dashEnabled={this.props.isSelected || false}
        dash={[5 / appPropStore.scale, 5 / appPropStore.scale]}
        fillPriority={this.props.isSelected ? 'linear-gradient' : 'color'}
        fillLinearGradientStartPoint={{ x: vertex.xMin, y: vertex.yMin }}
        fillLinearGradientEndPoint={{ x: vertex.xMax, y: vertex.yMax }}
        fillLinearGradientColorStops={[0.25, 'white', 1, fillColor]}
      />
    );
  }
}

//Render the list of Rects in props
function DrawListRender (props) {
  return (
    <Group>
      {props.list.map(listValue => (
        <DrawAnnotation
          key={`${ listValue.x }${listValue.y}`}
          points={listValue.points}
          closed
          onClick={(e) => props.onClick(e, listValue.id)}
          color={listValue.color}
          id={listValue.id}
        />
      ))}
    </Group>
  );
}

DrawAnnotation.propTypes = {
};

export { DrawAnnotation, DrawListRender };
