import { annotationOptions } from "../constants"

export const serializeAnnotation = function (annotation, annotatedSampleId) {
    if (annotation.shape.toUpperCase() === ("IMAGECATEGORY")) {
        return {
            id: annotation.id,
            annotatedSampleId: annotatedSampleId,
            type: annotation.annotationType.toUpperCase() === "NOTHING"
                ? "NoAnnotation"
                : "WholeImageAnnotation",
            annotationObject: {
                type: "AnnotationText",
                text: annotation.annotationType
            },
            tags: annotation.tags,
            metadata: annotation.metadata
        }
    } else if (annotation.shape.toUpperCase() === "RECT") {
        return {
            id: annotation.id,
            annotatedSampleId: annotatedSampleId,
            type: "RectangleAnnotation",
            annotationObject: {
                type: "AnnotationText",
                text: annotation.annotationType
            },
            color: annotation.color,
            notes: annotation.notes,
            height: annotation.naturalAttributes.height,
            width: annotation.naturalAttributes.width,
            point: {
                x: annotation.naturalAttributes.x,
                y: annotation.naturalAttributes.y
            },
            tags: annotation.tags,
            metadata: annotation.metadata
        }
    } else if (annotation.shape.toUpperCase() == "POLYGON") {
        let points = [];
        for (let i = 0; i + 1 < annotation.naturalAttributes.points.length; i += 2) {
          points.push({ x: annotation.naturalAttributes.points[i], y: annotation.naturalAttributes.points[i + 1] });
        }
        return {
            id: annotation.id,
            annotatedSampleId: annotatedSampleId,
            type: "PolygonAnnotation",
            annotationObject: {
                type: "AnnotationText",
                text: annotation.annotationType
            },
            color: annotation.color,
            notes: annotation.notes,
            height: annotation.naturalAttributes.height,
            width: annotation.naturalAttributes.width,
            points: points,
            tags: annotation.tags,
            metadata: annotation.metadata
        }
    } else if (annotation.shape.toUpperCase() === "POINT") {
        return {
            id: annotation.id,
            annotatedSampleId: annotatedSampleId,
            type: "PointAnnotation",
            annotationObject: { type: "AnnotationText", text: annotation.annotationType },
            color: annotation.color,
            notes: annotation.notes,
            point: annotation.naturalAttributes,
            tags: annotation.tags,
            metadata: annotation.metadata
        }
    } else if (annotation.shape.toUpperCase() === "CIRCLE") {
        return {
            id: annotation.id,
            annotatedSampleId: annotatedSampleId,
            type: "CircleAnnotation",
            annotationObject: { type: "AnnotationText", text: annotation.annotationType },
            color: annotation.color,
            notes: annotation.notes,
            center: { x: annotation.naturalAttributes.x, y: annotation.naturalAttributes.y },
            radius: annotation.naturalAttributes.naturalRadius,
            tags: annotation.tags,
            metadata: annotation.metadata
          }
    } else if (annotation.shape.toUpperCase() === "DRAW") {
        let points = [];
        for (let i = 0; i + 1 < annotation.naturalAttributes.points.length; i += 2) {
            points.push({
                x: annotation.naturalAttributes.points[i],
                y: annotation.naturalAttributes.points[i + 1]
            })
        }
        return {
            id: annotation.id,
            annotatedSampleId: annotatedSampleId,
            type: "DrawAnnotation",
            annotationObject: { type: "AnnotationText", text: annotation.annotationType },
            color: annotation.color,
            notes: annotation.notes,
            points: points,
            tags: annotation.tags,
            metadata: annotation.metadata
        }
    }
}

export const deSerializeAnnotation = function (annotation) {
    if (annotation.type.toUpperCase() === "WHOLEIMAGEANNOTATION" || annotation.type.toUpperCase() === "NOANNOTATION") {
        return {
            type: annotation.type,
            id: annotation.id,
            shape: "IMAGECATEGORY",
            annotationType: annotation.annotationObject.text,
            tags: annotation.tags,
            metadata: annotation.metadata
        }
    } else if (annotation.type.toUpperCase() === "RECTANGLEANNOTATION") {
        return {
            type: annotation.type,
            id: annotation.id,
            shape: "Rect",
            annotationType: annotation.annotationObject.text,
            color: getAnnotationTypeColor(annotation.annotationObject.text),
            notes: annotation.notes,
            naturalAttributes: {
                height: annotation.height,
                width: annotation.width,
                ...annotation.point
            },
            tags: annotation.tags,
            metadata: annotation.metadata
        }
    } else if (annotation.type.toUpperCase() === "CIRCLEANNOTATION") {
        return {
          type: annotation.type,
          id: annotation.id,
          shape: "Circle",
          annotationType: annotation.annotationObject.text,
          color: getAnnotationTypeColor(annotation.annotationObject.text),
          notes: annotation.notes,
          naturalAttributes: {
            naturalRadius: annotation.radius,
            ...annotation.center
          },
          tags: annotation.tags,
          metadata: annotation.metadata
        }
    } else if (annotation.type.toUpperCase() === "POLYGONANNOTATION") {
        let points = annotation.points.map(function (point) { return [point.x, point.y]; });
        const flatPoints = [].concat(...points);
        flatPoints.some(Array.isArray) ? flatten(flatPoints) : flatPoints;
        return {
          type: annotation.type,
          id: annotation.id,
          shape: "Polygon",
          annotationType: annotation.annotationObject.text,
          color: getAnnotationTypeColor(annotation.annotationObject.text),
          notes: annotation.notes,
          naturalAttributes: {
            points: flatPoints
          },
          tags: annotation.tags,
          metadata: annotation.metadata
        }
    } else if (annotation.type.toUpperCase() === "POINTANNOTATION") {
        return {
            type: annotation.type,
            id: annotation.id,
            shape: "Point",
            annotationType: annotation.annotationObject.text,
            color: getAnnotationTypeColor(annotation.annotationObject.text),
            notes: annotation.notes,
            naturalAttributes: annotation.point,
            tags: annotation.tags,
            metadata: annotation.metadata
          }
    } else if (annotation.type.toUpperCase() === "DRAWANNOTATION") {
        let points = annotation.points.map(function (point) { return [point.x, point.y]; });
        const flatPoints = [].concat(...points);
        flatPoints.some(Array.isArray) ? flatten(flatPoints) : flatPoints;
        return {
            type: annotation.type,
            id: annotation.id,
            shape: "Draw",
            annotationType: annotation.annotationObject.text,
            color: getAnnotationTypeColor(annotation.annotationObject.text),
            notes: annotation.notes,
            naturalAttributes: {
                points: flatPoints
            },
            tags: annotation.tags,
            metadata: annotation.metadata
        }
    }
}

const getAnnotationTypeColor = (annotationObjectType) => {
    for (let i in annotationOptions)
        if (annotationOptions[i].keyName === annotationObjectType)
            return annotationOptions[i].color

    return getRandomColor()
}

const getRandomColor = () => {
    var letters = '0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}