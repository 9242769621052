import Popup from 'react-popup';

export const calculatePopupPosition = (positioningData) => {
  let position = {
    top: positioningData.mostTop,
    left: positioningData.mostRight + 10
  };
  let promptSize = { width: 350 + 2, height: 161 + 2 };

  if (positioningData.mostTop + promptSize.height > window.innerHeight) {
    position.top = Math.max(0, positioningData.mostBottom - promptSize.height);
  }
  if (positioningData.mostRight + promptSize.width > window.innerWidth) {
    if (positioningData.mostLeft - 360 > 0) {
      position.left = positioningData.mostLeft - 360;
    } else {
      position.left = ((positioningData.mostRight + positioningData.mostLeft) / 2) - 360 / 2;
      if (position.top === positioningData.mostTop) {
        if (positioningData.mostBottom + promptSize.height < window.innerHeight) { position.top = positioningData.mostBottom + 10;} else {position.top = (positioningData.mostBottom + positioningData.mostTop) / 2 - 360 / 2;}
      }
    }
  }
  return position;
};

export const openConfirmationPopup = (positioningData, nAnnotations, confirmCallback, cancelCallback) => {
  let position = calculatePopupPosition(positioningData);

  let mySpecialPopup = Popup.register({
    title: 'Deleting Annotations',
    content: `Are you sure you want to delete the selected annotations (${nAnnotations}) ?`,
    closeOnOutsideClick: false,
    buttons: {
      left: [{
        text: 'Cancel',
        action: function () {
          cancelCallback();
          Popup.clearQueue();
          Popup.close();
        }
      }],
      right: [
        {
          text: 'Delete All',
          className: 'danger',
          action: function () {
            confirmCallback();
            Popup.clearQueue();
            Popup.close();
          }
        }
      ]
    },
    position: function (box) {
      box.style.top = position.top + 'px';
      box.style.left = position.left + 'px';
      box.style.margin = 0;
      box.style.opacity = 1;
    }
  });
  Popup.queue(mySpecialPopup);
};

export default { openConfirmationPopup, calculatePopupPosition };
