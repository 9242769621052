import { observable, action, computed, configure, toJS } from 'mobx';
import AnnotationFactory from './../components/annotations/AnnotationFactory';
import appPropStore from './app-properties-store';
import { v4 as uuidv4 } from 'uuid';

configure({ enforceActions: 'observed' }); // don't allow state modifications outside actions

class Annotation {
  id = uuidv4();
  @observable annotation = {};
  @observable isSelected = false;
  @observable isHidden = false;

  @computed get internalAttributes () {
    //if (appPropStore.userImageDimensions === null || appPropStore.naturalImageDimensions === null) return;
    let internalAttributes = AnnotationFactory.calculateViewDimensions({ annotation: this.annotation }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    return internalAttributes;
  }

  constructor (annotation) {
    this.annotation = annotation;
  }

  @action pushAttributes = (obj) => {
    Object.assign(this.annotation, obj);
  }

  @action setAnnotation = (annotation) => {
    this.annotation = annotation;
  }

  @action setAnnotationType = (annotationType) => {
    this.annotation['annotationType'] = annotationType;
  }
  @action setNotes = (notes) => {
    this.annotation['notes'] = notes;
  }
  @action setColor = (color) => {
    this.annotation['color'] = color;
  }
  @action setNaturalAttributes = (naturalAttributes) => {
    this.annotation.naturalAttributes = naturalAttributes;
  }
  @action toggleSelected = (value) => {
    this.isSelected = value === null || value === undefined ? !this.isSelected : value;
  }
  @action toggleHideAnnotation = (value) => {
    this.isHidden = value === null || value === undefined ? !this.isHidden : value;
  }
}

class AnnotationsStore {
  // Values marked as 'observable' can be watched by 'observers'
  @observable annotations = []; //array of annotations of class "store.Annotation"
  @observable annotationCategories = [];
  @observable imageAnnotationCategories = [];
  @observable presetAnnotationsGroupEnabledForPreview = null; // { sourceObject: Object /* original group object */, annotations: Array<Annotation> /* parsed annotations to be used by the annotator */ }

  @observable currentAnnotationIndex = -1; // Wizard

  @computed get cleanAnnotations () {
    var cleanAnnotations = [];
    this.annotations.forEach(function (annotation) { cleanAnnotations.push(toJS(annotation.annotation)); });
    return cleanAnnotations;
  }

  @action reset = () => {
    this.annotations = [];
    this.annotationCategories = [];
    this.imageAnnotationCategories = [];
    this.currentAnnotationIndex = -1;
  }

  @action setAnnotations = (annotations) => {
    /*annotations.forEach((function (element) {
      this.annotations.push(element);
    }).bind(this));*/
    this.annotations = annotations;
  }

  @action updateInternalAnnotations = (internalAnnotations, updatedAnnotations) => { // Useful to update annotations after posting to an API in order to save the ID ref
    internalAnnotations.forEach((internalAnnotation, i) => internalAnnotation.setAnnotation(updatedAnnotations[i]));
  }

  /*
  * Fills the annotations array with the source annotations
  */
  @action setAnnotationsFromSource = (newAnnotations) => {
    this.annotations = newAnnotations.map(extAnt => new Annotation(extAnt));
  }

  @action setPresetAnnotationsGroupEnabledForPreviewFromSource = (presetAnnotationsGroup) => {
    this.presetAnnotationsGroupEnabledForPreview = !presetAnnotationsGroup ? null :
      {
        sourceObject: presetAnnotationsGroup,
        annotations: presetAnnotationsGroup.annotations.map(extAnt => new Annotation(extAnt))
      };
  }

  @action pushAnnotation = (annotation) => {
    this.annotations.push(annotation);
    if (this.currentAnnotationIndex < 0) this.currentAnnotationIndex++;
  }

  /*
  * Used when using pagination:
  * Will Find and Update the reserved space '{}' for the paginated annotations with the '@annotation'
  */
  @action addPaginatedAnnotation = (annotation) => {
    for (let i = 0; i < this.annotations.length; i++) {
      if (Object.keys(this.annotations[i].annotation).length === 0) {
        this.annotations[i].annotation = annotation;
        break;
      }
    }
  }

  @action getAnnotationById = (annotationID) => {
    return this.annotations.find(x => x.id === annotationID);
  }

  @action removeAnnotationById = (annotationId) => {
    let removedAnnotation = this.annotations.filter(obj => obj.id === annotationId)[0]; // Finds the removed annotation to return
    if (removedAnnotation == null) return;

    this.annotations = this.annotations.filter(obj => obj.id !== annotationId); //Removes the selected one

    if (this.currentAnnotationIndex >= this.annotations.length) this.currentAnnotationIndex--;
    return toJS(removedAnnotation.annotation); // Returns the removed annotation
  }

  //TODO: does this method make sense now?
  // @action updateAnnotation = (annotation) => {
  //   let current = this.annotations.find(x => x.annotation === annotation.annotation);
  //   for (var k in annotation) current[k] = annotation[k];
  // }

  @action updateStoreAnnotation = (storeAnnotation) => {
    let current = this.annotations.find(x => x.id === storeAnnotation.id);
    if (current) current.annotation = storeAnnotation.annotation;
  }

  @action updateAnnotationById = (id, annotation) => {
    let current = this.annotations.find(x => x.id === id);
    current.annotation = annotation;
  }

  @action setAnnotationCategories = (annotationCategories) => {
    this.annotationCategories = annotationCategories;
  }

  @action pushAnnotationCategory = (annotationCategory) => {
    this.annotationCategories.push(annotationCategory);
  }

  @action setImageAnnotationCategories = (imageAnnotationCategories) => {
    this.imageAnnotationCategories = imageAnnotationCategories;
  }
  @action pushImageAnnotationCategory = (imageAnnotationCategories) => {
    this.imageAnnotationCategories.push(imageAnnotationCategories);
  }

  // @action updateAnnotationByIndex = (index, annotation) => {
  //   for (var k in annotation) this.annotations[index].annotation[k] = annotation[k];
  //   //this.annotations[index] = annotation;
  // }

  /* Wizard */
  @computed get currentAnnotation () {
    let index = this.annotations.length === this.currentAnnotationIndex ? this.annotations.length - 1 : this.currentAnnotationIndex;
    let currentAnnotation = this.annotations.length !== 0 ? this.annotations[index] || null : null;
    return currentAnnotation;
  }

  @action setCurrentAnnotationIndex = (currentAnnotationIndex) => {
    this.currentAnnotationIndex = currentAnnotationIndex;
  }

  @action updateCurrentAnnotation = (annotation) => {
    for (let k in annotation.annotation) this.annotations[this.currentAnnotationIndex].annotation[k] = annotation.annotation[k];
    for (let k in annotation.internalAttributes) this.annotations[this.currentAnnotationIndex].internalAttributes[k] = annotation.internalAttributes[k];
    //this.annotations[this.currentAnnotationIndex] = annotation;
  }

  //Update the annotation type
  @action updateKeyNameByIndex = (index, keyName) => {
    this.annotations[index].annotation.annotationType = keyName;
  }

  @action updateNotesByIndex = (index, notes) => {
    this.annotations[index].annotation.notes = notes;
  }
  /* END wizard*/

  @action setHiddenCategory = (keyName, hidden) => {
    this.annotations = this.annotations.map(ant => {
      if (ant.annotation.annotationType === keyName)
        ant.toggleHideAnnotation(hidden)
      return ant
    });
  }
}

const store = new AnnotationsStore();

export default store;
export { AnnotationsStore, Annotation };
