import React, { Component } from 'react'
import { Container, Button, Paper, Typography,
    Table, TableHead, TableBody, TablePagination, TableRow, TableCell,
    IconButton, LinearProgress, Tooltip } from '@material-ui/core'
import { Delete as DeleteIcon, Launch as EditIcon,
    Visibility as VisibilityIcon, PhotoLibrary as PhotoLibraryIcon } from '@material-ui/icons'
import { Link } from "react-router-dom"


const renderDatasetsListLayout = (elements, deleteElem) => {
    return (
        <Container style={{padding: 0, marginBottom: '2.5rem'}}>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="body1">Name</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">Description</Typography>
                            </TableCell>
                            {keycloak.hasRealmRole('admin') &&
                                <TableCell style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Typography variant="body1">Options</Typography>
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {elements.map(el => (
                            <TableRow key={el.id}>
                                <TableCell>
                                    <Link to={`/datasets/${el.id}`} style={{textDecoration: 'none', color: '#757575'}}>
                                        <Typography variant='body1'>
                                            {el.name}
                                        </Typography>
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/datasets/${el.id}`} style={{textDecoration: 'none', color: '#757575'}}>
                                        <Typography variant='body1'>
                                            {el.description}
                                        </Typography>
                                    </Link>
                                </TableCell>
                                {keycloak.hasRealmRole('admin') &&
                                    <TableCell style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <IconButton aria-label="Delete"
                                            onClick={() => {
                                                if (window.confirm('Are you sure you wish to delete this item?'))
                                                    deleteElem(el.id)
                                            }} 
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    )
}

const renderAcquisitionsListLayout = (elements, deleteElem, hideReady, exportAnnot, imageSamplesDownload, datasets) => {
    return (
        <Container style={{padding: 0, marginBottom: '2.5rem'}}>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="body1">ID</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">Date</Typography>
                            </TableCell>
                            {datasets.length > 0 && (
                                <TableCell>
                                  <Typography variant="body1">Dataset</Typography>
                                </TableCell>
                            )}
                            <TableCell>
                                <Typography variant="body1">progress</Typography>
                            </TableCell>
                            {keycloak.hasRealmRole('admin') &&
                                <TableCell style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Typography variant="body1">options</Typography>
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {elements.filter(el => !hideReady || el.metadata['done'] !== 'true').map(el => (
                            <TableRow key={el.id}>
                                <TableCell>
                                    <Link to={`/acquisitions/${el.id}`}>
                                        {el.metadata['sampleId'] ? el.metadata['sampleId'] : el.id}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {new Date(el.creationTimestamp).toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric'
                                    })}
                                </TableCell>
                                {datasets.length > 0 && (
                                    <TableCell>
                                        {getDatasetName(el, datasets)}
                                    </TableCell>
                                )}
                                <TableCell>
                                    {el.metadata['done'] === 'true' ? 'done' : '' }
                                </TableCell>
                                {keycloak.hasRealmRole('admin') &&
                                    <TableCell style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <IconButton aria-label="Download Sample Images"
                                            onClick={() => imageSamplesDownload(el.id, el.metadata['sampleId'] ? el.metadata['sampleId'] : el.id)}>
                                            <PhotoLibraryIcon />
                                        </IconButton>
                                        <IconButton aria-label="Export Annotations" 
                                            onClick={() => exportAnnot(el.id, el.metadata['sampleId'] ? el.metadata['sampleId'] : el.id)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="Delete"
                                            onClick={() => deleteElem(el.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    )
}

const renderUsersListLayout = (elements) => {
    return (
        <Container style={{padding: 0, marginBottom: '2.5rem'}}>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: '15%'}}>
                                <Typography variant="body1">Name</Typography>
                            </TableCell>
                            <TableCell style={{width: '85%'}}>
                                <Typography variant="body1">Progress</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {elements.map(el => (
                            <TableRow key={el.user['id']} >
                                <TableCell style={{width: '15%'}}>
                                    <Link to={{
                                        pathname: `/users/${el.user['id']}`,
                                        state: {
                                            progress: el.progress
                                        }
                                    }}>
                                        {(el.user['firstName'] && el.user['lastName']) ? el.user['firstName'] + ' ' + el.user['lastName'] : el.user['username']}                                
                                    </Link>
                                </TableCell>
                                <TableCell style={{width: '85%'}}>
                                    {(el.progress && el.progress.total.samples > 0) ? (
                                        <div style={{display: 'flex', flexDirection: 'column', padding: 'auto 5%'}}>
                                            <Tooltip
                                                title={(100 * Math.min(el.progress.total.annotatedSamples/el.progress.total.samples, 1)).toFixed(0).toString() + ' %'}
                                                aria-label={(100 * Math.min(el.progress.total.annotatedSamples/el.progress.total.samples, 1)).toFixed(0).toString() + ' %'}
                                            >
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={100*Math.min(el.progress.total.annotatedSamples/el.progress.total.samples, 1)}
                                                    style={{height: '12px', borderRadius: '7px'}}
                                                />
                                            </Tooltip>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                                <p style={{marginBottom: 0}}>
                                                    Annotated {Math.min(el.progress.total.annotatedSamples, el.progress.total.samples)} out of {el.progress.total.samples}
                                                </p>
                                                <p style={{marginLeft: '3rem', marginBottom: 0}}>
                                                    {(100 * Math.min(el.progress.total.annotatedSamples/el.progress.total.samples, 1)).toFixed(0)} %
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{display: 'flex', flexDirection: 'column', padding: 'auto 5%'}}>
                                            <p>No acquisitions were assigned to this user</p>
                                        </div>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    )
}

const getDatasetName = (el, datasets) => {
    if (el.datasetId != "" && datasets.length > 0) {
        let dataset = datasets.find(dst => dst.id == el.datasetId)
        if (dataset) {
            return dataset.name
        }
    } else {
        return ""
    }
}

const ListLayoutContainer = ({title, elements, datasets, deleteElem, hideReady, exportAnnot, imageSamplesDownload}) => {
    switch (title) {
        case 'Datasets':
            return renderDatasetsListLayout(elements, deleteElem)
        case 'Acquisitions':
            return renderAcquisitionsListLayout(elements, deleteElem, hideReady, exportAnnot, imageSamplesDownload, datasets)
        case 'Users':
            return renderUsersListLayout(elements)
        default:
            return null;
    }
}

export default ListLayoutContainer
