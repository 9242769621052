import React, { Component } from 'react';
import store from '../../models/annotations-store';

/** The prompt content component */
class NewAnnotationCategoryForm extends Component {
  constructor (props) {
    super();
    this.state = {
      name: "",
      color: "#ffffff"
    };
  }

  addNewAnnotationCategory (e) {
    let newCategory = {
      friendlyName: this.state.name,
      keyName: this.state.name.replace(/ /g, "_"),
      color: this.state.color,
      defaultChecked: true
    };
    store.pushAnnotationCategory(newCategory);
    let newStoreCategory = store.annotationCategories.find(c => c.keyName === newCategory.keyName);
    this.props.onClose(newStoreCategory);
  }

  render () {
    return (
      <div className="well" style={{ overflow: "hidden" }}>
        <input type="image" onClick={this.props.onClose} style={{ display: "inline-block", cursor: "pointer" }} src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1OSA0NTkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1OSA0NTk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0icmVwbHkiPgoJCTxwYXRoIGQ9Ik0xNzguNSwxNDAuMjV2LTEwMkwwLDIxNi43NWwxNzguNSwxNzguNVYyOTAuN2MxMjcuNSwwLDIxNi43NSw0MC44LDI4MC41LDEzMC4wNUM0MzMuNSwyOTMuMjUsMzU3LDE2NS43NSwxNzguNSwxNDAuMjV6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==" />
        <h1 style={{ display: "inline-block", marginLeft: 5 }} >New Annotation Type</h1>
        <label htmlFor="typeName">Name: </label>
        <input id="typeName" type="text" className="form-control" placeholder="Type name" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />
        <br/>
        <label htmlFor="typeColor">Color: </label>
        <input id="typeColor" type="color" className="form-control" onChange={(e) => this.setState({ color: e.target.value })} value={this.state.color} />
        <br/>
        <button className="btn btn-primary btn-sm" style={{ float: "right" }} disabled={this.state.name.length === 0} onClick={this.addNewAnnotationCategory.bind(this)}>Create</button>
      </div>
    );
  }
}

export default NewAnnotationCategoryForm;
