import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PatientAndEyeInfoHeader extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    currentImageIndex: PropTypes.number.isRequired
  }

  lastPatient = null;
  currentPatientBackgroundColor = '#550000';

  getPatientBackgroundColor = (patient) => {
    if (patient !== this.lastPatient) {
      this.currentPatientBackgroundColor = this.currentPatientBackgroundColor === '#550000' ? '#FFAAAA' : '#550000';
      this.lastPatient = patient;
    }
    return this.currentPatientBackgroundColor;
  }

  getPatientForegroundColor = (patient) => {
    return this.getPatientBackgroundColor(patient) === '#550000' ? 'white' : 'black';
  }

  render () {
    const { data, currentImageIndex } = this.props;
    return (
      <div className="row" style={{ backgroundColor: this.getPatientBackgroundColor(data[currentImageIndex].patient) }}>

        {/* LEFT */}
        <div className="col-xs-4" style={{ backgroundColor: data[currentImageIndex].eye === 'RIGHT' ? '#2D882D' : '' }}>
          {data[currentImageIndex].eye === 'RIGHT' &&
            <h2 className="text-left align-middle" style={{ color: 'white' }} >
              <b>RIGHT EYE</b>
              <i> {`${data[currentImageIndex].eyeIndex}/${data[currentImageIndex].eyeTotal}`}</i>
            </h2>
          }
        </div>

        {/* MIDDLE */}
        <div className="col-xs-4" >
          <h2 className="text-center align-middle" style={{ color: this.getPatientForegroundColor(data[currentImageIndex].patient) }} >
            <b>PATIENT {data[currentImageIndex].patient}</b>
            <i> {`${data[currentImageIndex].patientImageIndex}/${data[currentImageIndex].patientImageTotal}`}</i>
          </h2>
        </div>

        {/* RIGHT */}
        <div className="col-xs-4" style={{ backgroundColor: data[currentImageIndex].eye === 'LEFT' ? "#2B4970" : "" }}>
          {data[currentImageIndex].eye === 'LEFT' &&
            <h2 className="text-right align-middle" style={{ color: 'white' }} >
              <b>LEFT EYE</b>
              <i> {`${data[currentImageIndex].eyeIndex}/${data[currentImageIndex].eyeTotal}`}</i>
            </h2>
          }
        </div>

      </div>
    );
  }
}
