import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Rect, Group } from 'react-konva';
import { getNaturalCoords, getNaturalSize, getPointerPositionThoughAnnotator } from '../../../utils/Utils';
import { RectangleAnnotation } from './RectangleAnnotation';
//import './RectangleAnnotator.css';
import Popup from 'react-popup';
import '../../prompts/AnnotationPrompt';

import store from './../../../models/annotations-store';
import appPropStore from './../../../models/app-properties-store';

class RectangleAnnotator extends Component {
  constructor () {
    super();
    this.state = {
      coordDown: { x: 0, y: 0 },
      tempShape: null
    };
    this._onMouseDown = this._onMouseDown.bind(this);
    this._onMouseUp = this._onMouseUp.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onTouchStart = this._onTouchStart.bind(this);
    this._onTouchEnd = this._onTouchEnd.bind(this);
    this._onTouchMove = this._onTouchMove.bind(this);

    // Values to aid the calculation of the popup position
    this.mostLeft = this.mostRight = this.mostTop = this.mostBottom = null;
  }

  restartDrawing () {
    this.mostTop = this.mostLeft = this.mostRight = this.mostBottom = null;
    this.setState({ tempShape: null });
  }

  //Mouse Inputs
  _onMouseDown (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.button !== 0) { return; }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    this.setState({
      coordDown: { x: pointerPosition.x, y: pointerPosition.y },
      tempShape: { internalAttributes: { x: pointerPosition.x, y: pointerPosition.y } }
    });
  }

  _onMouseUp (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.button !== 0 || this.state.tempShape == null) {
      this.restartDrawing();
      return;
    }
    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    let xCoord = pointerPosition.x;
    let yCoord = pointerPosition.y;
    let width = xCoord - this.state.coordDown.x;
    let height = yCoord - this.state.coordDown.y;

    //Size must be bigger than 5x5
    if (Math.abs(width) <= 2 || Math.abs(height) <= 2) {
      this.restartDrawing();
      return;
    }

    let internalAttributes = {
      x: this.state.coordDown.x, y: this.state.coordDown.y,
      height: height, width: width
    };

    //Calculate rect atributes for the real image
    let size = getNaturalSize(internalAttributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let coords = getNaturalCoords(internalAttributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let naturalAttributes = {
      ...coords,
      ...size
    };

    //Create the new rect to send to parent
    let rect = {
      annotation: {
        naturalAttributes: naturalAttributes
      }
    };


    let popupPosition = this.calculatePopupPosition();
    // Open the popup
    Popup.plugins().promptForAdditionalInfo(popupPosition, this.props.annotationTypes, ((notes, annotationType, isUpdate) => this.props.addAnnotation('Rect', rect, notes, annotationType, isUpdate)), (() => this.restartDrawing()));
  }

  calculatePopupPosition () {
    let position = {
      top: this.mostTop,
      left: this.mostRight + 10
    };
    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (this.mostTop + promptSize > window.innerHeight) {
      position.top = Math.max(0, this.mostBottom - promptSize);
    }
    if (this.mostRight + 360 > window.innerWidth) {
      if (this.mostLeft - 360 > 0) {
        position.left = this.mostLeft - 360;
      } else {
        position.left = ((this.mostRight + this.mostLeft) / 2) - 360 / 2;
        if (position.top === this.mostTop) {
          if (this.mostBottom + promptSize < window.innerHeight) { position.top = this.mostBottom + 10;} else {position.top = (this.mostBottom + this.mostTop) / 2 - 360 / 2;}
        }
      }
    }
    return position;
  }

  _onMouseMove (e) {
    if (this.state.tempShape == null) return;
    else if (!appPropStore.isDrawingKeyDown || e.evt.buttons !== 1) {this.setState({ tempShape: null }); return;}

    // Update positions for the calculation of the popup
    if (!this.mostTop || this.mostTop > e.evt.clientY) this.mostTop = e.evt.clientY;
    if (!this.mostBottom || this.mostBottom < e.evt.clientY) this.mostBottom = e.evt.clientY;
    if (!this.mostLeft || this.mostLeft > e.evt.clientX) this.mostLeft = e.evt.clientX;
    if (!this.mostRight || this.mostRight < e.evt.clientX) this.mostRight = e.evt.clientX;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    let xCoord = pointerPosition.x;
    let yCoord = pointerPosition.y;
    let width = xCoord - this.state.coordDown.x;
    let height = yCoord - this.state.coordDown.y;
    //if(Math.abs(width) <= 5 || Math.abs(height) <= 5) return;

    this.setState({
      tempShape: {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          height: height, width: width
        },
        color: "white"
      }
    });
  }

  //Touch Inputs
  _onTouchStart (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.touches.length !== 1) { return; }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    this.setState({
      coordDown: { x: pointerPosition.x, y: pointerPosition.y },
      tempShape: { internalAttributes: { x: pointerPosition.x, y: pointerPosition.y } }
    });
  }

  _onTouchEnd (e) {
    if (!appPropStore.isDrawingKeyDown || this.state.tempShape == null) {
      this.restartDrawing();
      return;
    }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    let xCoord = pointerPosition.x;
    let yCoord = pointerPosition.y;
    let width = xCoord - this.state.coordDown.x;
    let height = yCoord - this.state.coordDown.y;

    //Size must be bigger than 5x5
    if (Math.abs(width) <= 2 || Math.abs(height) <= 2) {
      this.restartDrawing();
      return;
    }

    let internalAttributes = {
      x: this.state.coordDown.x, y: this.state.coordDown.y,
      height: height, width: width
    };

    //Calculate rect atributes for the real image
    let size = getNaturalSize(internalAttributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let coords = getNaturalCoords(internalAttributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let naturalAttributes = {
      ...coords,
      ...size
    };

    //Create the new rect to send to parent
    let rect = {
      annotation: {
        naturalAttributes: naturalAttributes
      }
    };


    let popupPosition = this.calculatePopupPosition();
    // Open the popup
    Popup.plugins().promptForAdditionalInfo(popupPosition, this.props.annotationTypes, ((notes, annotationType, isUpdate) => this.props.addAnnotation('Rect', rect, notes, annotationType, isUpdate)), (() => this.restartDrawing()));
  }

  _onTouchMove (e) {
    if (this.state.tempShape == null)
      return;
    else if (!appPropStore.isDrawingKeyDown || e.evt.touches.length !== 1) {
      this.setState({ tempShape: null });
      return;
    }

    // Update positions for the calculation of the popup
    if (!this.mostTop || this.mostTop > e.evt.clientY) this.mostTop = e.evt.clientY;
    if (!this.mostBottom || this.mostBottom < e.evt.clientY) this.mostBottom = e.evt.clientY;
    if (!this.mostLeft || this.mostLeft > e.evt.clientX) this.mostLeft = e.evt.clientX;
    if (!this.mostRight || this.mostRight < e.evt.clientX) this.mostRight = e.evt.clientX;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    let xCoord = pointerPosition.x;
    let yCoord = pointerPosition.y;
    let width = xCoord - this.state.coordDown.x;
    let height = yCoord - this.state.coordDown.y;
    //if(Math.abs(width) <= 5 || Math.abs(height) <= 5) return;

    this.setState({
      tempShape: {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          height: height, width: width
        },
        color: "white"
      }
    });
  }

  render () {
    return (
      <Group>
        {/*Temp Rect*/}
        {this.state.tempShape != null &&
            <RectangleAnnotation {...this.state.tempShape} />
        }
        {/*Renders a canvas for drawing (listening to mouse inputs)*/}
        <Rect
          {...appPropStore.userImageDimensions}
          onMouseMove={this._onMouseMove} onMouseDown={this._onMouseDown} onMouseUp={this._onMouseUp}
          onTouchMove={this._onTouchMove} onTouchStart={this._onTouchStart} onTouchEnd={this._onTouchEnd}
          transformsEnabled={'none'}
        />
      </Group>
    );
  }
}

RectangleAnnotator.propTypes = {
  addAnnotation: PropTypes.func.isRequired //Callback to return the annotations
};

function calculateRectViewDimensions (rect, viewDimensions, naturalImageDimensions) {
  let internalAttributes = {
    x: (rect.annotation.naturalAttributes.x * viewDimensions.width / naturalImageDimensions.width),
    y: (rect.annotation.naturalAttributes.y * viewDimensions.height / naturalImageDimensions.height),
    width: (rect.annotation.naturalAttributes.width * viewDimensions.width / naturalImageDimensions.width),
    height: (rect.annotation.naturalAttributes.height * viewDimensions.height / naturalImageDimensions.height)
  };

  return internalAttributes;
}

function calculateRectListViewDimensions (list, viewDimensions, naturalImageDimensions) {
  list.map(listValue => (
    listValue = calculateRectViewDimensions(listValue, viewDimensions, naturalImageDimensions)
  ));
  return list;
}

export { RectangleAnnotator, calculateRectViewDimensions, calculateRectListViewDimensions };
