import React, { Component } from 'react';
import { Group } from 'react-konva';
import { observer } from 'mobx-react';
import { DrawAnnotation } from './DrawAnnotation';
import { getNaturalCoords, isIosDevice } from '../../../utils/Utils';

import '../../prompts/PromptPlugins';
import Popup from 'react-popup';

import appPropStore from './../../../models/app-properties-store';
import store from './../../../models/annotations-store';

@observer
class DrawEditor extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showAnchors: false
    };
    this.pointsBeforeDragStart = []; // Auxiliary variable for the points calculations when dragging
  }

  _onShapeDragMove (e) {
    var annotation = this.props.annotation;

    let internalPoints = [];
    for (let i = 0; i + 1 < annotation.internalAttributes.points.length; i += 2) {
      internalPoints.push(this.pointsBeforeDragStart[i] + e.target.attrs.x);
      internalPoints.push(this.pointsBeforeDragStart[i + 1] + e.target.attrs.y);
    }

    let naturalPoints = [];
    for (let i = 0; i + 1 < internalPoints.length; i += 2) {
      let c = getNaturalCoords(
        { x: internalPoints[i], y: internalPoints[i + 1] },
        appPropStore.userImageDimensions,
        appPropStore.naturalImageDimensions
      );
      naturalPoints.push(c.x); naturalPoints.push(c.y);
    }

    // Clean the modifications made by the "draggable" functionality
    this.draw.setX(0);
    this.draw.setY(0);

    annotation.setNaturalAttributes({ ...annotation.annotation.naturalAttributes, points: naturalPoints });
  }

  _onAnnotationClick (e) {
    if (e.evt.shiftKey || e.evt.button !== 0) return;

    let position = this.calculatePopupPosition(/*this.props.annotation.internalAttributes*/ { x: e.evt.clientX, y: e.evt.clientY, width: 0, height: 0 });
    // Open the popup
    Popup.plugins().updateAnnotationInfo(
      position,
      store.annotationCategories,
      this.props.annotation,
      ((notes, annotationType) => this.props.updateAnnotationInfo(this.props.annotation, notes, annotationType)),
      null
    );
  }

  _oniosDblTap (e) {
    let isios = isIosDevice()
    if (isios) {
      let position = this.calculatePopupPosition(/*this.props.annotation.internalAttributes*/ { x: e.evt.clientX, y: e.evt.clientY, width: 0, height: 0 });
      // Open the popup
      Popup.plugins().updateAnnotationInfo(
        position,
        store.annotationCategories,
        this.props.annotation,
        ((notes, annotationType) => this.props.updateAnnotationInfo(this.props.annotation, notes, annotationType)),
        null
        )
    }
  }

  _onAnnotationDblTap (e) {
    let position = this.calculatePopupPosition(/*this.props.annotation.internalAttributes*/ { x: e.evt.clientX, y: e.evt.clientY, width: 0, height: 0 });
    // Open the popup
    Popup.plugins().updateAnnotationInfo(
      position,
      store.annotationCategories,
      this.props.annotation,
      ((notes, annotationType) => this.props.updateAnnotationInfo(this.props.annotation, notes, annotationType)),
      null
    )
  }

  calculatePopupPosition (attributes) {
    //TODO fix the calculation
    let offsetLeft = 0;

    let mostTop = attributes.y;
    let mostRight = attributes.x + attributes.width + offsetLeft;
    let mostBottom = attributes.y + attributes.height;
    let mostLeft = attributes.x + offsetLeft;

    let position = {
      top: mostTop,
      left: mostRight + 10
    };
    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (mostTop + promptSize > window.innerHeight) {
      position.top = Math.max(0, mostBottom - promptSize);
    }
    if (mostRight + 360 > window.innerWidth) {
      if (mostLeft - 360 > 0) {
        position.left = mostLeft - 360;
      } else {
        position.left = ((mostRight + mostLeft) / 2) - 360 / 2;
        if (position.top === mostTop) {
          if (mostBottom + promptSize < window.innerHeight) { position.top = mostBottom + 10;} else {position.top = (mostBottom + mostTop) / 2 - 360 / 2;}
        }
      }
    }

    return position;
  }

  render () {
    return (
      <Group
        ref={instance => { this.draw = instance; }}
        draggable
        onMouseDown={(e) => {e.evt.stopPropagation();}}
        onDragMove={this._onShapeDragMove.bind(this)}
        onDragStart={() => { this.pointsBeforeDragStart = this.props.annotation.internalAttributes.points;}}
        onDragEnd={() => this.props.notifyChangesCallback('UPDATE', this.props.annotation.annotation, store.cleanAnnotations)}
        onMouseOver={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'move')}
        onMouseOut={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'default')}
        onClick={this._onAnnotationClick.bind(this)}
        onDblTap={this._onAnnotationDblTap.bind(this)}
        onTap={this._oniosDblTap.bind(this)}
      >
        {/*Annotation*/}
        <DrawAnnotation
          {...this.props.annotation}
          internalAttributes={this.props.annotation.internalAttributes}
        />
      </Group>

    );
  }
}

DrawEditor.propTypes = {
};

export { DrawEditor };
