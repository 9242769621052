import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Circle, Group } from 'react-konva';
import { getNaturalCoords, getPointerPositionThoughAnnotator } from '../../../utils/Utils';

import appPropStore from '../../../models/app-properties-store';
import store from '../../../models/annotations-store';

@observer
class ElipseAnchors extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isDraggingAnchor: false
    };
    this.rightAnchorRef = React.createRef();
    this.leftAnchorRef = React.createRef();
    this.topAnchorRef = React.createRef();
    this.bottomAnchorRef = React.createRef();
  }

  onAnchorDragMove (e) {
    const annotation = this.props.annotation;
    let naturalRadius = { ...annotation.annotation.naturalAttributes.naturalRadius };

    const pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    const naturalMousePos = getNaturalCoords({ x: pointerPosition.x, y: pointerPosition.y }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);

    if (e.target.attrs.id === "top" || e.target.attrs.id === "bottom") {
      const yNaturalCenter = Math.abs(naturalMousePos.y - annotation.annotation.naturalAttributes.y);
      naturalRadius.y = yNaturalCenter;
    }
    if (e.target.attrs.id === "left" || e.target.attrs.id === "right") {
      const xNaturalCenter = Math.abs(naturalMousePos.x - annotation.annotation.naturalAttributes.x);
      naturalRadius.x = xNaturalCenter;
    }

    annotation.setNaturalAttributes({ ...annotation.annotation.naturalAttributes, naturalRadius: naturalRadius });
  }

  dragBoundFunc = (anchorRef, pos) => {
    const { x, y } = anchorRef.current.absolutePosition()
    return { x, y };
  }

  render () {
    const { isDraggingAnchor } = this.state;
    const internalAttributes = this.props.annotation.internalAttributes;

    return (
      <Fragment>
        {/*Render the anchors */}
        {!appPropStore.isShiftDown &&
        <Group
          onMouseOver={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'pointer')}
          onMouseOut={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'default')}
          onDragStart={() => (this.setState({isDraggingAnchor: true}))}
          onDragMove={this.onAnchorDragMove.bind(this)}
          onDragEnd={() => {this.setState({isDraggingAnchor: false}); this.props.notifyChangesCallback('UPDATE', this.props.annotation.annotation, store.cleanAnnotations);}}
          onMouseDown={() => {this.setState({ isDraggingAnchor: true });}}
          onMouseUp={() => {this.setState({ isDraggingAnchor: false });}}
          onTouchStart={() => {this.setState({ isDraggingAnchor: true });}}
          onTouchEnd={() => {this.setState({ isDraggingAnchor: false });}}
        >
          <Circle
            ref={this.rightAnchorRef}
            x={internalAttributes.x + internalAttributes.radius.x} y={internalAttributes.y} radius={2 / (appPropStore.scale * 0.5)}
            fill="gray" stroke="black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false}
            id={"right"} key={"right"} strokeWidth={2 / (appPropStore.scale * 0.5)}
            draggable dragBoundFunc={(pos) => this.dragBoundFunc(this.rightAnchorRef, pos)} onDragEnd={() => {}}
          />
          <Circle
            ref={this.leftAnchorRef}
            x={internalAttributes.x - internalAttributes.radius.x} y={internalAttributes.y} radius={2 / (appPropStore.scale * 0.5)}
            fill="gray" stroke="black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false}
            id={"left"} key={"left"} strokeWidth={2 / (appPropStore.scale * 0.5)}
            draggable dragBoundFunc={(pos) => this.dragBoundFunc(this.leftAnchorRef, pos)} onDragEnd={() => {}}
          />
          <Circle
            ref={this.topAnchorRef}
            x={internalAttributes.x} y={internalAttributes.y - internalAttributes.radius.y} radius={2 / (appPropStore.scale * 0.5)}
            fill="gray" stroke="black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false}
            id={"top"} key={"top"} strokeWidth={2 / (appPropStore.scale * 0.5)}
            draggable dragBoundFunc={(pos) => this.dragBoundFunc(this.topAnchorRef, pos)} onDragEnd={() => {}}
          />
          <Circle
            ref={this.bottomAnchorRef}
            x={internalAttributes.x} y={internalAttributes.y + internalAttributes.radius.y} radius={2 / (appPropStore.scale * 0.5)}
            fill="gray" stroke="black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false}
            id={"bottom"} key={"bottom"} strokeWidth={2 / (appPropStore.scale * 0.5)}
            draggable dragBoundFunc={(pos) => this.dragBoundFunc(this.bottomAnchorRef, pos)} onDragEnd={() => {}}
          />
        </Group>
        }
      </Fragment>
    );
  }
}

ElipseAnchors.propTypes = {
  annotation: PropTypes.object.isRequired,
  notifyChangesCallback: PropTypes.func
};

export default ElipseAnchors;
