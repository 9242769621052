import React, { Component, Fragment } from 'react'
import { Container, Paper, Typography, CircularProgress} from '@material-ui/core'
import { getAllAcquisitions, addNewAcquisition, deleteAcquisition, updateAcquisition,
    exportAcquisitionAnnotations, downloadImageSamples,
    getAcquisition, fetchAllImageSamples } from '../requests/acquisitions'
import ListingPage from '../components/ListingPage'
import Loading from '../components/Loading'
import XLSX from 'xlsx'
import { AcquisitionOptions, annotationOptions, wholeImageAnnotationOptions, ACQUISITION_DEFAULT_METADATA } from '../constants'
import { fetchUsers } from '../requests/users'
import { fetchAnnotations } from '../requests/annotations'
import { getAllDatasets } from '../requests/datasets'

class Acquisitions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            results: [],
            datasets: [],
            hideReady: true,
            fetchingFileData: false,
            loading: true
        }
        this.sampleTags = []
        this.imageAnnotations = []
        this.toggleHideReady = this.toggleHideReady.bind(this)
    }

    async componentDidMount() {
        let acquisitions = []
        let datasets = []
        Promise.all([
            getAllAcquisitions().then(acqs => acquisitions = acqs),
            getAllDatasets().then(dsts => datasets = dsts)
        ]).then(() => {
            this.setState({
                results: keycloak.hasRealmRole('admin') ?
                    acquisitions :
                    acquisitions.filter(acq => (acq.ownerId === keycloak.subject // && acq.metadata['done'] === 'false'
                    )),
                loading: false,
                datasets: datasets
            })
        })
    }

    addNewAcq (newAcq, ownerId) {
        addNewAcquisition(newAcq, null).then(newAcq => {
            if (ownerId) {
                updateAcquisition(newAcq.id, {ownerId: ownerId})
            }
            this.componentDidMount()
        })
    }

    deleteAcquisition = async (acqId) => {
        if (window.confirm('Are you sure you want to delete this acquisition ?')) {
            const newData = this.state.results
            const ind = newData.findIndex(obj => obj.id === acqId)
            newData.splice(ind, 1)
            await deleteAcquisition(acqId).then(() =>
                this.setState({results: newData})
            )
        }
    }

    imageSamplesDownload = (acqId, sampleId) => {
        downloadImageSamples(acqId, sampleId)
    }

    exportAnnotations = (acqId, sampleId) => {
        exportAcquisitionAnnotations(acqId, sampleId)
    }

    toggleHideReady() {
        this.setState({hideReady: !this.state.hideReady})
    }

    async exportAllAnnotations() {
        this.setState({fetchingFileData: true})

        const completeAcquisitions = this.state.results.filter(acq => acq.metadata['done'] === 'true')
        const users = await fetchUsers()

        const imageAnnotationGroups = new Set(wholeImageAnnotationOptions.map(opt => opt.group))

        // Image classification worksheet
        this.imageAnnotations = []
        this.imageAnnotations.push(['Doctor', 'Sample', 'Image ID', 'Image Filename', ...imageAnnotationGroups, ...annotationOptions.map(opt => opt.friendlyName)])
    
        // Sample classification worksheet
        const flatTags = AcquisitionOptions.map(gr => gr.options.map(opt => { return {...opt, group: gr.group} }).flat()).flat()
        if (AcquisitionOptions.length > 1) // has more than 1 group
            this.sampleTags.push(['Doctor', 'Sample', 'Image ID', 'Image Filename', ...flatTags.map(opt => `${opt.name} (${opt.group})`), 'obeservations'])
        else
            this.sampleTags.push(['Doctor', 'Sample', 'Image ID', 'Image Filename', ...flatTags.map(opt => `${opt.name}`), 'obeservations'])

        for (let i = 0; i < completeAcquisitions.length; i++) {
            await getAcquisition(completeAcquisitions[i].id).then(acq => {
                fetchAllImageSamples(acq.id, {page: 0, size: '-1', sort: 'id'}).then(imgSamples => {

                    imgSamples.forEach(img => {
                        if (!img.tags.includes('hemogram')) {
                            let imgRow = []
                            let newRow = []
                            if (completeAcquisitions[i].ownerId) {
                                imgRow.push(users.find(us => us.id === completeAcquisitions[i].ownerId).username)
                                newRow.push(users.find(us => us.id === completeAcquisitions[i].ownerId).username)
                            } else {
                                imgRow.push('not assigned')
                                newRow.push('not assigned')
                            }

                            if (acq.metadata['sampleId']) {
                                imgRow.push(acq.metadata['sampleId'])
                                newRow.push(acq.metadata['sampleId'])
                            } else {
                                imgRow.push(acq.id)
                                newRow.push(acq.id)
                            }
        
                            imgRow.push(img.id)
                            imgRow.push(img.metadata['filename'] || '')
                            newRow.push(img.id)
                            newRow.push(img.metadata['filename'] || '')

                            flatTags.forEach(tag => {
                                if (img.metadata && img.metadata.tags.includes(tag.keyName))
                                    newRow.push('x')
                                else
                                    newRow.push('')
                            })

                            if (img.metadata) {
                                newRow.push(img.metadata.observations)
                            }
            
                            this.sampleTags.push(newRow)

                            fetchAnnotations(completeAcquisitions[i].id, img.id).then(annotations => {
                                let imageAnnotations = annotations.filter(annot => annot.type === 'WholeImageAnnotation')
                                imageAnnotationGroups.forEach(imgAnnotGrp => {
                                    const optsWithinGroup = wholeImageAnnotationOptions.filter(option => option.group === imgAnnotGrp).map(opt => opt.keyName)
                                    let res = ''
                                    imageAnnotations.forEach(imgAnnot => {
                                        if (optsWithinGroup.includes(imgAnnot.annotationObject.text)) {
                                            if (res != '')
                                                res += ', '
                                            res += imgAnnot.annotationObject.text
                                        }
                                    })
                                    imgRow.push(res)
                                })

                                let structureAnnotations = annotations.filter(annot => annot.type !== 'WholeImageAnnotation')
                                annotationOptions.forEach(option => {
                                    imgRow.push(structureAnnotations.filter(obj => obj.annotationObject.text === option.keyName).length)
                                })
                            })
                            this.imageAnnotations.push(imgRow)
                        }
                    })


                })
            })
        }
        
        this.setState({fetchingFileData: false})

        // adding worksheets to workbook and exporting file
        const imageSheet = XLSX.utils.aoa_to_sheet(this.imageAnnotations)
        const sampleSheet = XLSX.utils.aoa_to_sheet(this.sampleTags)
        const new_workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(new_workbook, imageSheet, "imageSamples & annotations")
        XLSX.utils.book_append_sheet(new_workbook, sampleSheet, "imageSamples & tags")

        XLSX.writeFile(new_workbook, 'annotations.xlsx', {})
    }

    render () {
        if (this.state.loading) {
            return <Loading />
        } else {
            return (
                <Fragment>
                    {this.state.fetchingFileData && (
                        <Container>
                            <Paper style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '80%',
                                margin: '0 auto',
                                paddingTop: '1.5rem',
                                paddingBottom: '1.5rem',
                                backgroundColor: '#aae6ba',
                                color: 'white',
                                borderRadius: '0 0 10px 10px'
                            }}>
                                <Typography variant="h5" style={{paddingBottom: '1.5rem'}}>
                                    Fetching Annotations Data to Export File
                                </Typography>
                                <CircularProgress color="white" />
                            </Paper>
                        </Container>
                    )}
                    <ListingPage
                        resultsType={'Acquisitions'}
                        results={this.state.results}
                        datasets={this.state.datasets}
                        addNew={(n_acq, ownerId, hemogramFile) => this.addNewAcq(n_acq, ownerId, hemogramFile)}
                        toggleHideReady={() => this.toggleHideReady()}
                        imageSamplesDownload={(acqId, sampleId) => this.imageSamplesDownload(acqId, sampleId)}
                        exportAnnot={(acqId, sampleId) => this.exportAnnotations(acqId, sampleId)}
                        exportAllAnnotations={() => this.exportAllAnnotations()}
                        deleteElement={acqId => this.deleteAcquisition(acqId)}
                        hideReady={this.state.hideReady}
                    />
                </Fragment>
            )
        }
    }
}

export default Acquisitions