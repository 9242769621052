import React, { Component, Fragment } from 'react'
import { Container, Paper, GridList, GridListTile,
    GridListTileBar, Typography, IconButton,
    Button, CircularProgress, ImageListItemBar,
    ImageList, ImageListItem} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import Loading from './Loading'
import { BASE_URL } from '../constants/index'
import { removeImageSample, getRawImageBlob } from '../requests/acquisitions'


class GridLayoutContainer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: true,
            showAll: false,
            allImagesFetched: false,
            loadingAllSamples: false,
            images: []
        }
        this.mounted = false
    }

    async componentDidMount () {
        this.mounted = true;
        const loadedImages = []
        for (const elem of this.props.elements) {
            await getRawImageBlob(this.props.acquisitionId, elem.id).then(blob => {
                loadedImages.push({
                    id: elem.id,
                    path: blob
                })
            })
        }

        if (this.mounted) {
            this.setState({
                images: loadedImages,
                allImagesFetched: false,
                loading: false
            })
        }
    }

    async componentDidUpdate (prevProps) {
        if (this.props.elements.length === prevProps.elements.length)
            return
        
        this.setState({loadingAllSamples: true})
        const loadedImages = []
        for (const elem of this.props.elements) {
            await getRawImageBlob(this.props.acquisitionId, elem.id).then(blob => {
                loadedImages.push({
                    id: elem.id,
                    path: blob
                })
            })
        }

        this.setState({
            images: loadedImages,
            loadingAllSamples: false
        })

    }

    componentWillUnmount(){
        this.setState({
            images: []
        })
        this.mounted = false
    }

    toogleShowAll () {
        if (!this.state.allImagesFetched) {
            this.props.fetchAllImageSamples()
        }
        let val = this.state.showAll
        this.setState({
            allImagesFetched: true,
            showAll: !val
        })
    }

    removeImageSample (sampleId) {
        const newImages = this.state.images
        const ind = newImages.findIndex(obj => obj.id === sampleId)
        newImages.splice(ind, 1)

        removeImageSample(this.props.acquisitionId, sampleId)
        this.setState({images: newImages})
    }

    render () {
        if (this.state.loading) {
            return <Loading />
        } else {            
            return (
                <Paper style={{padding: '2rem', paddingBottom: 0, marginBottom: '1.5rem'}}>
                    {this.state.images.length ? (
                        <Fragment>
                            <GridList cols={5} style={this.state.showAll ? {height: '100%'} : {height: 'min-content'}}>
                                {this.state.images.map(tile => (
                                    <GridListTile key={tile.id} cols={1}>
                                        <img src={tile.path} alt={tile.id} />
                                        {keycloak.hasRealmRole('admin') &&
                                            <GridListTileBar
                                                style={{backgroundColor: 'transparent'}}
                                                titlePosition='top'
                                                actionIcon={
                                                    <IconButton aria-label="remove from samples"
                                                        onClick={() => this.removeImageSample(tile.id)}
                                                    >
                                                        <DeleteIcon style={{color: '#fa5555'}} />
                                                    </IconButton>
                                                }
                                            />
                                        }
                                    </GridListTile>
                                ))}
                            </GridList>
                            {this.state.loadingAllSamples && (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '1rem'}}>
                                    <Typography variant='body1' style={{padding: '1rem'}} >
                                        Loading remaining image samples
                                    </Typography>
                                    <CircularProgress style={{color: '#009474'}} />
                                </div>
                            )}
                            <Button
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%'
                                }}
                                onClick={() => this.toogleShowAll()}
                            >
                                {this.state.showAll ? (
                                    <Typography variant='body2'>
                                        Show less ...
                                    </Typography>
                                ) : (
                                    <Typography variant='body2'>
                                        Show more ...
                                    </Typography>
                                )}
                            </Button>
                        </Fragment>
                    ) : (
                        <Typography>
                            No samples added to this acquisition
                        </Typography>
                    )}
                </Paper>
            )
        }
    }
}

export default GridLayoutContainer
