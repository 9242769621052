import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Rect, Group } from 'react-konva';
import { CircleAnnotation } from './CircleAnnotation';
import { getNaturalCoords, isIosDevice } from '../../../utils/Utils';
import Anchors from './CircleAnchors';

import '../../prompts/PromptPlugins';
import Popup from 'react-popup';

import appPropStore from './../../../models/app-properties-store';
import store from './../../../models/annotations-store';

@observer
class CircleEditor extends Component {
  constructor () {
    super();

    this.state = {
      showAnchors: false,
      mouseInside: false,
      resizing: false
    };
  }

  onShapeDragMove (e) {
    var annotation = this.props.annotation;
    let coords = getNaturalCoords({ x: e.target.attrs.x, y: e.target.attrs.y }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    annotation.setNaturalAttributes({ ...annotation.annotation.naturalAttributes, ...coords });
  }

  _onAnnotationClick (e) {
    if (e.evt.shiftKey || e.evt.button !== 0) return;

    let position = this.calculatePopupPosition({ x: this.props.annotation.internalAttributes.radius + e.evt.clientX, y: this.props.annotation.internalAttributes.radius + e.evt.clientY, width: 0, height: 0 });
    // Open the popup
    Popup.plugins().updateAnnotationInfo(
      position,
      store.annotationCategories,
      this.props.annotation,
      ((notes, annotationType) => this.props.updateAnnotationInfo(this.props.annotation, notes, annotationType)),
      null
    );
  }

  _oniosDblTap (e) {
    let isios = isIosDevice()
    if (isios) {
      let position = this.calculatePopupPosition({ x: this.props.annotation.internalAttributes.radius + e.evt.clientX, y: this.props.annotation.internalAttributes.radius + e.evt.clientY, width: 0, height: 0 });
      // Open the popup
      Popup.plugins().updateAnnotationInfo(
        position,
        store.annotationCategories,
        this.props.annotation,
        ((notes, annotationType) => this.props.updateAnnotationInfo(this.props.annotation, notes, annotationType)),
        null
      )
    }
  }

  _onAnnotationDblTap (e) {
    let position = this.calculatePopupPosition({ x: this.props.annotation.internalAttributes.radius + e.evt.clientX, y: this.props.annotation.internalAttributes.radius + e.evt.clientY, width: 0, height: 0 });
    // Open the popup
    Popup.plugins().updateAnnotationInfo(
      position,
      store.annotationCategories,
      this.props.annotation,
      ((notes, annotationType) => this.props.updateAnnotationInfo(this.props.annotation, notes, annotationType)),
      null
    )
  }

  calculatePopupPosition (attributes) {
    let offsetLeft = 0;

    let mostTop = attributes.y;
    let mostRight = attributes.x + attributes.width + offsetLeft;
    let mostBottom = attributes.y + attributes.height;
    let mostLeft = attributes.x + offsetLeft;

    let position = {
      top: mostTop,
      left: mostRight + 10
    };
    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (mostTop + promptSize > window.innerHeight) {
      position.top = Math.max(mostTop - promptSize);
    }
    if (mostRight + 360 > window.innerWidth) {
      if (mostLeft - 360 > 0) {
        position.left = mostLeft - 360;
      } else {
        position.left = ((mostRight + mostLeft) / 2) - 360 / 2;
        if (position.top === mostTop) {
          if (mostBottom + promptSize < window.innerHeight) { position.top = mostBottom + 10;} else {position.top = (mostBottom + mostTop) / 2 - 360 / 2;}
        }
      }
    }

    return position;
  }

  render () {
    return (
      <Group
        ref={instance => (this.instance = instance)}
        onMouseDown={(e) => {e.evt.stopPropagation();}}
        onMouseOver={() => (this.setState({ showAnchors: true, mouseInside: true }))}
        onMouseOut={() => {this.setState({ mouseInside: false }); setTimeout(() => { if (!this.state.mouseInside && this.instance) this.setState({ showAnchors: false }); }, 1000);}}
        onTap={() => {this.setState({ showAnchors: true, mouseInside: true })}}
      >
        {/*Annotation*/}
        <Group
          onMouseOver={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'move')}
          onMouseOut={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'default')}
          onDragStart={() => this.setState({ showAnchors: false })}
          onDragEnd={() => {this.setState({ showAnchors: true }); this.props.notifyChangesCallback('UPDATE', this.props.annotation.annotation, store.cleanAnnotations);}}
          onClick={this._onAnnotationClick.bind(this)}
          onDblTap={this._onAnnotationDblTap.bind(this)}
          onTap={this._oniosDblTap.bind(this)}
        >
          <CircleAnnotation
            draggable
            onDragStart={() => this.setState({ showAnchors: false })}
            onDragEnd={() => {this.setState({ showAnchors: true }); this.props.notifyChangesCallback('UPDATE', this.props.annotation.annotation, store.cleanAnnotations);}}
            onDragMove={this.onShapeDragMove.bind(this)} {...this.props.annotation}
            internalAttributes={this.props.annotation.internalAttributes} />
        </Group>
        {/*Render the anchors */}
        {this.state.showAnchors &&
        <Anchors annotation={this.props.annotation} notifyChangesCallback={(this.props.notifyChangesCallback)} forceParentUpdate={() => this.forceUpdate()}/>
        }
        {/* Rect (was necessary for the circle resizing to prevent the mouse to be intersected when mouse is over other components) */}
        {this.state.resizing &&
        <Rect
          {...appPropStore.userImageDimensions}
          onMouseUp={() => this.setState({ resizing: false })}
        />
        }
      </Group>
    );
  }
}

CircleEditor.propTypes = {
};

export { CircleEditor };
