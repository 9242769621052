import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Group } from 'react-konva';
import { observer } from 'mobx-react';
import { PointAnnotation } from './PointAnnotation';
import { getNaturalCoords, isIosDevice } from '../../../utils/Utils';

import '../../prompts/PromptPlugins';
import Popup from 'react-popup';

import appPropStore from './../../../models/app-properties-store';
import store from './../../../models/annotations-store';

@observer
class PointEditor extends Component {
  constructor () {
    super();
  }

  onShapeDragMove (e) {
    var annotation = this.props.annotation;
    let coords = getNaturalCoords({ x: e.target.attrs.x, y: e.target.attrs.y }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    annotation.setNaturalAttributes({ ...annotation.annotation.naturalAttributes, ...coords });
  }

  _onAnnotationClick (e) {
    if (e.evt.shiftKey || e.evt.button !== 0) return;

    let position = this.calculatePopupPosition({ x: e.evt.clientX, y: e.evt.clientY, width: 0, height: 0 });
    // Open the popup
    Popup.plugins().updateAnnotationInfo(
      position,
      store.annotationCategories,
      this.props.annotation,
      ((notes, annotationType) => this.props.updateAnnotationInfo(this.props.annotation, notes, annotationType)),
      null
    );
  }

  _oniosDblTap (e) {
    let isios = isIosDevice()
    if (isios) {
      let position = this.calculatePopupPosition({ x: e.evt.clientX, y: e.evt.clientY, width: 0, height: 0 });
      // Open the popup
      Popup.plugins().updateAnnotationInfo(
        position,
        store.annotationCategories,
        this.props.annotation,
        ((notes, annotationType) => this.props.updateAnnotationInfo(this.props.annotation, notes, annotationType)),
        null
      );
    }
  }

  _onAnnotationDblTap (e) {
    let position = this.calculatePopupPosition({ x: e.evt.clientX, y: e.evt.clientY, width: 0, height: 0 });
    // Open the popup
    Popup.plugins().updateAnnotationInfo(
      position,
      store.annotationCategories,
      this.props.annotation,
      ((notes, annotationType) => this.props.updateAnnotationInfo(this.props.annotation, notes, annotationType)),
      null
    );
  }

  calculatePopupPosition (attributes) {
    let position = {
      top: attributes.y,
      left: attributes.x + appPropStore.dotSize * 2 + 10
    };
    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (position.left + promptSize > window.innerWidth) {
      position.left = attributes.x - appPropStore.dotSize * 2 - 360;
    }
    if (position.top + promptSize > window.innerHeight) {
      position.top = Math.max(0, attributes.y - promptSize);
    }

    return position;
  }

  render () {
    return (
      <Group
        onMouseDown={(e) => {e.evt.stopPropagation();}}
        onMouseOver={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'move')}
        onMouseOut={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'default')}
        onDragMove={this.onShapeDragMove.bind(this)}
        onDragEnd={() => this.props.notifyChangesCallback('UPDATE', this.props.annotation.annotation, store.cleanAnnotations)}
        onClick={this._onAnnotationClick.bind(this)}
        onDblTap={this._onAnnotationDblTap.bind(this)}
        onTap={this._oniosDblTap.bind(this)}
      >
        <PointAnnotation
          draggable onDragMove={() => {}}
          internalAttributes={this.props.annotation.internalAttributes}
          {...this.props.annotation}
        />
      </Group>
    );
  }
}

PointEditor.propTypes = {
};

export { PointEditor };
