import React, { Component } from 'react';
import appPropStore from './../models/app-properties-store';
import { limitOffset } from './../utils/Utils';
import appPropertiesStore from '../models/app-properties-store';

class ZoomComponent extends Component {
  constructor (props) {
    super(props);
    this.stageMove = this.stageMove.bind(this);
    this.componentRef = React.createRef();

    this.deltaY = 0;
    this.minDeltaY = 5;

    this.previousTouch = null;
    this.initialDistance = 0;

    this.touchZoomThresh = 60;
  }

  componentDidMount () {
    if (this.componentRef.current) {
      this.componentRef.current.addEventListener('wheel', this.handleMouseOnWheel, { passive: false });
    }
  }
  componentWillUnmount () {
    if (this.componentRef.current) {
      this.componentRef.current.removeEventListener('wheel', this.handleMouseOnWheel, { passive: false });
    }
  }

  getPointDistance(pt1, pt2) {
    let dist = Math.sqrt(Math.pow((pt1.x - pt2.x), 2) + Math.pow((pt1.y - pt2.y), 2))
    return (dist > this.touchZoomThresh) ? dist : 0
  }

  /* HANDLE ZOOMING */
  handleMouseOnWheel = (e) => {
    if (this.isPromptOpen()) return;
    e.preventDefault();
    this.deltaY += Math.abs(e.deltaY);
    if (this.deltaY < this.minDeltaY) return;
    this.deltaY = 0;

    this.clearSearchTimeout();
    appPropertiesStore.setDrawAnnotations(false);
    this.updateScale(e.deltaY < 0, undefined, true);

    this.searchTimeout = setTimeout(function () {
      appPropertiesStore.setDrawAnnotations(true);
    }, 100);
  }

  updateScale (zoomIn = true, scaleBy = 1.14, centerOnMousePos = false) {
    const stage = appPropStore.annotatorRef.getStage();
    if (!stage || !stage.getPointerPosition()) return;
    const oldScale = stage.scaleX();
    let newOffsetPos = { x: 0, y: 0 };

    const newScale = zoomIn ? oldScale * scaleBy : oldScale / scaleBy;
    if (newScale <= 1) {
      appPropStore.setScale(1);
      appPropertiesStore.setOffset({ x: 0, y: 0 });
      return;
    }
    appPropStore.setScale(newScale);

    const zoomedTo = {
      x: centerOnMousePos ? stage.getPointerPosition().x : appPropStore.stageDimensions.width / 2,
      y: centerOnMousePos ? stage.getPointerPosition().y : appPropStore.stageDimensions.height / 2
    };
    const centerPos = {
      x: (zoomedTo.x - stage.x()) / oldScale,
      y: (zoomedTo.y - stage.y()) / oldScale
    };
    newOffsetPos = {
      x: zoomedTo.x - centerPos.x * newScale,
      y: zoomedTo.y - centerPos.y * newScale
    };

    newOffsetPos = limitOffset(appPropStore.stageDimensions, appPropStore.userImageDimensions, newOffsetPos, appPropStore.scale);
    appPropStore.setOffset(newOffsetPos);
  }

  stageMove (e) {
    let directionX = e.movementX || e.mozMovementX || e.webkitMovementX || 0;
    let directionY = e.movementY || e.mozMovementY || e.webkitMovementY || 0;
    this.updateOffset(directionX / appPropStore.scale, directionY / appPropStore.scale);
  }

  updateOffset (directionX = 0, directionY = 0) {
    let offset = {
      x: appPropStore.offset.x + directionX * appPropStore.scale,
      y: appPropStore.offset.y + directionY * appPropStore.scale
    };

    offset = limitOffset(appPropStore.stageDimensions, appPropStore.userImageDimensions, offset, appPropStore.scale);

    appPropStore.setOffset(offset);
  }
  /* END HANDLE ZOOMING */


  clearSearchTimeout () {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }

  isPromptOpen () {
    return document.getElementsByClassName('mm-popup mm-popup--visible').length > 0;
  }

  render () {
    return (
      <div
        id={'zoomComponentDiv'}
        ref={this.componentRef}
        onMouseDown={(e) => {
          if (this.isPromptOpen() || e.button !== 0 || appPropertiesStore.isShiftDown || e.shiftKey || appPropStore.isDrawingKeyDown) return;
          window.addEventListener('mousemove', this.stageMove);
          appPropertiesStore.setDrawAnnotations(false);
        }}
        onMouseUp={(e) => { window.removeEventListener('mousemove', this.stageMove); appPropertiesStore.setDrawAnnotations(true);}}
        onMouseLeave={() => {window.removeEventListener('mousemove', this.stageMove); appPropertiesStore.setDrawAnnotations(true);}}
        onTouchStart={(e) => {
          if (e.touches.length == 2) {
            var middlePoint = {
              x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
              y: (e.touches[0].clientY + e.touches[1].clientY) / 2
            }
            this.previousTouch = middlePoint
            this.initialDistance = this.getPointDistance({x: e.touches[0].clientX, y: e.touches[0].clientY}, {x: e.touches[1].clientX, y: e.touches[1].clientY})
          }
        }}
        onTouchEnd={(e) => {
          this.previousTouch = null
          this.initialDistance = 0
        }}
        onTouchMove={(e) => {
          if (this.isPromptOpen()) return;

          if (e.touches.length == 2 && this.previousTouch != null) {
            let dist = this.getPointDistance({x: e.touches[0].clientX, y: e.touches[0].clientY}, {x: e.touches[1].clientX, y: e.touches[1].clientY})
            
            // if ZOOM
            this.deltaY = dist - this.initialDistance;
            if (Math.abs(this.deltaY) > this.minDeltaY) {
              const stage = appPropStore.annotatorRef.getStage();
              if (!stage || !stage.getPointerPosition()) return;
              const oldScale = stage.scaleX();
              let newOffsetPos = { x: 0, y: 0 };

              const newScale = this.deltaY > 0 ? oldScale * 1.07 : oldScale / 1.07;
              if (newScale <= 1) {
                appPropStore.setScale(1);
                appPropertiesStore.setOffset({ x: 0, y: 0 });
                return;
              }
              appPropStore.setScale(newScale);

              const zoomedTo = {
                x: appPropStore.stageDimensions.width / 2,
                y: appPropStore.stageDimensions.height / 2
              };
              const centerPos = {
                x: (zoomedTo.x - stage.x()) / oldScale,
                y: (zoomedTo.y - stage.y()) / oldScale
              };
              newOffsetPos = {
                x: zoomedTo.x - centerPos.x * newScale,
                y: zoomedTo.y - centerPos.y * newScale
              };

              newOffsetPos = limitOffset(appPropStore.stageDimensions, appPropStore.userImageDimensions, newOffsetPos, appPropStore.scale);
              appPropStore.setOffset(newOffsetPos);
              this.initialDistance = dist
            }
            // else PAN
            else {
              let middlePoint = {
                x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
                y: (e.touches[0].clientY + e.touches[1].clientY) / 2
              }
              let directionX = middlePoint.x - this.previousTouch.x || 0;
              let directionY = middlePoint.y - this.previousTouch.y || 0;
              this.updateOffset(directionX / appPropStore.scale, directionY / appPropStore.scale);
              this.previousTouch = middlePoint
            }
          }
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default ZoomComponent;
