import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Rect, Group } from 'react-konva';
import update from 'immutability-helper';
import { getNaturalCoords, getPointerPositionThoughAnnotator, isIosDevice } from '../../../utils/Utils';
import { PolygonAnnotation } from './PolygonAnnotation';
import Popup from 'react-popup';
import '../../prompts/AnnotationPrompt';

import store from './../../../models/annotations-store';
import appPropStore from './../../../models/app-properties-store';

class PolygonAnnotator extends Component {
  constructor () {
    super();
    this.state = {
      coordDown: null,
      points: [],
      tempShape: null
    };
    this._onMouseDown = this._onMouseDown.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onTouchMove = this._onTouchMove.bind(this);
    this._onTouchEnd = this._onTouchEnd.bind(this);

    // Values to aid the calculation of the popup position
    this.mostLeft = this.mostRight = this.mostTop = this.mostBottom = null;

    this.clickedSamePos = false; // Used to know if a used clicked on the same spot repeatedly (in order to finish the annotation on static mode)
  }

  restartDrawing () {
    this.mostTop = this.mostLeft = this.mostRight = this.mostBottom = null;
    this.setState({
      coordDown: null,
      points: [],
      tempShape: null
    });
  }

  //Mouse Inputs
  _onMouseDown (e) {
    if (!appPropStore.isDrawingKeyDown || e.evt.button !== 0) return;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    if (this.isSamePointPositionAsLastPoint(pointerPosition, this.state.points)) { this.clickedSamePos = true; return; } else this.clickedSamePos = false;

    if (this.state.coordDown == null) {
      this.setState({
        coordDown: { x: pointerPosition.x, y: pointerPosition.y },
        points: update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] })
      });
    } else {
      let newPoints = update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] });
      let newtempShape = {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          points: newPoints
        },
        color: "white"
      };
      this.setState({
        points: newPoints,
        tempShape: newtempShape
      });
    }
  }

  _onMouseMove (e) {
    if (appPropStore.isDrawingKeyDown && this.state.coordDown != null) {
      // Update positions for the calculation of the popup
      if (!this.mostTop || this.mostTop > e.evt.clientY) this.mostTop = e.evt.clientY;
      if (!this.mostBottom || this.mostBottom < e.evt.clientY) this.mostBottom = e.evt.clientY;
      if (!this.mostLeft || this.mostLeft > e.evt.clientX) this.mostLeft = e.evt.clientX;
      if (!this.mostRight || this.mostRight < e.evt.clientX) this.mostRight = e.evt.clientX;


      let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
      let newPoints = update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] });
      let newtempShape = {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          points: newPoints
        },
        color: "transparent"
      };
      this.setState({
        tempShape: newtempShape
      });
    }

    if (appPropStore.isDrawingKeyDown) return; //if still drawing

    if (!appPropStore.isDrawingKeyDown && this.state.coordDown != null && this.state.points.length >= 3 * 2) {
      //Update the shape to remove the last point preview
      this.setState({
        tempShape: {
          internalAttributes: {
            x: this.state.coordDown.x, y: this.state.coordDown.y,
            points: this.state.points
          },
          color: "transparent"
        }
      });

      this.createAnnotationFromPoints(this.state.points);
    } else {
      this.restartDrawing();
      return;
    }
  }

  //Touch Inputs
  _onTouchEnd (e) {
    if (!appPropStore.isDrawingKeyDown && this.state.coordDown != null && this.state.points.length >= 3 * 2) {
      //Update the shape to remove the last point preview
      /*
      this.setState({
        tempShape: {
          internalAttributes: {
            x: this.state.coordDown.x, y: this.state.coordDown.y,
            points: this.state.points
          },
          color: "transparent"
        }
      });
      */

      this.createAnnotationFromPoints(this.state.points);
    } else if (!appPropStore.isDrawingKeyDown) {
      return;
    }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    if (this.isSamePointPositionAsLastPoint(pointerPosition, this.state.points, 5)) { this.clickedSamePos = true; return; } else this.clickedSamePos = false;

    if (this.state.coordDown == null) {
      this.setState({
        coordDown: { x: pointerPosition.x, y: pointerPosition.y },
        points: update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] })
      });
    } else {
      let newPoints = update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] });
      let newtempShape = {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          points: newPoints
        },
        color: "white"
      };
      this.setState({
        points: newPoints,
        tempShape: newtempShape
      });
    }
  }

  _onTouchMove (e) {
    if (appPropStore.isDrawingKeyDown && e.evt.touches.length == 1 && this.state.coordDown != null) {
      // Update positions for the calculation of the popup
      if (!this.mostTop || this.mostTop > e.evt.clientY) this.mostTop = e.evt.clientY;
      if (!this.mostBottom || this.mostBottom < e.evt.clientY) this.mostBottom = e.evt.clientY;
      if (!this.mostLeft || this.mostLeft > e.evt.clientX) this.mostLeft = e.evt.clientX;
      if (!this.mostRight || this.mostRight < e.evt.clientX) this.mostRight = e.evt.clientX;


      let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
      let newPoints = update(this.state.points, { $push: [pointerPosition.x, pointerPosition.y] });
      let newtempShape = {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          points: newPoints
        },
        color: "transparent"
      };
      this.setState({
        tempShape: newtempShape
      });
    }

    if (appPropStore.isDrawingKeyDown) return; //if still drawing

    if (!appPropStore.isDrawingKeyDown && this.state.coordDown != null && this.state.points.length >= 3 * 2) {
      //Update the shape to remove the last point preview
      this.setState({
        tempShape: {
          internalAttributes: {
            x: this.state.coordDown.x, y: this.state.coordDown.y,
            points: this.state.points
          },
          color: "transparent"
        }
      });

      this.createAnnotationFromPoints(this.state.points);
    } else {
      this.restartDrawing();
      return;
    }
  }

  /**
   * This method is called when the user double clicks
   * The objective of the use of this method is the possibility of finishing the annotation mainly when the we are using the static annotation mode (double click on the annotation tool)
   * If the double click is not on the same position, we will exit the function
   */
  _onDblClick = (e) => {
    if (!appPropStore.isDrawingKeyDown || !this.clickedSamePos) return;

    if (this.state.coordDown != null && this.state.points.length >= 3 * 2) this.createAnnotationFromPoints(this.state.points);
    else { this.restartDrawing(); return; }
  }

  _onTap = (e) => {
    if (!isIosDevice)
      return

    if (!appPropStore.isDrawingKeyDown || !this.clickedSamePos) return;

    if (this.state.coordDown != null && this.state.points.length >= 3 * 2) this.createAnnotationFromPoints(this.state.points);
    else { this.restartDrawing(); return; }
  }

  _onDblTap = (e) => {
    if (!appPropStore.isDrawingKeyDown || !this.clickedSamePos) return;

    if (this.state.coordDown != null && this.state.points.length >= 3 * 2) this.createAnnotationFromPoints(this.state.points);
    else { this.restartDrawing(); return; }
  }

  isSamePointPositionAsLastPoint = (currPoint, pointsArray, touchThreshold = 0) => {
    return pointsArray.length >= 2 &&
      Math.abs(currPoint.x - pointsArray[pointsArray.length - 2]) <= touchThreshold &&
      Math.abs(currPoint.y - pointsArray[pointsArray.length - 1]) <= touchThreshold;
  }


  createAnnotationFromPoints = (points) => {
    //calculate the points
    var internalPoints = [];
    let naturalPoints = [];
    for (let i = 0; i < points.length; i++) {
      if (i % 2) { //y
        let y = points[i];
        internalPoints.push(y);
        naturalPoints.push(getNaturalCoords({ y: y }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions).y);
      } else { //x
        let x = points[i];
        internalPoints.push(x);
        naturalPoints.push(getNaturalCoords({ x: x }, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions).x);
      }
    }

    //Send to parent
    let polygon = {
      annotation: {
        naturalAttributes: {
          points: naturalPoints
        }
      }
    };

    let popupPosition = this.calculatePopupPosition();
    // Open the popup
    Popup.plugins().promptForAdditionalInfo(
      popupPosition,
      this.props.annotationTypes,
      ((notes, annotationType, isUpdate) => this.props.addAnnotation('Polygon', polygon, notes, annotationType, isUpdate)),
      (() => this.restartDrawing())
    );
  }

  calculatePopupPosition () {
    let position = {
      top: this.mostTop,
      left: this.mostRight + 10
    };
    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (this.mostTop + promptSize > window.innerHeight) {
      position.top = Math.max(0, this.mostBottom - promptSize);
    }
    if (this.mostRight + 360 > window.innerWidth) {
      if (this.mostLeft - 360 > 0) {
        position.left = this.mostLeft - 360;
      } else {
        position.left = ((this.mostRight + this.mostLeft) / 2) - 360 / 2;
        if (position.top === this.mostTop) {
          if (this.mostBottom + promptSize < window.innerHeight) { position.top = this.mostBottom + 10; } else { position.top = (this.mostBottom + this.mostTop) / 2 - 360 / 2; }
        }
      }
    }
    return position;
  }

  render () {
    return (
      <Group>
        {/*Temp Rect*/}
        {this.state.tempShape != null &&
          <PolygonAnnotation
            {...this.state.tempShape}
            closed
          />
        }
        {/*Renders a canvas for drawing (listening to mouse inputs)*/}
        <Rect
          {...appPropStore.userImageDimensions}
          onMouseMove={this._onMouseMove}
          onMouseDown={this._onMouseDown}
          onMouseUp={this._onMouseUp}
          onTouchMove={this._onTouchMove}
          onTouchEnd={this._onTouchEnd}
          onDblClick={this._onDblClick}
          onDblTap={this._onDblTap}
          onTap={this._onTap}
        />
      </Group>
    );
  }
}

PolygonAnnotator.propTypes = {
  addAnnotation: PropTypes.func.isRequired //Callback to return the annotations
};

function calculatePolygonViewDimensions (polygon, viewDimensions, naturalImageDimensions) {
  let internalAttributes = {
    points: getViewListPointsCoodsFromNaturalCoords(polygon.annotation.naturalAttributes.points, viewDimensions, naturalImageDimensions)
  };

  return internalAttributes;
}

function calculatePolygonListViewDimensions (list, viewDimensions, naturalImageDimensions) {
  list.map(listValue => (
    listValue = calculatePolygonViewDimensions(listValue, viewDimensions, naturalImageDimensions)
  ));
  return list;
}

function getViewListPointsCoodsFromNaturalCoords (points, viewDimensions, naturalImageDimensions) {
  let newPoints = [];
  for (let i = 0; i < points.length; i++) {
    if (i % 2) newPoints.push(points[i] * viewDimensions.width / naturalImageDimensions.width);
    else newPoints.push(points[i] * viewDimensions.height / naturalImageDimensions.height);
  }
  return newPoints;
}

export { PolygonAnnotator, calculatePolygonViewDimensions, calculatePolygonListViewDimensions };
