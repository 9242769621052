import React, { Component, Fragment } from 'react'
import { getAllDatasets, addNewDataset, deleteDataset } from '../requests/datasets'
import ListingPage from '../components/ListingPage'
import Loading from '../components/Loading'

class Datasets extends Component {
    constructor (props) {
        super(props)
        this.state = {
            results: [],
            loading: true
        }
    }

    async componentDidMount () {
        getAllDatasets().then(datasets => {
            this.setState({
                results: datasets,
                loading: false
            })
        })
    }
    
    addNewDataset(n_elem) {
        addNewDataset(n_elem).then(() => {
            this.componentDidMount()
        })
    }

    deleteDataset = async (datasetId) => {
        const newData = this.state.results
        const ind = newData.findIndex(obj => obj.id === datasetId)
        newData.splice(ind, 1)
        await deleteDataset(datasetId).then(() => {
            this.setState({results: newData})
        }
        )
    }

    render () {
        if (this.state.loading) {
            return <Loading />
        } else {
            return <ListingPage
                resultsType={'Datasets'}
                results={this.state.results}
                addNew={n_el => this.addNewDataset(n_el)}
                deleteElement={elemId => this.deleteDataset(elemId)}
            />
        }
    }
}

export default Datasets 