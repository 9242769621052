import React, { Component } from 'react';

import { observer } from 'mobx-react';
import store from './../models/annotations-store';

@observer
class WizardControlsComponent extends Component {
  // Send the 'annotations' to the 'notifyChangesCallback' callback
  notifyChanges (annotations) {
    if (this.props.notifyChangesCallback) {
      this.props.notifyChangesCallback(annotations);
    }
  }

  handleClickNext () {
    if (store.currentAnnotationIndex < store.annotations.length - 1) {
      store.setCurrentAnnotationIndex(store.currentAnnotationIndex + 1);
      this.props.onAnnotationChanged();
    } else if (!(store.currentAnnotationIndex < store.annotations.length - 1) && this.props.hasNextImage) {
      this.props.changeImage(1);
      this.props.onAnnotationChanged();
    }
  }

  handleClickPrevious () {
    if (store.currentAnnotationIndex > 0) {
      store.setCurrentAnnotationIndex(store.currentAnnotationIndex - 1);
      this.props.onAnnotationChanged();
    }
  }

  setAnnotationType (e) {
    let keyName = e.target.value;
    store.updateKeyNameByIndex(store.currentAnnotationIndex, keyName);

    if (this.props.autoSkipAnnotation == null || this.props.autoSkipAnnotation) {this.handleClickNext();}

    this.notifyChanges(store.cleanAnnotations);
    this.forceUpdate(); //NOTE because of the 'ref' on the mobx
  }

  handleOnChangeInputText (e) {
    store.updateNotesByIndex(store.currentAnnotationIndex, e.target.value);
    this.notifyChanges(store.cleanAnnotations);

    this.forceUpdate(); //NOTE because of the 'ref' on the mobx
  }

  selectAnnotationCategoryByIndex (index) {
    let annotationTypes = this.props.annotationTypes[store.currentAnnotation.annotation.shape];
    if (index < annotationTypes.length) {
      this.setAnnotationType(annotationTypes[index].keyName);
    }
  }

  render () {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <th>
                <span>Annotation {store.annotations.length > 0 ? store.currentAnnotationIndex + 1 : 0} of {store.annotations.length}</span>
              </th>
              <th>
                { (this.props.hasPreviousImage && store.currentAnnotationIndex <= 0) ?
                  <button className="btn btn-primary btn-sm" type="button" onClick={() => this.props.changeImage(-1)} >Previous Image</button> :
                  <button className="btn btn-primary btn-sm" type="button" onClick={this.handleClickPrevious.bind(this)} disabled={store.currentAnnotationIndex <= 0} >Previous</button>
                }
              </th>
              <th>
                { (this.props.hasNextImage && store.currentAnnotationIndex + 1 >= store.annotations.length) ?
                  <button className="btn btn-primary btn-sm" type="button" onClick={() => this.props.changeImage(1)} disabled={this.props.nextImageEnabled} >Next Image</button> :
                  <button className="btn btn-primary btn-sm" type="button" onClick={this.handleClickNext.bind(this)}
                    disabled={store.currentAnnotationIndex + 1 >= store.annotations.length || store.cleanAnnotations[store.currentAnnotationIndex + 1] === undefined} >Next</button>
                }
              </th>
            </tr>
          </tbody>
        </table>
        <hr/>
        <h1 style={{ float: "left", marginTop: -10 }} >Annotation Info</h1>
        <br/>
        <div>
          <br/>

          <div className="annotatorTypeSelectorDiv">
            <label htmlFor="annotation-type" style={{ marginRight: 5 }}>Category:</label>
            {store.currentAnnotation !== null && this.props.annotationCategorySelector && this.props.annotationCategorySelector === "dropdown" &&
              <select id="annotation-types" name="annotation-types" value={store.currentAnnotation ? store.currentAnnotation.annotation.annotationType : ""} onChange={this.setAnnotationType.bind(this)} >
                {this.props.annotationTypes[store.currentAnnotation.annotation.shape].map((at) =>
                  <option key={at.keyName} value={at.keyName} onClick={() => this.setAnnotationType(at.keyName)} >{at.friendlyName}</option>
                )}
              </select>
            }
            {store.currentAnnotation !== null && (!this.props.annotationCategorySelector || this.props.annotationCategorySelector === "buttons") &&
              this.props.annotationTypes[store.currentAnnotation.annotation.shape] &&
              this.props.annotationTypes[store.currentAnnotation.annotation.shape].map((at, i) =>
                (<button type="button" key={at.keyName}
                  className="btn btn-primary btn-xs"
                  onClick={() => this.setAnnotationType(at.keyName)}
                  disabled={store.currentAnnotation ? store.currentAnnotation.annotation.annotationType === at.keyName : "false"} >
                  {at.friendlyName + (i <= 9 ? " (" + i + ")" : "")}
                </button>)
              )
            }
          </div>
          <br/>
          <div className="notesDiv">
            <label htmlFor="notesArea">Notes:</label>
            <textarea rows="5" cols="50" id="notesArea" className="form-control" disabled={store.currentAnnotation === null} onChange={this.handleOnChangeInputText.bind(this)} value={store.currentAnnotation && store.currentAnnotation.annotation.notes ? store.currentAnnotation.annotation.notes : ""} />
          </div>
        </div>
      </div>
    );
  }
}

export default WizardControlsComponent;
