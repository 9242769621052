function getNaturalCoords (userCoords, displayImageDimensions, naturalImageDimensions) {
  return {
    x: userCoords.x ? Math.round(userCoords.x * naturalImageDimensions.width / displayImageDimensions.width) : null,
    y: userCoords.y ? Math.round(userCoords.y * naturalImageDimensions.height / displayImageDimensions.height) : null
  };
}

function getNaturalSize (userSize, displayImageDimensions, naturalImageDimensions) {
  return {
    width: Math.round(userSize.width * naturalImageDimensions.width / displayImageDimensions.width),
    height: Math.round(userSize.height * naturalImageDimensions.height / displayImageDimensions.height)
  };
}

//Calculates and returns the component (element) dimensions
function getBoundingRect (element) {
  var style = window.getComputedStyle(element);
  var margin = {
    left: parseInt(style['margin-left']),
    right: parseInt(style['margin-right']),
    top: parseInt(style['margin-top']),
    bottom: parseInt(style['margin-bottom'])
  };
  var padding = {
    left: parseInt(style['padding-left']),
    right: parseInt(style['padding-right']),
    top: parseInt(style['padding-top']),
    bottom: parseInt(style['padding-bottom'])
  };
  var border = {
    left: parseInt(style['border-left']),
    right: parseInt(style['border-right']),
    top: parseInt(style['border-top']),
    bottom: parseInt(style['border-bottom'])
  };

  var rect = element.getBoundingClientRect();
  rect = {
    left: (rect.left || 0) - (margin.left || 0),
    right: (rect.right || 0) - (margin.right || 0) - (padding.left || 0) - (padding.right || 0),
    top: (rect.top || 0) - (margin.top || 0),
    bottom: (rect.bottom || 0) - (margin.bottom || 0) - (padding.top || 0) - (padding.bottom || 0) - (border.bottom || 0)
  };
  rect.width = rect.right - rect.left;
  rect.height = rect.bottom - rect.top;
  //rect.height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - rect.top - 30; // TODO: check if there is a better approach to calculate this height for the image

  return rect;
}

function base64ImageToBinaryMatrix (img, dimensions) {
  var width = dimensions.width;
  var height = dimensions.height;
  //var resizeFactor = 2;

  // create an off-screen canvas
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  // set its dimension to target size
  canvas.width = width;
  canvas.height = height;
  // draw source image into the off-screen canvas:
  ctx.drawImage(img, 0, 0, width, height);

  // apply filter
  var data = ctx.getImageData(0, 0, width, height);
  var binaryData = [];
  var i = 0;
  var j = 0;
  binaryData[j] = [];
  while (i < data.data.length) {
    var rgb = data.data[i++] + data.data[i++] + data.data[i++];
    if (rgb < 1) {
      //data.data[i-3] = data.data[i-2] = data.data[i-1]  = 0;
      binaryData[j].push(0);
    } else {
      //data.data[i-3] = data.data[i-2] = data.data[i-1]  = 255;
      binaryData[j].push(255);
    }
    if (binaryData[j].length >= height) binaryData[++j] = [];

    //data.data[i]  = 255;
    i++;
  }
  binaryData.splice(binaryData.length - 1, 1);
  //ctx.putImageData(data,0,0);

  return binaryData;
}

function base64ImageToJpgImage (backgroundImage, img, dimensions) {
  var width = dimensions.width;
  var height = dimensions.height;

  // create an off-screen canvas
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  // set its dimension to target size
  canvas.width = width;
  canvas.height = height;
  // draw source image into the off-screen canvas:

  if (typeof backgroundImage === 'string' || backgroundImage instanceof String) {
    var background = new Image();
    background.src = backgroundImage;
    backgroundImage = background;
  }
  ctx.drawImage(backgroundImage, 0, 0, width, height);
  ctx.drawImage(img, 0, 0, width, height);

  //var file = new Blob([canvas.toDataURL("image/jpeg")], { type: "image/jpeg" });
  //window.open(canvas.toDataURL("image/jpg"), '_blank');

  return canvas.toDataURL('image/jpg');
}

// Limit "offset" to the image limits
function limitOffset (stageDimensions, userImageDimensions, offset, scale) {
  if (offset.x > 0) offset.x = 0;
  if (offset.y > 0) offset.y = 0;

  let limitOffsetX = stageDimensions.width - userImageDimensions.width * scale;
  let limitOffsetY = stageDimensions.height - userImageDimensions.height * scale;

  if (offset.x < limitOffsetX) offset.x = limitOffsetX;
  if (offset.y < limitOffsetY) offset.y = limitOffsetY;

  return offset;
}

function getPointerPositionThoughAnnotator (annotatorRef, scale, offset) {
  let coords = annotatorRef.getStage().getPointerPosition();
  return {
    x: coords.x / scale - offset.x / scale,
    y: coords.y / scale - offset.y / scale
  };
}

function groupBy (list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function isIosDevice () {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
    'MacIntel'
  ].includes(navigator.platform)
}

export { getNaturalCoords, getNaturalSize, getBoundingRect, base64ImageToBinaryMatrix, base64ImageToJpgImage, limitOffset, getPointerPositionThoughAnnotator, groupBy, isIosDevice };
