import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Circle, Group } from 'react-konva';
import { getNaturalCoords, getNaturalSize } from '../../../utils/Utils';

import appPropStore from './../../../models/app-properties-store';
import store from './../../../models/annotations-store';

@observer
class Anchors extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isDraggingAnchor: false
    };
  }

  onAnchorDragMove (e) {
    var storeAnnotation = this.props.annotation;
    let newInternalAtributes = { ...storeAnnotation.internalAttributes };
    if (e.target.attrs.id.includes("top")) {
      newInternalAtributes.height = newInternalAtributes.height + newInternalAtributes.y - e.target.attrs.y;
      newInternalAtributes.y = e.target.attrs.y;
    }
    if (e.target.attrs.id.includes("bottom")) {
      newInternalAtributes.height = e.target.attrs.y - newInternalAtributes.y;
    }
    if (e.target.attrs.id.includes("left")) {
      newInternalAtributes.width = newInternalAtributes.width + newInternalAtributes.x - e.target.attrs.x;
      newInternalAtributes.x = e.target.attrs.x;
    }
    if (e.target.attrs.id.includes("right")) {
      newInternalAtributes.width = e.target.attrs.x - newInternalAtributes.x;
    }

    let size = getNaturalSize(newInternalAtributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);
    let coords = getNaturalCoords(newInternalAtributes, appPropStore.userImageDimensions, appPropStore.naturalImageDimensions);

    storeAnnotation.setNaturalAttributes({ ...coords, ...size });

    // this.props.forceParentUpdate(); // because we dont want mobx to update the annotation because the delay can cause the dragging to stop
    //this.anchorsLayer.getLayer().batchDraw();
  }

  render () {
    const { isDraggingAnchor } = this.state;
    //{this.state.anchors.map((anchor) => anchor)}
    let positioning = this.props.annotation.internalAttributes;
    return (
      <Fragment>
        {/*Render the anchors */}
        {!appPropStore.isShiftDown &&
        <Group
          ref={instance => { this.anchorsLayer = instance; }}
          onMouseOver={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'pointer')}
          onMouseOut={() => (appPropStore.annotatorRef.imageDiv.style.cursor = 'default')}
          onDragStart={() => {this.setState({isDraggingAnchor: true});}}
          onDragMove={this.onAnchorDragMove.bind(this)}
          onDragEnd={() => {this.setState({isDraggingAnchor: false}); this.props.notifyChangesCallback('UPDATE', this.props.annotation.annotation, store.cleanAnnotations);}}
          onMouseDown={() => {this.setState({ isDraggingAnchor: true });}}
          onMouseUp={() => {this.setState({ isDraggingAnchor: false });}}
          onTouchStart={() => {this.setState({ isDraggingAnchor: true });}}
          onTouchEnd={() => {this.setState({ isDraggingAnchor: false });}}
        >
          <Circle
            x={positioning.x} y={positioning.y} radius={2 / (appPropStore.scale * 0.5)}
            fill="gray" stroke="black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false}
            id={"top-left"} key={"top-left"} strokeWidth={2 / (appPropStore.scale * 0.5)}
            draggable onDragEnd={() => {}}
          />
          <Circle
            x={positioning.x + positioning.width} y={positioning.y} radius={2 / (appPropStore.scale * 0.5)}
            fill="gray" stroke="black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false}
            id={"top-right"} key={"top-right"} strokeWidth={2 / (appPropStore.scale * 0.5)}
            draggable onDragEnd={() => {}}
          />
          <Circle
            x={positioning.x} y={positioning.y + positioning.height} radius={2 / (appPropStore.scale * 0.5)}
            fill="gray" stroke="black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false}
            id={"bottom-left"} key={"bottom-left"} strokeWidth={2 / (appPropStore.scale * 0.5)}
            draggable onDragEnd={() => {}}
          />
          <Circle
            x={positioning.x + positioning.width} y={positioning.y + positioning.height} radius={2 / (appPropStore.scale * 0.5)}
            fill="gray" stroke="black" opacity={isDraggingAnchor ? 0 : 0.2} perfectDrawEnabled={false}
            id={"bottom-right"} key={"bottom-right"} strokeWidth={2 / (appPropStore.scale * 0.5)}
            draggable onDragEnd={() => {}}
          />
        </Group>
        }
      </Fragment>
    );
  }
}

Anchors.propTypes = {
};

export default Anchors;
