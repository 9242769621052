import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import defaultTutorialConfig from './defaultConfig';

class ImageAnnotatorTutorial extends React.Component {
  constructor (props) {
    super(props);

    const allSteps = props.tutorialConfig?.steps?.length > 0 ? props.tutorialConfig.steps : defaultTutorialConfig.steps;
    let steps = !props.isWholeImageAnnotationCategoriesDivPresent ? allSteps.filter(step => step.selector !== '.wholeImageAnnotationsDiv') : allSteps;

    const styles = props.tutorialConfig?.styles || defaultTutorialConfig.styles;

    this.state = {
      steps,
      styles
    };
  }

  render () {
    const { run } = this.props;
    const { steps, styles } = this.state;

    return (
      <Joyride
        styles={styles}
        steps={steps}
        run={run}
        spotlightClicks
        showSkipButton
        showProgress
        continuous
        locale={{ back: (<span>Back</span>), close: (<span>Close</span>), last: (<span>Close</span>), next: (<span>Next</span>), skip: (<span>Skip</span>) }}
      />
    );
  }
}

ImageAnnotatorTutorial.propTypes = {
  isWholeImageAnnotationCategoriesDivPresent: PropTypes.bool,
  run: PropTypes.bool,
  tutorialConfig: PropTypes.shape({
    steps: PropTypes.array,
    styles: PropTypes.object
  })
};

export default ImageAnnotatorTutorial;
