import React, { Component } from 'react'
import { fetchUsers, getUser } from '../requests/users'
import ListingPage from '../components/ListingPage'
import Loading from '../components/Loading'
import { BASE_URL } from '../constants'
import { getAcquisitionImagesCount } from '../requests/acquisitions'

class Users extends Component {
    constructor (props) {
        super(props)
        this.state = {
            results: [],
            loading: true
        }
    }

    UNSAFE_componentWillMount () {
        let results = []
        this.setState({loading: false})
        fetchUsers().then(async(usersList) => {
            for (let i = 0; i < usersList.length; i++) {
                let user = usersList[i]
                await this.getUserAcquisitionSamplesCount(user).then(newInfo => {
                    results.push(newInfo)
                    this.setState({
                        results: [
                            ...this.state.results,
                            newInfo
                        ]
                    })
                })
            }
        })
    }

    componentWillUnmount () {
        this.setState({
            results: [],
            loading: true
        })
    }

    getUserAcquisitionSamplesCount = async (user) => {
        let resp = {
            user: {
                id: user['id'],
                firstName: user['firstName'],
                lastName: user['lastName'],
                username: user['username']
            },
            progress: undefined
        }
        let acquisitonCounter = []
        let totalSamples = 0
        let totalAnnotSamples = 0
        await getUser(user['id']).then(async (data) => {
            let acquisitions = data.user_Acquisitions
            let acqSamples = 0 
            let acqAnnotSamples = 0
            for (let i = 0; i < acquisitions.length; i++) {
                let acquisition = acquisitions[i]
                await Promise.all([
                    getAcquisitionImagesCount(acquisition['id']).then((nr) => {
                        acqSamples = nr
                        totalSamples += nr
                    }),
                    await fetch(`${BASE_URL}acquisitions/${acquisition['id']}/annotations`, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: 'Bearer ' + keycloak.token
                        }
                    })
                    .then(async (data) => data.json())
                    .then((annots) => {
                        let annotatedSamples_nr = 0
                        if (acquisition.metadata["done"] == "true") {
                            annotatedSamples_nr = acqSamples
                        } else {
                            annotatedSamples_nr = Math.max([...new Set(annots.map(el => el.annotatedSampleId))].length - 1, 0)
                        }
                        acqAnnotSamples = annotatedSamples_nr
                        totalAnnotSamples += annotatedSamples_nr
                    })
                ]).then(() => {
                    acquisitonCounter = [
                        ...acquisitonCounter,
                        {
                            id: acquisition['id'],
                            samples: acqSamples,
                            annotatedSamples: acqAnnotSamples
                        }
                    ]
                })
            }
        })
        return {
            ...resp,
            progress: {
                acquisitions: acquisitonCounter,
                total: {
                    samples: totalSamples,
                    annotatedSamples: totalAnnotSamples
                }
            }
        }
    }

    render () {
        if (this.state.loading) {
            return <Loading />
        } else {
            return (
                <ListingPage
                    resultsType={'Users'}
                    results={this.state.results}
                />
            )
        }
    }
}

export default Users 