import {BASE_URL} from '../constants/index'
import { getAllAcquisitions } from './acquisitions'


export async function getAllDatasets () {
    let datasets = []
    await fetch(BASE_URL + 'datasets/', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {
        datasets = data
    })
    return datasets
}

// newDataser = { "name": "asdsadas", "description": "" }
export async function addNewDataset (n_elem) {
    await fetch(BASE_URL + 'datasets/', {
        method: 'POST',
        body: JSON.stringify(n_elem),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        },
        cache: 'reload'
    })
    .then(data => data.json())
    .then(data => data)
    .catch(err => {
        return err
    })
}

export async function deleteDataset (datasetID) {
    let reqUrl = BASE_URL + 'datasets/' + datasetID.toString()
    await fetch(reqUrl, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then().then(data => data)
    .catch(err => {
        return err
    })
}

export async function getDataset (datasetID) {
    let dataset = {
        info: undefined,
        acquisitions: []
    }
    await Promise.all([
        await fetch(BASE_URL + `datasets/${datasetID}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + keycloak.token
            }
        })
        .then(data => data.json())
        .then(data => {
            dataset['info'] = data
        })
        .catch(err => {
            return err
        }),
        await getAllAcquisitions(datasetID)
        .then(data => {
            dataset['acquisitions'] = data
        })
    ]).then(() => dataset)
    return dataset
}
