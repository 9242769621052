import React, {} from 'react';
import { Ellipse, Group } from 'react-konva';
// /import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import appPropStore from '../../../models/app-properties-store';

@observer
class EllipseAnnotation extends React.Component {
  render () {
    let fillColor = this.props.color || (this.props.annotation && this.props.annotation.color ? this.props.annotation.color : null) || 'white';
    return (
      <Ellipse
        onDragMove={this.props.onDragMove}
        x={this.props.internalAttributes.x}
        y={this.props.internalAttributes.y}
        radius={this.props.internalAttributes.radius}
        fill={fillColor}
        draggable={this.props.draggable}
        stroke={this.props.stroke || 'black'}
        strokeWidth={1 / (appPropStore.scale * 0.5)}
        opacity={this.props.opacity || 0.3}
        id={this.props.id}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}

        dashEnabled={this.props.isSelected || false}
        dash={[5 / appPropStore.scale, 5 / appPropStore.scale]}
        fillPriority={this.props.isSelected ? 'radial-gradient' : 'color'}
        fillRadialGradientColorStops={[0, 'white', 1, fillColor]}
        fillRadialGradientEndRadius={Math.max(this.props.internalAttributes.radius.x, this.props.internalAttributes.radius.y)}
      />
    );
  }
}

//Render the list of Rects in props
function EllipseListRender (props) {
  return (
    <Group>
      {props.list.map(listValue => (
        <EllipseAnnotation
          key={`${ listValue.x }${listValue.y }${listValue.radius}`}
          x={listValue.x} y={listValue.y}
          radius={listValue.radius}
          onClick={(e) => props.onClick(e, listValue.id)}
          color={listValue.color}
          id={listValue.id}
        />
      ))}
    </Group>
  );
}

EllipseAnnotation.propTypes = {
};

export { EllipseAnnotation, EllipseListRender };
