import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import './index.css';

import store from './../../models/annotations-store';

@observer
class AnnotationsResume extends Component {
  render() {
    const { annotations, annotationCategories } = store;

    const annotationsCatCount = annotations.filter(ant => ant.annotation.annotationType)
      .reduce((a, ant) => {
        const antT = ant.annotation.annotationType
        a[antT] = {
          count: a[antT] ? a[antT].count + 1 : 1,
          isHidden: a[antT] ? a[antT].isHidden || ant.isHidden : ant.isHidden,
          annotations: a[antT] ? [...a[antT].annotations, ant] : [ant]
        }
        return a;
      }, {});
    const annotationsCatResume = annotationCategories.filter(antC => annotationsCatCount[antC.keyName]);

    return (
      <div className="annotationsResumeDiv" id={'annotationsResumeDiv'}>
          <h1 className={'annotationsResumeTitle'} />
          {annotationsCatResume.length > 0 ?
            <ul className="annotationsResumeList">
              {annotationsCatResume.map(annotationCatResume => (
                <li key={annotationCatResume.keyName} style={{ marginLeft: 0, border: 5, borderLeftStyle: "solid", borderLeftColor: annotationCatResume.color }} className="radio">
                  <label style={{ fontWeight: "bold", paddingLeft: 5 }} htmlFor={annotationCatResume.keyName}
                    onClick={() => store.setHiddenCategory(annotationCatResume.keyName, !annotationsCatCount[annotationCatResume.keyName].isHidden)}
                  >
                    {annotationsCatCount[annotationCatResume.keyName].isHidden ?
                      <BsFillEyeSlashFill /> : <BsFillEyeFill />
                    }
                    {` ${annotationsCatCount[annotationCatResume.keyName].count}x ${annotationCatResume.friendlyName}`}
                  </label>
                  <ul>
                    {annotationsCatCount[annotationCatResume.keyName].annotations.map((ant, i) =>
                      <li key={i} className="radio clickable">
                        <span style={{}}
                          onClick={() => ant.toggleHideAnnotation()}
                          onMouseEnter={() => ant.toggleSelected()}
                          onMouseLeave={() => ant.toggleSelected()}
                        >
                          {ant.isHidden ?
                            <BsFillEyeSlashFill /> : <BsFillEyeFill />
                          }
                          {" " + ant.annotation.shape + (ant.annotation.notes ? " - " + ant.annotation.notes : "")}
                        </span>
                      </li>
                    )}
                  </ul>
                </li>))
              }
            </ul>
            :
            <span className="annotationsResumePlaceHolder">No annotations</span>
          }
      </div>
    );
  }
}

export default AnnotationsResume;
