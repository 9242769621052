import React from 'react';
import Popup from 'react-popup';
import AnnotationsPrompt from './AnnotationsPrompt';
import './annotationPrompt.css';

/** Additional Annotation Info Prompt plugin **/
Popup.registerPlugin('updateAnnotationInfo', function (position, annotationTypes, annotation, callbackSave, callbackClose) {
  //Find the default annotation type
  let defaultChecked = annotationTypes.filter(obj => obj.keyName === annotation.annotation.annotationType)[0];
  if (!defaultChecked && annotationTypes.length > 0) {
    annotationTypes[0]['defaultChecked'] = true;
    defaultChecked = annotationTypes[0];
  }

  let noteValue = annotation.annotation.notes || "";
  let promptValue = noteValue;
  let annotationType = defaultChecked;

  let promptChange = function (noteValue, annotationValue) {
    promptValue = noteValue;
    annotationType = annotationValue;
  };

  let buttons = {
    left: [{
      text: 'Cancel',
      action: function () {
        typeof callbackClose === 'function' && callbackClose();
        Popup.clearQueue();
        Popup.close();
      }
    }],
    right: [{
      text: 'Update',
      className: 'success',
      action: function () {
        if (annotationType == null) {window.alert("Choose a category to create the annotation"); return; }
        if (annotationType.notesRequired && promptValue === "") {window.alert("Write a note first"); return; }
        typeof callbackClose === 'function' && callbackClose();
        typeof callbackSave === 'function' && callbackSave(promptValue, annotationType.keyName);
        Popup.clearQueue();
        Popup.close();
      }
    }]
  };

  this.create({
    title: 'Anotação da Imagem',
    closeOnOutsideClick: false, // Should a click outside the popup close it? (default is closeOnOutsideClick property on the component)
    noOverlay: false, // hide overlay layer (default is false, overlay visible),
    content: <AnnotationsPrompt onChange={promptChange} annotationTypes={annotationTypes} selectedType={defaultChecked ? defaultChecked : null} annotation={annotation} />,
    buttons: buttons,
    position: function (box) {
      box.style.top = position.top + 'px';
      box.style.left = position.left + 'px';
      box.style.margin = 0;
      box.style.opacity = 1;
    }
  });
});

/** Additional Annotation Info Prompt plugin **/
Popup.registerPlugin('multipleAnnotationsSelected', function (position, annotationTypes, callbackSave, callbackDelete, callbackClose) {
  let noteValue = "";
  let promptValue = noteValue;
  let annotationType = null;

  let promptChange = function (noteValue, annotationValue) {
    promptValue = noteValue;
    annotationType = annotationValue;
  };

  let buttons = {
    left: [{
      text: 'Cancel',
      action: function () {
        typeof callbackClose === 'function' && callbackClose();
        Popup.clearQueue();
        Popup.close();
      }
    }],
    right: [
      {
        text: 'Delete All',
        className: 'danger',
        action: function () {
          typeof callbackClose === 'function' && callbackClose();
          typeof callbackDelete === 'function' && callbackDelete();
          Popup.clearQueue();
          Popup.close();
        }
      },
      {
        text: 'Update All',
        className: 'success',
        action: function () {
          typeof callbackClose === 'function' && callbackClose();
          typeof callbackSave === 'function' && callbackSave(promptValue, annotationType ? annotationType.keyName : null);
          Popup.clearQueue();
          Popup.close();
        }
      }
    ]
  };

  this.create({
    title: 'Anotação da Imagem',
    closeOnOutsideClick: false, // Should a click outside the popup close it? (default is closeOnOutsideClick property on the component)
    noOverlay: false, // hide overlay layer (default is false, overlay visible),
    content: <AnnotationsPrompt onChange={promptChange} annotationTypes={annotationTypes} selectedType={null} />,
    buttons: buttons,
    position: function (box) {
      box.style.top = position.top + 'px';
      box.style.left = position.left + 'px';
      box.style.margin = 0;
      box.style.opacity = 1;
    }
  });
});
