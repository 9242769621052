import React, {} from 'react';
import { Circle } from 'react-konva';
// /import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import appPropStore from './../../../models/app-properties-store';

@observer
class PointAnnotation extends React.Component {
  render () {
    let fillColor = this.props.color || (this.props.annotation && this.props.annotation.color ? this.props.annotation.color : null) || 'white';
    return (
      <Circle
        onDragMove={this.props.onDragMove}
        x={this.props.internalAttributes.x}
        y={this.props.internalAttributes.y}
        radius={appPropStore.dotSize / appPropStore.scale}
        fill={fillColor}
        draggable={this.props.draggable}
        stroke={this.props.stroke || 'black'}
        strokeWidth={1 / (appPropStore.scale * 0.5)}
        opacity={this.props.opacity || 0.3}
        id={this.props.id}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}

        dashEnabled={this.props.isSelected || false}
        dash={[5 / appPropStore.scale, 5 / appPropStore.scale]}
        fillPriority={this.props.isSelected ? 'radial-gradient' : 'color'}
        fillRadialGradientColorStops={[0, 'white', 1, fillColor]}
        fillRadialGradientEndRadius={appPropStore.dotSize / appPropStore.scale}
      />
    );
  }
}

PointAnnotation.propTypes = {
};

export { PointAnnotation };
