import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import AnnotationFactory from './components/annotations/AnnotationFactory';
import Annotator from './components/Annotator';
import ZoomComponent from './components/ZoomComponent';
import WizardControlsComponent from './components/WizardControlsComponent';
import WholeImageAnnotations from './components/WholeImageAnnotations';
import AnnotationsResume from './components/AnnotationsResume';
import AnnotationsPresets from './components/AnnotationsPresets';
import ImageAnnotatorTutorial from './components/tutorial/';
import Popup from 'react-popup';
import { BsX } from 'react-icons/bs';
import { Annotation } from './models/annotations-store';
import { limitOffset } from './utils/Utils';
import './defaultStyles.css';

import { observer } from 'mobx-react';
import dataStore from './models/data-store';
import store from './models/annotations-store';
import appPropStore from './models/app-properties-store';
import imageToolsStore from './models/image-tools-store';
import ImageHeader, { headerTypes } from './sections/ImageHeader';
import ImageTools from './components/annotations/ImageTools/ImageTools';
import ZoomToolButtons from './components/ZoomToolButtons';


// // mobx debug
// import { enableLogging } from 'mobx-logger';
// const config = {
//   predicate: () => true,
//   action: true,
//   reaction: false,
//   transaction: true,
//   compute: true
// };
// enableLogging(config);

@observer
class ImageAnnotator extends Component {
  static propTypes = {
    /** Data **/
    /** @type {array} data is an array of objects that contain an image path with its own annotations **/
    data: PropTypes.array.isRequired,
    dataLength: PropTypes.number,
    annotationShapes: PropTypes.array,
    annotationCategories: PropTypes.array,
    wholeImageAnnotationCategories: PropTypes.array,

    /** Options **/
    /** @type {string} the size of the dot annotation */
    dotSize: PropTypes.number,
    drawingEnabled: PropTypes.bool,
    editEnabled: PropTypes.bool,
    imageToolsEnabled: PropTypes.bool,
    showAnnotationsPresets: PropTypes.bool,
    allowNewCategories: PropTypes.bool,
    allowNewImageCategories: PropTypes.bool,
    createImageCategoryAnnotationOnZeroAnnotations: PropTypes.bool,
    tutorialEnabled: PropTypes.bool,
    dontUseExifOrientation: PropTypes.bool,
    showAnnotationsResume: PropTypes.bool,
    enableHeader: PropTypes.oneOf(headerTypes),
    allowImageChange: PropTypes.bool,
    enabledNextImage: PropTypes.bool,
    nextImageTooltip: PropTypes.string,
    zoomAmountFromButtons: PropTypes.number,
    tutorialConfig: PropTypes.shape({
      steps: PropTypes.array,
      styles: PropTypes.object
    }),

    /** Functions and Callbacks **/
    /** @type {functions} it's called every time an annotation is edited/created/deleted */
    notifyChangesCallback: PropTypes.func,
    onImageChanged: PropTypes.func,
    onAnnotationChanged: PropTypes.func,
    onFinish: PropTypes.func,
    getImageInfo: PropTypes.func, // Callback used to get more data of an image (when using pagination)
    getNextAnnotationsPage: PropTypes.func, // Called when reached the end of the annotations page (when using pagination)
    allowWholeImageAnnotationCreation: PropTypes.func, // Called before creating a WholeImageAnnotation to check if it's allowed or not (should return a boolean)
    allowWholeImageAnnotationDeletion: PropTypes.func, // Called before deleting a WholeImageAnnotation to check if it's allowed or not (should return a boolean)
    shouldDisablePresetAnnotationsGroupFunc: PropTypes.func,

    /** Save on image change options **/
    askToSaveOnImageChange: PropTypes.bool, // saveAnnotationsCallback
    askToSaveCallback: PropTypes.func, //askToSaveOnImageChange
    askToSaveOnImageChangeTitle: PropTypes.string,
    askToSaveOnImageChangeDescription: PropTypes.string,

    /** Wizard Options **/
    wizardMode: PropTypes.bool,
    autoSkipAnnotation: PropTypes.bool,
    annotationCategorySelector: PropTypes.oneOf(['buttons', 'dropdown']),
    focusOnAnnotation: PropTypes.bool,
    focusAnnotationFillRatio: requiredFocusProps,
    focusPointZoomAmount: requiredFocusProps,
    forceAnnotationFocus: PropTypes.bool
  }

  constructor (props) {
    super(props);

    // Put props values on store and fill the rest of the array (filling is needed on pagination, when reviving data for images on other indexes that not exist yet)
    dataStore.setImagesData(props.dataLength ? [...props.data, ...Array(Math.max(props.dataLength - props.data.length, 0)).fill({})] : props.data);

    // Reserve the spaces for the paginated annotations on the first image (fill those spaces with an empty object '{}')
    this.preReservePaginatedAnnotations(0);

    appPropStore.setActiveTool('PolygonAnnotator');
    appPropStore.setDotSize(Number.isInteger(props.dotSize) ? props.dotSize : 10);
    appPropStore.setDrawingEnabled(typeof (props.drawingEnabled) === 'boolean' ? props.drawingEnabled : true);
    appPropStore.setWizardMode(typeof (props.wizardMode) === 'boolean' ? props.wizardMode : false);
    appPropStore.setEditEnabled(typeof (props.editEnabled) === 'boolean' ? props.editEnabled : true);
    appPropStore.setAllowNewCategories(typeof (props.allowNewCategories) === 'boolean' ? props.allowNewCategories : false);
    appPropStore.setdontUseExifOrientation(typeof (props.dontUseExifOrientation) === 'boolean' ? props.dontUseExifOrientation : false);
    appPropStore.setAllowImageChange(typeof (props.allowImageChange) === 'boolean' ? props.allowImageChange : true);
    appPropStore.setEnabledNextImage(typeof (props.enabledNextImage) === 'boolean' ? props.enabledNextImage : true);
    store.setAnnotationCategories(this.props.annotationCategories ? this.props.annotationCategories : []);
    imageToolsStore.setImageToolsEnabled(typeof (props.imageToolsEnabled) === 'boolean' ? props.imageToolsEnabled : false);

    if (props.wizardMode) store.setCurrentAnnotationIndex(0);

    // Bind functions
    this.handleComponentResize = this.handleComponentResize.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.zoomComponentRef = createRef();
  }

  //Add event listener
  componentDidMount () {
    window.addEventListener('resize', this.handleComponentResize);
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
    document.addEventListener('visibilitychange', this.onBlur);
  }

  //Remove event listener
  componentWillUnmount () {
    window.removeEventListener('resize', this.handleComponentResize);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('visibilitychange', this.onBlur);

    // This will prevent some store values to me maintained on the next instance
    // this may happen because we are using the parents mobx instance
    appPropStore.reset();
    store.reset();
    imageToolsStore.reset();
    dataStore.reset();
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (props.wizardMode !== appPropStore.wizardMode) { if (!props.wizardMode) {store.setCurrentAnnotationIndex(-1);} else {store.setCurrentAnnotationIndex(0);} } //TODO find a better approach (this was placed so the single previous annotation is not displayed)

    appPropStore.setDotSize(Number.isInteger(props.dotSize) ? props.dotSize : 10);
    appPropStore.setDrawingEnabled(typeof (props.drawingEnabled) === 'boolean' ? props.drawingEnabled : true);
    appPropStore.setWizardMode(typeof (props.wizardMode) === 'boolean' ? props.wizardMode : false);
    appPropStore.setEditEnabled(typeof (props.editEnabled) === 'boolean' ? props.editEnabled : true);
    appPropStore.setAllowImageChange(typeof (props.allowImageChange) === 'boolean' ? props.allowImageChange : true);
    appPropStore.setEnabledNextImage(typeof (props.enabledNextImage) === 'boolean' ? props.enabledNextImage : true);
    imageToolsStore.setImageToolsEnabled(typeof (props.imageToolsEnabled) === 'boolean' ? props.imageToolsEnabled : false);

    if (this.props.data !== props.data) {
      dataStore.setImagesData(props.dataLength ? [...props.data, ...Array(Math.max(props.dataLength - props.data.length, 0)).fill({})] : props.data);
      this.forceUpdate();
    }
  }

  onBlur () {
    appPropStore.setIsShiftDown(false);
    appPropStore.setIsDrawingKeyDown(false);
  }

  handleComponentResize () {
    if (document.querySelector('.mm-popup__input') !== null) return;
    this.annotator.handleComponentResize();
    if (appPropStore.wizardMode && this.props.focusOnAnnotation) this.focusOnAnnotation(appPropStore.scale);
  }
  handleKeyDown (e) {
    if (document.querySelector('.mm-popup--visible') !== null) return;
    if (this.isDrawingKey(e.key)) {
      this.handleDrawingKeyDown(e);
    } else if (e.key === 'Shift') {
      this.handleShiftKeyDown(e);
    } else if (!isNaN(e.key) && appPropStore.wizardMode && e.target.type !== 'textarea') {
      this.WizardControlsComponent.selectAnnotationCategoryByIndex(parseInt(e.key, 10));
    } else if (e.key === 'ArrowRight' && e.target.type !== 'textarea' && /*document.getElementsByClassName("mm-popup__box").length === 0 &&*/ this.isNextImageAvailable()) {
      this.changeImage.bind(this)(1);
    } else if (e.key === 'ArrowLeft' && e.target.type !== 'textarea' && /*document.getElementsByClassName("mm-popup__box").length === 0 &&*/ this.isPreviousImageAvailable()) {
      this.changeImage.bind(this)(-1);
    }
  }
  handleKeyUp (e) {
    if (this.isDrawingKey(e.key)) {
      this.handleDrawingKeyUp();
    } else if (e.key === 'Shift') {
      this.handleShiftKeyUp();
    }
  }

  handleDrawingKeyDown (e) {
    this.annotator.handleDrawingKeyDown(e);
    appPropStore.setIsDrawingKeyDown(true);
    appPropStore.setIsShiftDown(false);
  }
  handleDrawingKeyUp (e) {
    this.annotator.handleDrawingKeyUp(e);
    appPropStore.setIsDrawingKeyDown(false);
  }
  handleShiftKeyDown (e) {
    appPropStore.setIsShiftDown(true);
    appPropStore.setIsDrawingKeyDown(false);
  }
  handleShiftKeyUp (e) {
    appPropStore.setIsShiftDown(false);
  }

  isDrawingKey (key) {
    var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0; // check if it is a mac device
    return (!isMac && key === 'Control') || (isMac && key === 'Meta');
  }

  setAnnotationShape (e) {
    let selectedTool = e.target.value;
    if (selectedTool === 'DeleteTool') {
      appPropStore.setIsDrawingKeyDown(false);
      if (selectedTool === appPropStore.activeTool) appPropStore.setIsShiftDown(!appPropStore.isShiftDown);
    } else {
      appPropStore.setIsShiftDown(false);
      if (selectedTool === appPropStore.activeTool) {
        appPropStore.isDrawingKeyDown ? this.handleDrawingKeyUp(e) : this.handleDrawingKeyDown(e);
      } else this.handleDrawingKeyUp(e);
    }
    appPropStore.setActiveTool(selectedTool);
  }

  notifyBulkChangesCallback = (action, annotations, resultingList, internalAnnotations) => {
    dataStore.setImageDataElementAttribute(dataStore.currentImageIndex, 'annotations', store.cleanAnnotations);
    switch (action) {
    case 'DELETE':
      dataStore.setImageDataElementAttribute(dataStore.currentImageIndex, 'deletedAnnotations', [...(dataStore.currentImageData.deletedAnnotations || []), ...annotations]);
      break;
    }

    // This cb will update the annotation (useful for backends that associates an id to the annotation)
    const updateAnnotationCallback = action === 'DELETE' ? null : this.updateAnnotationCallback;
    if (this.props.notifyChangesCallback) this.props.notifyChangesCallback({ action, annotations, resultingList, internalAnnotations }, updateAnnotationCallback);
  }
  updateAnnotationCallback = (internalAnnotations, updatedAnnotations) => {
    store.updateInternalAnnotations(internalAnnotations, updatedAnnotations);
    dataStore.setImageDataElementAttribute(dataStore.currentImageIndex, 'annotations', store.cleanAnnotations);
  };
  /**
   * TO BE DEPRECATED
   * substitute by notifyBulkChangesCallback
   */
  notifyChangesCallback (action, annotation, resultingList, internalAnnotation) {
    this.notifyBulkChangesCallback(action, [annotation], resultingList, [internalAnnotation]);
  }

  imageChangeCallback () {
    if (this.props.wizardMode && this.props.focusOnAnnotation) {this.focusOnAnnotation();}
    if (this.props.onImageChanged) this.props.onImageChanged(dataStore.currentImageIndex);
    dataStore.setIsLoadingImage(false);
  }
  annotationChangeCallback () {
    if (this.props.wizardMode && this.props.focusOnAnnotation) {this.focusOnAnnotation();}
    if (this.props.onAnnotationChanged) this.props.onAnnotationChanged(store.currentAnnotationIndex);
    if (this.props.getNextAnnotationsPage &&
      store.currentAnnotationIndex + 1 < dataStore.currentImageData.annotationsCount &&
      dataStore.currentImageData.annotations[store.currentAnnotationIndex + 1] !== undefined &&
      Object.keys(dataStore.currentImageData.annotations[store.currentAnnotationIndex + 1]).length === 0
    ) { this.props.getNextAnnotationsPage(dataStore.currentImageIndex); } // Call the function getNextAnnotationsPage() to ask for the next annotations page
  }

  validateImageChange (indexChangeStep, callback) {
    if (indexChangeStep < 0) {
      callback();
      return;
    }

    // Validations & callbacks
    if (this.props.createImageCategoryAnnotationOnZeroAnnotations && !store.annotations.length) {
      Popup.plugins().promptConfirmSaveImageWithNoAnnotations(() => this.addImageCategoryAnnotation.bind(this)('nothing'), callback);
    } else if (this.props.askToSaveOnImageChange) {
      Popup.plugins().promptConfirmSaveAnnotations(
        () => this.props.askToSaveCallback(dataStore.currentImageIndex, store.cleanAnnotations, callback),
        callback,
        this.props.askToSaveOnImageChangeTitle,
        this.props.askToSaveOnImageChangeDescription
      );
    }
    // If none of the above just change the image
    if (!(this.props.createImageCategoryAnnotationOnZeroAnnotations && !store.annotations.length) && !this.props.askToSaveOnImageChange) {
      callback();
    }
  }

  changeImage (indexChangeStep) {
    let changeImage = () => {
      let newImageIndex = dataStore.currentImageIndex + indexChangeStep;

      // Reserve the spaces for the paginated annotations (fill those spaces with an empty object '{}')
      this.preReservePaginatedAnnotations(newImageIndex);

      store.setCurrentAnnotationIndex(0);
      store.setPresetAnnotationsGroupEnabledForPreviewFromSource(null);
      dataStore.setCurrentImageIndex(newImageIndex);

      appPropStore.setScale(1);
      appPropStore.setOffset({ x: 0, y: 0 });

      // Check if is needed the information for "newImageIndex + 1" image
      if (this.props.getImageInfo && dataStore.imagesData[newImageIndex + 1] !== undefined && !dataStore.imagesData[newImageIndex + 1].imagePath) {this.props.getImageInfo(newImageIndex + 1);}
    };

    this.validateImageChange(indexChangeStep, changeImage);
  }

  isNextImageAvailable () {
    return appPropStore.enabledNextImage && appPropStore.allowImageChange &&
    !dataStore.isLoadingImage &&
    (dataStore.currentImageIndex + 1 < this.props.data.length) &&
    (dataStore.currentImageIndex + 1 < dataStore.imagesData.length) &&
    (dataStore.imagesData[dataStore.currentImageIndex + 1].imagePath) &&
    (dataStore.imagesData[dataStore.currentImageIndex + 1].imagePath !== '') &&
    appPropStore.preloadedImage === dataStore.imagesData[dataStore.currentImageIndex + 1].imagePath;
  }

  isPreviousImageAvailable () {
    return appPropStore.allowImageChange && !dataStore.isLoadingImage && dataStore.currentImageIndex > 0;
  }

  focusOnAnnotation (scale = null) {
    // Restart scale and offset if there are no more annotations
    if (store.currentAnnotation === null) {
      appPropStore.setOffset({ x: 0, y: 0 });
      appPropStore.setScale(1);
      return;
    }

    // Focus on annotation (on the wizard mode)
    let focusValues = AnnotationFactory.calculateZoomAndScaleForAnnotationFocus(
      store.currentAnnotation,
      appPropStore.annotatorBoundingRect,
      this.props.focusAnnotationFillRatio,
      this.props.focusPointZoomAmount || appPropStore.dotSize,
      scale
    );
    appPropStore.setScale(scale ? scale : focusValues.scale);

    focusValues.offset.x *= -1;
    focusValues.offset.y *= -1;
    let newOffset = limitOffset(appPropStore.stageDimensions, appPropStore.userImageDimensions, focusValues.offset, appPropStore.scale);
    appPropStore.setOffset(this.props.forceAnnotationFocus ? focusValues.offset : newOffset);
  }

  /*
  * Reserve the spaces for the paginated annotations (fill those spaces with an empty object '{}')
  * - This will allow the counter on wizard to display the proper value,
  *   also, when adding a new annotations, it goes to the end of the list
  */
  preReservePaginatedAnnotations (imageIndex) {
    if (dataStore.imagesData[imageIndex] !== undefined || dataStore.imagesData[imageIndex].annotationsCount && !dataStore.imagesData[imageIndex]['loaded']) {
      for (let j = dataStore.imagesData[imageIndex].annotations.length; j < dataStore.imagesData[imageIndex].annotationsCount; j++) {
        dataStore.setImageDataElementAttribute(imageIndex, 'annotations', [...dataStore.imagesData[imageIndex].annotations, {}]);
      }
      dataStore.setImageDataElementAttribute(imageIndex, 'loaded', true); // So we know that the paginated spaces are already reserved
    }
  }

  /* FUNCTIONS FOR PARENT TO CALL */
  getCurrentImageIndex () { return dataStore.currentImageIndex; }
  getBinaryMatrix (callback) { return this.annotator.getBinaryMatrix(callback); }
  getJpgImage (callback, width, height) { return this.annotator.getJpgImage(callback, width, height); }
  getFullJpgImage (callback) { return this.annotator.getFullJpgImage(callback); }
  getNaturalImageDimensions () { return appPropStore.naturalImageDimensions; }
  getUserImageDimensions () { return appPropStore.userImageDimensions; }
  getStageDimensions () { return appPropStore.stageDimensions; }
  updateScale (zoomIn = true, zoomAmount = 1.2) { this.zoomComponentRef.current.updateScale(zoomIn, zoomAmount); }
  updateOffset (directionX = 0, directionY = 0) { this.zoomComponentRef.current.updateOffset(directionX, directionY); }
  getAnnotations () {
    // Filer in case the annotation was not set (when using pagination)
    let cleanData = JSON.parse(JSON.stringify(dataStore.imagesData));
    cleanData = cleanData.filter(image => (image !== null && image !== undefined && image.hasOwnProperty('imagePath'))); // Clean the unloaded images
    for (let i = 0; i < cleanData.length; i++) { // Clean the unloaded annotations
      if (cleanData[i].annotations) cleanData[i].annotations = cleanData[i].annotations.filter(annotation => Object.keys(annotation).length !== 0);
    }
    return cleanData;
  }
  updateAnnotations (newAnnotations) { /* Updates the annotations with new ones */
    if (newAnnotations) {
      dataStore.setImageDataElementAttribute(dataStore.currentImageIndex, 'annotations', newAnnotations);
      store.setAnnotationsFromSource(newAnnotations);
    } else {
      dataStore.setImagesData(this.props.dataLength ? [...this.props.data, ...Array(Math.max(this.props.dataLength - this.props.data.length, 0)).fill({})] : this.props.data);
      store.setAnnotationsFromSource(dataStore.currentImageData.annotations);
    }
  }
  addNewAnnotation (annotation) {
    //Create the annotation object for the store
    let annt = new Annotation();
    annt.annotation = annotation.annotation;
    store.pushAnnotation(annt);
    //this.forceUpdate();
  }
  addPaginatedAnnotation (imageIndex, annotation) {
    //Create the annotation object for the store
    if (dataStore.currentImageIndex === imageIndex) {
      store.addPaginatedAnnotation(annotation);
      dataStore.setImageDataElementAttribute(imageIndex, 'annotations', store.cleanAnnotations);
    } else {
      let first = dataStore.imagesData[imageIndex].annotations.findIndex(function (element) { return Object.keys(element).length === 0; });
      dataStore.imagesData[imageIndex].annotations[first] = annotation; // TODO: Warning: change not using store?
    }
  }
  addPaginatedImage (imageIndex, image, preReservePaginatedAnnotations = false) {
    // Reserve the spaces for the paginated annotations (fill those spaces with an empty object '{}')
    dataStore.setImageDataElement(imageIndex, image);
    // Reserve the spaces for the paginated annotations on the received image (fill those spaces with an empty object '{}')
    if (preReservePaginatedAnnotations) this.preReservePaginatedAnnotations(imageIndex);
    this.forceUpdate();
  }
  /* END FUNCTIONS FOR PARENT TO CALL */

  addImageCategoryAnnotation (annotationType) {
    let annotation = {
      shape: 'IMAGECATEGORY',
      annotationType: annotationType
    };
    //Create the annotation object for the store
    let annt = new Annotation();
    annt.annotation = annotation;
    store.pushAnnotation(annt);
    this.notifyChangesCallback('ADD', annt.annotation, store.cleanAnnotations, annt);
  }
  removeImageCategoryAnnotation (annotationType) {
    let annotation = store.annotations.filter(e => e.annotation.annotationType === annotationType);
    if (annotation.length > 0) {
      let removedAnnotation = store.removeAnnotationById(annotation[0].id);
      if (removedAnnotation) this.notifyChangesCallback('DELETE', removedAnnotation, store.cleanAnnotations);
    }
  }

  render () {
    return (
      <div className="AppAnnotationCreator" >
        {this.props.tutorialEnabled && <ImageAnnotatorTutorial run={this.props.tutorialEnabled} isWholeImageAnnotationCategoriesDivPresent={this.props.wholeImageAnnotationCategories !== undefined} tutorialConfig={this.props.tutorialConfig}/> }
        {this.props.enableHeader && <ImageHeader headerType={this.props.enableHeader} data={this.props.data} currentImageIndex={dataStore.currentImageIndex} />}

        {appPropStore.isDrawingEnabled &&
        <div className="well shapes-radio toolsPanel" id={'toolsPanel'}>
          <div style={{ fontSize: 'x-small', marginTop: '10px', marginBottom: '10px' }}><b style={{ fontSize: 'x-small' }}>Draw</b></div>
          <div>
            { AnnotationFactory.createAnnotationShapesSelectors(this.props.annotationShapes, this.setAnnotationShape.bind(this), true, appPropStore.activeTool, appPropStore.isDrawingKeyDown, appPropStore.isShiftDown) }
            <label htmlFor="DeleteTool" className={`btn ${appPropStore.activeTool === 'DeleteTool' || appPropStore.isShiftDown ? 'btn-primary' : 'btn-default'} btn-sm`} title="Delete Tool" style={{ opacity: appPropStore.isShiftDown ? null : 0.4 }}>
              <input id="DeleteTool" className="form-control" type="radio" value="DeleteTool" name={appPropStore.activeTool === 'DeleteTool' ? 'annotationShape' : ''} onClick={this.setAnnotationShape.bind(this)} readOnly checked={appPropStore.activeTool === 'DeleteTool' || appPropStore.isShiftDown} />
              <BsX color={'red'} />
            </label>
          </div>
          {imageToolsStore.imageToolsEnabled && <ImageTools/>}
          <ZoomToolButtons zoomComponentRef={this.zoomComponentRef} zoomAmount={this.props.zoomAmountFromButtons}/>
        </div>
        }

        <Fragment>
          {(this.props.showAnnotationsResume || this.props.showAnnotationsPresets) &&
          <div className="annotationsResumeAndPresetDiv well">
            { this.props.showAnnotationsResume && <AnnotationsResume /> }
            { this.props.showAnnotationsPresets && <AnnotationsPresets notifyChangesCallback={this.notifyBulkChangesCallback} shouldDisablePresetAnnotationsGroupFunc={this.props.shouldDisablePresetAnnotationsGroupFunc} /> }
          </div>
          }
          { this.props.wholeImageAnnotationCategories &&
          <WholeImageAnnotations
            categories={[...this.props.wholeImageAnnotationCategories, ...store.imageAnnotationCategories]}
            allowNewImageCategories={this.props.allowNewImageCategories}
            addNewAnnotationCategory={cat => store.pushImageAnnotationCategory(cat)}
            addAnnotationCallback={this.addImageCategoryAnnotation.bind(this)}
            removeAnnotationCallback={this.removeImageCategoryAnnotation.bind(this)}

            allowWholeImageAnnotationCreation={this.props.allowWholeImageAnnotationCreation}
            allowWholeImageAnnotationDeletion={this.props.allowWholeImageAnnotationDeletion}
          />
          }
          <ZoomComponent ref={this.zoomComponentRef} >
            <div>
              <Annotator
                ref={instance => { this.annotator = instance; appPropStore.setAnnotatorRef(instance); }}
                annotations={dataStore.currentImageData.annotations}
                updateWizard={() => (this.WizardControlsComponent.forceUpdate())}
                childImagePath={dataStore.currentImageData.imagePath}
                subsequentImageSrc={(dataStore.currentImageIndex + 1) < dataStore.imagesData.length ? dataStore.imagesData[dataStore.currentImageIndex + 1].imagePath : null}
                annotationTypes={dataStore.currentImageData.annotationTypes ? dataStore.currentImageData.annotationTypes : store.annotationCategories}
                annotationShape={appPropStore.activeTool}
                notifyChangesCallback={this.notifyChangesCallback.bind(this)}
                notifyBulkChangesCallback={this.notifyBulkChangesCallback}
                onImageChanged={this.imageChangeCallback.bind(this)}
                onAnnotationChanged={this.annotationChangeCallback.bind(this)}
              />
            </div>
          </ZoomComponent>
        </Fragment>
        <div className="annotatorControls well">
          <table>
            <tbody>
              <tr>
                <th>
                  <span style={{ marginRight: 5 }}>Image {dataStore.imagesData.length > 0 ? dataStore.currentImageIndex + 1 : 0} of {this.props.dataLength || dataStore.imagesData.length}</span>
                </th>
                {dataStore.isLoadingImage ?
                  <th style={{ width: 266, height: 27 }}><span>Loading image...</span></th> :
                  <>
                    <th>
                      { dataStore.imagesData.length > 1 &&
                    <button className="btn btn-primary  btn-sm" onClick={() => this.changeImage.bind(this)(-1)} disabled={!this.isPreviousImageAvailable()} >&larr; Previous Image</button>
                      }
                    </th>
                    <th>
                      {!(dataStore.currentImageIndex + 1 >= (this.props.dataLength || this.props.data.length)) &&
                  <button className="btn btn-primary  btn-sm"
                    title={!appPropStore.enabledNextImage ? this.props.nextImageTooltip : ''}
                    alt={!appPropStore.enabledNextImage ? this.props.nextImageTooltip : ''}
                    onClick={() => this.changeImage.bind(this)(1)}
                    disabled={!this.isNextImageAvailable()} >Next Image &rarr;</button>
                      }
                      {(dataStore.currentImageIndex + 1 >= (this.props.dataLength || this.props.data.length)) &&
                    <button className="btn btn-primary  btn-sm" onClick={() => {
                      if (this.props.onFinish) this.validateImageChange(0, this.props.onFinish);
                    }
                    } >Finish &#10004;</button>
                      }
                    </th>
                  </>}
              </tr>
            </tbody>
          </table>

          {/*controls TODO: improve the props? */}
          {appPropStore.wizardMode &&
          <WizardControlsComponent
            ref={instance => { this.WizardControlsComponent = instance; }}
            annotationTypes={dataStore.currentImageData.annotationTypes ? dataStore.currentImageData.annotationTypes : store.annotationCategories}
            notifyChangesCallback={this.notifyChangesCallback.bind(this)}
            autoSkipAnnotation={this.props.autoSkipAnnotation}
            annotationCategorySelector={this.props.annotationCategorySelector}
            hasPreviousImage={dataStore.currentImageIndex > 0} hasNextImage={dataStore.currentImageIndex + 1 < this.props.data.length}
            changeImage={(position) => { this.changeImage.bind(this)(position); }}
            onAnnotationChanged = {this.annotationChangeCallback.bind(this)}
            nextImageEnabled={
              (dataStore.currentImageIndex + 1 >= this.props.data.length) ||
               dataStore.imagesData[dataStore.currentImageIndex + 1].imagePath === null ||
               dataStore.imagesData[dataStore.currentImageIndex + 1].imagePath === undefined ||
               dataStore.imagesData[dataStore.currentImageIndex + 1].imagePath === ''
            }
          />
          }
        </div>

        {/*<DevTools />*/}
      </div>
    );
  }
}

/*
 * Custom prop validator for focusAnnotationFillRatio && focusPointZoomAmount
 * Both this fields are required when using focusOnAnnotation
*/
function requiredFocusProps (props, propName, componentName) {
  function checkDataOrRequest () {
    return props.hasOwnProperty('focusOnAnnotation') && (!props.hasOwnProperty('focusAnnotationFillRatio') ||
    !props.hasOwnProperty('focusPointZoomAmount')) &&
    new Error('Properties "focusAnnotationFillRatio" and "focusPointZoomAmount" are required');
  }

  function checkTypes () {
    if (/*(propName === 'focusAnnotationFillRatio' && !isNaN(props.focusAnnotationFillRatio)) ||*/
      (propName === 'focusAnnotationFillRatio' && isNaN(props.focusAnnotationFillRatio))) {
      return new Error(
        `Invalid prop \`${ propName }\` supplied to` +
      ` \`${ componentName }\`. Validation failed.`
      );
    }
    return false;
  }

  return checkDataOrRequest() && checkTypes();
}

ImageAnnotator.propTypes = ImageAnnotator.propTypes;

export default ImageAnnotator;
