import React, { Component, Fragment } from 'react'
import { Container, Typography, Button, IconButton, TextField, CircularProgress, Paper,
    FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core'
import {
    EditRounded as EditIcon,
    CancelRounded as CancelIcon,
    InfoRounded as InfoIcon,
    CheckRounded as CheckIcon,
    ClearRounded as ClearIcon,
    UndoRounded as UndoIcon
} from '@material-ui/icons'
import ListingPage from '../components/ListingPage'
import Loading from '../components/Loading'
import { getAcquisition, updateAcquisition, createSubject, addImageSample, addNonAnnotationImage, fetchAllImageSamples, getRawImageBlob, getAcquisitionImagesCount, getImageSamples } from '../requests/acquisitions'
import { ACQUISITION_DEFAULT_METADATA, annotationOptions } from '../constants'
import PaginatedAnnotatorContainer from '../components/PaginatedAnnotatorContainer'
import { fetchUsers, getUserInfo } from '../requests/users'
import XLSX from 'xlsx'
import { fetchAnnotations } from '../requests/annotations'
import { getAllDatasets } from '../requests/datasets'
import { excelDate2JSDate } from '../utils/other'

class Acquisition extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: true,
            samples: [],
            metadata: {},
            tags: [],
            samplesNr: undefined,
            assignedUser: undefined,
            watchMode: false,
            annotationMode: false,
            editMode: false,
            detailedInfoMode: false,
            exporting: false,
            // for editing
            availableDatasets: [],
            availableUsers: [],
            sampleId: undefined,
            datasetId: undefined,
            ownerId: undefined,
            nMDkey: undefined,
            nMDval: undefined
        };
        this.annotatedData = []
    }

    UNSAFE_componentWillMount () {
        const acqId = this.props.match.params.id
        getAcquisition(acqId).then(acquisition => {
            let assignedUser = undefined
            if (acquisition.ownerId) {
                getUserInfo(acquisition.ownerId).then(user => {
                    assignedUser = user.username
                }).then(() => {
                    this.setState({
                        assignedUser: assignedUser
                    })
                })
            }
            getAcquisitionImagesCount(acqId).then(samplesNr => {
                fetchAllImageSamples(acqId, {page: 0, size: '-1', sort: 'id'})
                    .then(samples => {
                        this.setState({
                            acqId: acqId,
                            samplesNr: samplesNr,
                            assignedUser: assignedUser,
                            samples: samples, //.slice(0, 10),
                            metadata: acquisition.metadata,
                            tags: acquisition.tags,
                            ownerId: acquisition.ownerId,
                            datasetId: acquisition.datasetId,
                            loading: false
                        })
                    })
            })
            if (!keycloak.hasRealmRole("admin")) {
                this.setState({
                    detailedInfoMode: true
                })
            }
        })
    }

    exportAcquisitionData = async (acqId, acqMetadata) => {
        this.annotatedData = []
        this.annotatedData.push(['Image ID', 'Image Filename', 'annotation Shape', 'Annotation Type', 'points', 'note'])
        this.setState({exporting: true})

        const imageSamples = await getImageSamples(acqId)
        for (let i = 0; i < imageSamples.length; i++) {
            await fetchAnnotations(acqId, imageSamples[i].id).then(annotations => {
                annotations.forEach(annot => {
                    if (['DrawAnnotation', 'PolygonAnnotation'].includes(annot.type)) {
                        this.annotatedData.push([
                            imageSamples[i].id,
                            imageSamples[i].metadata['filename'],
                            annot.type,
                            annotationOptions.find(
                                opt => opt.keyName === annot.annotationObject['text']
                            )['friendlyName'],
                            JSON.stringify(annot.points),
                            annot.notes
                        ])
                    } else {
                        console.log('annot', annot)
                    }
                })
            })
        }

        this.setState({exporting: false})
        // adding worksheets to workbook and exporting file
        const filename = acqMetadata['sampleId'] || acqId
        const sampleSheet = XLSX.utils.aoa_to_sheet(this.annotatedData)
        const new_workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(new_workbook, sampleSheet, "sample's annotations")
        XLSX.writeFile(new_workbook, `${filename}.xlsx`, {})
    }

    fetchAllImageSamples = () => {
        fetchAllImageSamples(this.state.acqId, {page: 0, size: '-1', sort: 'id'})
            .then(samples => {
                this.setState({
                    samples: samples.filter(s => !s.tags.includes('hemogram'))
                })
            })
    }

    componentWillUnmount () {
        this.setState({
            acqId: null,
            subject: {},
            samples: [],
            metadata: {},
            tags: [],
            watchMode: false,
            loading: true,
            exporting: false,
            editMode: false,
            detailedInfoMode: false,
            availableDatasets: [],
            availableUsers: [],
            sampleId: undefined,
            datasetId: undefined,
            ownerId: undefined
        })
    }

    toggleAnnotationMode (watchOnlyMode) {
        this.setState({
            watchMode: watchOnlyMode,
            annotationMode: !this.state.annotationMode
        })
    }

    toggleEditionMode() {
        getAllDatasets().then(rsl2 => {
            this.setState({availableDatasets: rsl2})
        })
        fetchUsers().then(rsl => {
            this.setState({availableUsers: rsl})
        })
        this.setState({editMode: !this.state.editMode})
        this.setState({
            sampleId: this.state.metadata.sampleId
        })
    }

    toggleDetailedInfoMode() {
        this.setState({detailedInfoMode: !this.state.detailedInfoMode})
    }

    toggleDoneUndone() {
        let nDone = (this.state.metadata["done"] === true || this.state.metadata["done"] === "true") ? "false" : "true"
        updateAcquisition(this.state.acqId, {
            metadata: {...this.state.metadata, "done": nDone.toString()}
        }).then(() => {
            this.setState({metadata: {...this.state.metadata, "done": nDone.toString()}})
        })
    }

    readFileAsync (file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()
      
          reader.onload = () => {
            const workbook = XLSX.read(reader.result, {type: 'binary'})
            const firstSheet = workbook.SheetNames[0]
            const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet])
            resolve(excelRows)
          }
      
          reader.onerror = reject
      
          reader.readAsArrayBuffer(file)
        })
    }

    addNewImageSamples = async (acqId, images, files) => {
        let interventionCSV, patientCSV = undefined

        let readers = []
        for (const file of files)
            readers.push(this.readFileAsync(file))

        await Promise.all(readers).then(async (values) => {
            values.forEach(content => {
                if (Object.entries(content[0]).map(el => el[0]).includes("FotografiaPensoSujo")) {
                    interventionCSV = content
                } else {
                    patientCSV = content
                }
            })
        }).then(async () => {
            if (interventionCSV == undefined && patientCSV == undefined) {
                window.confirm('Missing both csv files.\n Only images will be uploaded!')
            } else if (interventionCSV == undefined) {
                window.confirm('Missing csv file for Interventions.\n Only images will be uploaded!')
            } else if (patientCSV == undefined) {
                window.confirm('Missing csv file for Patients.')
            }

            for (let i = 0; i < images.length; i++) {
                let imgName = images[i].name.replaceAll("%3A", ":")
                const imgFile = images[i]

                let f_interventions = undefined
                let f_patients = undefined

                let splitedName = imgName
                if (imgName.includes('-')) {
                    splitedName = imgName.split('-').reverse()[0]
                }

                if (interventionCSV != undefined) {
                    f_interventions = interventionCSV.filter(el => 
                        el['FotografiaPensoSujo'].includes(splitedName) || el['FotografiaFeridaSuja'].includes(splitedName) ||
                        el['FotografiaFeridaLimpa'].includes(splitedName) || el['FotoPensoLimpo'].includes(splitedName))
                
                    if (patientCSV != undefined)
                        f_patients = patientCSV.filter(el => (
                            f_interventions.map(elem =>
                                elem['CodigoUtente'] == el['CodigoUtente'] && elem['IDInstituicao'] == el['IDInstituicao']
                            )
                        ))
                }

                for (let j = 0; j < 2; j++) {
                    try {
                        if (f_interventions !== undefined && f_interventions.length > 0 && f_interventions[0] != undefined)
                            await addImageSample(acqId, this.state.metadata['sampleId'], imgFile, f_interventions[0])
                        else
                            await addImageSample(acqId, this.state.metadata['sampleId'], imgFile, {})
                            await getAcquisition(acqId).then(async(acquisition) => {
                                let acqMD = acquisition.metadata
                                if (f_patients !== undefined && f_patients.length > 0 && f_patients[0] !== undefined) {
                                    acqMD["Fototipo da pele"] = f_patients[0]['FototipoPele'] || ""
                                }
                                if (f_interventions !== undefined && f_interventions.length > 0 && f_interventions[0] !== undefined) {
                                    acqMD["ID da instituição"] = f_interventions[0]['IDInstituicao'] || ""
                                    acqMD["ID do Paciente"] = f_interventions[0]['CodigoUtente']
                                    acqMD["ID da Ferida"] = f_interventions[0]['CodigoFerida'] || ""
                                    acqMD["Tipologia da ferida"] = f_interventions[0]['TipologiaFerida'] || ""
                                    acqMD["Ferida Presente desde"] = excelDate2JSDate(f_interventions[0]['FeridaPresenteDesde']) || ""
                                    acqMD["Localização da ferida"] = f_interventions[0]['ZonaAnatomica'] || ""
                                    acqMD["Data da Primeira Monitorização"] = excelDate2JSDate(f_interventions[0]['DataRegistoFerida']) || ""
                                }
                                await updateAcquisition(acqId, {metadata: {...acqMD}})
                            })
                        break;
                    } catch (err) {
                        if (j === 1) //last attempt
                            throw err;
                    }
                }
            }
        }).then(async() => {
            this.UNSAFE_componentWillMount()
        })
    }

    setSubject (newSubj) {
        this.setState({subject: newSubj})
    }

    render () {
        if (this.state.loading) {
            return <Loading />
        } else {
            return (
                <Container style={{marginTop: '3rem'}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '2rem'
                    }} >
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant='h4'>
                                Acquisition {this.state.metadata['sampleId']}
                            </Typography>
                            {this.state.assignedUser && (
                                <Typography variant='h6'>
                                    {this.state.assignedUser}
                                </Typography>
                            )}
                        </div>
                        <div>
                            <div className='iconsRight'>
                                {(!this.state.editMode && keycloak.hasRealmRole('admin')) && (
                                    <IconButton onClick={() => this.toggleEditionMode()}>
                                        <EditIcon />
                                    </IconButton>
                                )}
                                <IconButton onClick={() => this.toggleDetailedInfoMode()}>
                                    <InfoIcon />
                                </IconButton>
                            </div>
                        {this.state.annotationMode ? (
                            <Button
                                className="coloredButton"
                                style={{height: 'min-content', padding: '0.5rem 1rem'}}
                                onClick={() => this.toggleAnnotationMode(false)}
                            >
                                <Typography variant='body1'>
                                    Go Back to Acquisition Summary
                                </Typography>
                            </Button>
                        ) : (
                            this.state.samples.length > 0 && keycloak.hasRealmRole('admin') && (
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <div style={{display: 'flex', flexBasis: 1, justifyContent: 'space-between', flexDirection: 'row'}}>
                                        <Button
                                            className="coloredButton"
                                            style={{marginRight: '2rem', height: 'min-content', padding: '0.5rem 1rem'}}
                                            onClick={() => this.toggleAnnotationMode(true)}
                                        >
                                            <Typography variant="body2">
                                                Watch Annotations
                                            </Typography>
                                        </Button>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <Button
                                            className="coloredButton"
                                            style={{marginRight: '0rem', height: 'min-content', padding: '0.5rem 1rem'}}
                                            onClick={() => this.toggleAnnotationMode(false)}
                                        >
                                            <Typography variant='body2'>
                                                Annotate Acquisition
                                            </Typography>
                                        </Button>
                                        <Button
                                            disabled={this.state.exporting}
                                            className="coloredButton"
                                            style={{marginTop: '1rem', marginRight: '0rem', height: 'min-content', padding: '0.5rem 1rem'}}
                                            onClick={() => this.exportAcquisitionData(this.state.acqId, this.state.metadata)}
                                        >
                                            {this.state.exporting ? (
                                                <CircularProgress size={18} style={{color: '#fff'}} />
                                            ) : (
                                                <Typography variant='body2'>
                                                    Export Annotated Data
                                                </Typography>
                                            )}
                                        </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                        
                        </div>
                    </div>

                    {/* MARK AS DONE/UNDONE */}
                    {!this.state.annotationMode && keycloak.hasRealmRole("admin") && (
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.metadata["done"] == "true"}
                                        onChange={() => this.toggleDoneUndone()}
                                        color="primary"
                                    />
                                }
                                label="Mark acquisition as done/undone"
                            />
                        </div>
                    )}

                    {/* SHOW DETAILED INFO */}
                    {this.state.detailedInfoMode && (
                        <Paper className="metadataPaper">
                            <Typography variant='body1'>
                                <u>Informação de base </u>
                            </Typography>
                            {Object.entries(this.state.metadata).map((row, idx) => {
                                if (!["sampleId", "observations", "done"].includes(row[0])) {
                                    if (keycloak.hasRealmRole("admin") && !this.state.annotationMode) {
                                        return (
                                            <MetadataRow mdkey={row[0]} mdvalue={row[1]} updateMetadaDataRow={(nk, nv) => {
                                                var array = Object.entries(this.state.metadata)
                                                array.splice(idx, 1)
                                                let nMtDt = Object.fromEntries(array)
                                                updateAcquisition(this.state.acqId, {
                                                    metadata: {...nMtDt, [nk]: nv}
                                                }).then(() => {
                                                    this.setState({
                                                        metadata: {...nMtDt, [nk]: nv},
                                                        detailedInfoMode: false
                                                    })
                                                })
                                            }} removeMetadata={() => {
                                                var array = Object.entries(this.state.metadata)
                                                array.splice(idx, 1)
                                                let nMtDt = Object.fromEntries(array)
                                                updateAcquisition(this.state.acqId, {
                                                    metadata: nMtDt
                                                }).then(() => {
                                                    this.setState({
                                                        metadata: nMtDt
                                                    })
                                                })
                                            }} />
                                        )
                                    }
                                }
                            })}
                            {(!keycloak.hasRealmRole("admin") || this.state.annotationMode) && Object.entries(ACQUISITION_DEFAULT_METADATA).map((row, idx) => {
                                if (!["sampleId", "observations", "done"].includes(row[0])) {
                                    return (
                                        <div className='metadataRow'>
                                            <b>{row[0]}: </b>
                                            <p>
                                                {this.state.metadata[row[0]]}
                                            </p>
                                        </div>
                                    )
                                }
                            })}

                            {/*EMPTY ROW TO ADD NEW METADATA*/}
                            {(keycloak.hasRealmRole("admin") && !this.state.annotationMode) && (
                                <div className='metadataRow'>
                                    <form>
                                        <TextField required
                                            label='Metadata key'
                                            value={this.state.nMDkey}
                                            onChange={event => this.setState({nMDkey: event.target.value})}
                                            margin='normal'
                                            variant='outlined'
                                        />
                                        <TextField required
                                            label='Metadata value'
                                            value={this.state.nMDval}
                                            onChange={event => this.setState({nMDval: event.target.value})}
                                            margin='normal'
                                            variant='outlined'
                                        />
                                        <IconButton onClick={() => {
                                            let nMD = {
                                                ...this.state.metadata,
                                                [this.state.nMDkey]: this.state.nMDval
                                            }
                                            updateAcquisition(this.state.acqId, {metadata: nMD}).then(() => {
                                                this.setState({
                                                    metadata: nMD,
                                                    nMDkey: "",
                                                    nMDval: ""
                                                })
                                            })
                                        }}>
                                            <CheckIcon />
                                        </IconButton>
                                        <IconButton onClick={() => {
                                            this.setState({
                                                nMDkey: "",
                                                nMDval: ""
                                            })
                                        }}>
                                            <ClearIcon />
                                        </IconButton>
                                    </form>
                                </div>
                            )}
                        </Paper>
                    )}


                    {/* EDIT ACQUISITION FORM */}
                    {(this.state.editMode && keycloak.hasRealmRole('admin')) && (
                        <Fragment>
                            <div>
                                <Paper className='acquisitionForm' style={{marginBottom: '3rem', padding: '2rem 3rem'}}>
                                    <form style={{display: 'flex', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem'}}>
                                        <TextField required
                                            label='Sample ID'
                                            value={this.state.sampleId}
                                            onChange={event => this.setState({sampleId: event.target.value})}
                                            margin='normal'
                                            variant='outlined'
                                        />
                                        <div className='dropdownElements' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                            <FormControl variant='outlined'>
                                                <InputLabel htmlFor="outlined-datasetId">
                                                    Dataset Id
                                                </InputLabel>
                                                <Select
                                                    disableUnderline
                                                    value={this.state.datasetId}
                                                    onChange={ev => this.setState({datasetId: ev.target.value})}
                                                    inputProps={{
                                                        name: 'Dataset Id',
                                                        id: 'outlined-datasetId',
                                                    }}
                                                >
                                                    <MenuItem value=''><em>None</em></MenuItem>
                                                    {this.state.availableDatasets.map(dataset =>
                                                        <MenuItem key={dataset.id} value={dataset.id}>
                                                            {dataset.name}
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>

                                            <FormControl variant='outlined'>
                                                <InputLabel htmlFor="outlined-ownerId">
                                                    owner Id
                                                </InputLabel>
                                                <Select
                                                    disableUnderline
                                                    value={this.state.ownerId}
                                                    onChange={ev => this.setState({ownerId: ev.target.value})}
                                                    inputProps={{
                                                        name: 'Dataset',
                                                        id: 'outlined-ownerId',
                                                    }}
                                                >
                                                    <MenuItem value=''><em>None</em></MenuItem>
                                                    {this.state.availableUsers.map(user =>
                                                        <MenuItem key={user.id} value={user.id}>
                                                            {(user['firstName'] && user['lastName']) ? (
                                                                user['firstName'] + ' ' + user['lastName']
                                                            ) : (
                                                                user['username']
                                                            )}
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </form>
                                    <div className='iconsRight'>
                                        <Button className='outlinedButton' onClick={() => this.toggleEditionMode()}>
                                            <Typography>
                                                CANCEL
                                            </Typography>
                                        </Button>
                                        <Button
                                            variant='contained'
                                            className="formBtn"
                                            onClick={() => {
                                                // UPDATE ACQUISITION
                                                updateAcquisition(this.state.acqId, {
                                                    metadata: {...this.state.metadata, sampleId: this.state.sampleId},
                                                    datasetId: this.state.datasetId,
                                                    ownerId: this.state.ownerId
                                                }).then(() => {
                                                    this.setState({
                                                        acqId: this.state.sampleId,
                                                        metadata: {...this.state.metadata, sampleId: this.state.sampleId},
                                                        datasetId: this.state.datasetId,
                                                        ownerId: this.state.ownerId,
                                                        assignedUser: this.state.ownerId

                                                    })
                                                }).then(() => {
                                                    this.toggleEditionMode()
                                                })
                                            }}
                                        >
                                            Update Acquisition
                                        </Button>
                                    </div>
                                </Paper>

                            </div>
                        </Fragment>
                    )}

                    {(this.state.annotationMode || !keycloak.hasRealmRole('admin')) ? (
                        //TODO: add 'watch-only' mode
                        <PaginatedAnnotatorContainer
                            acquisitionId={this.state.acqId}
                            acqMetadata={this.state.metadata}
                            acqTags={this.state.tags}
                            samples={this.state.samples}
                            watchMode={keycloak.hasRealmRole('admin') ? this.state.watchMode : false}
                        />
                    ) : (
                        <Fragment>
                            {keycloak.hasRealmRole('admin') && (
                                <ListingPage
                                    resultsType={'Samples'}
                                    counter={this.state.samplesNr}
                                    results={this.state.samples.filter(sample => !sample.tags.includes("hemogram"))}
                                    additionalData={{acqId: this.state.acqId}}
                                    addNew={(images, files) => this.addNewImageSamples(this.state.acqId, images, files)}
                                    fetchAllImageSamples={() => this.fetchAllImageSamples()}
                                />
                            )}
                        </Fragment>
                    )}
                </Container>
            )
        }
    }
}

class SubjectPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            subject: {
                firstName: '',
                lastName: '',
                clinicalHistory: '',
                demographicData: '',
                hemogramFile: undefined
            },
            editionMode: false,
            imgBlob: undefined
        }
    }

    async componentDidMount() {
        if (this.props.hemogramFile) {
            await getRawImageBlob(this.props.acquisitionId, this.props.hemogramFile['id'])
            .then(blob => {
                this.setState({
                    ...this.props,
                    imgBlob: blob
                })
            })
        } else {
            this.setState({...this.props})
        }
    }
    
    render() {
        return (
            <Fragment>
                {keycloak.hasRealmRole('admin') && this.state.editMode ? (
                    <Fragment>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <IconButton onClick={() => {
                                this.toggleEditionMode()
                            }}>
                                <CancelIcon />
                            </IconButton>
                        </div>
                        <div>
                            <form style={{display: 'flex', flexDirection: 'column', marginBottom: '1rem'}}>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <TextField
                                        label='First Name'
                                        value={this.state.firstName}
                                        onChange={event => this.setState({firstName: event.target.value})}
                                        style={{width: '48%'}}
                                        margin='normal'
                                        variant='outlined'
                                    />
                                    <TextField
                                        label='Last Name'
                                        value={this.state.lastName}
                                        onChange={event => this.setState({lastName: event.target.value})}
                                        style={{width: '48%'}}
                                        margin='normal'
                                        variant='outlined'
                                    />
                                </div>
                                <TextField
                                    label='Clinical History'
                                    value={this.state.clinicalHistory}
                                    onChange={event => this.setState({clinicalHistory: event.target.value})}
                                    margin='normal'
                                    variant='outlined'
                                    multiline
                                    rows='2'
                                    rowsMax='4'
                                />
                                <TextField
                                    label='Demographic Data'
                                    value={this.state.demographicData}
                                    onChange={event => this.setState({demographicData: event.target.value})}
                                    margin='normal'
                                    variant='outlined'
                                    multiline
                                    rows='2'
                                    rowsMax='4'
                                />
                                <Fragment>
                                    <input
                                        accept="image/*"
                                        style={{display: 'none'}}
                                        onChange={(event) => {
                                            const filess = event.target.files[0]
                                            this.setState({hemogramFile: event.target.files[0]})
                                        }}
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                    />
                                    <label htmlFor="contained-button-file" >
                                        <Button variant="contained" component="span">
                                            Upload
                                        </Button>
                                        {this.state.hemogramFile && (
                                            <Fragment>
                                                <Typography style={{marginLeft: '2rem'}}>{this.state.hemogramFile['name']}</Typography>
                                            </Fragment>
                                        )}
                                    </label> 
                                </Fragment>
                            </form>
                            <Button variant='contained'
                                style={{backgroundColor: '#009474', color: '#fff'}}
                                onClick={() => {
                                    const newImage = this.state.hemogramFile
                                    let newSubject = {
                                        type: 'HumanSubject',
                                        description: '',
                                        metadata: {
                                            clinicalHistory: this.state.clinicalHistory,
                                            demographicData: this.state.demographicData,
                                            hemogram: this.state.hemogramFile['name']
                                        },
                                        tags: [],
                                        firstName: this.state.firstName,
                                        lastName: this.state.lastName,
                                        birthdateTimestamp: "null"
                                    }
                                    createSubject(this.props.acquisitionId, newSubject).then(() => {
                                        this.props.setSubject(newSubject)
                                    })
                                    if (this.state.hemogramFile instanceof File) {
                                        addNonAnnotationImage(this.props.acquisitionId, this.state.hemogramFile)
                                        .then(async (newHemogramFile) => {
                                            await getRawImageBlob(this.props.acquisitionId, newHemogramFile['id']).then(blob => {
                                                this.setState({
                                                    hemogramFile: newHemogramFile,
                                                    imgBlob: blob
                                                })
                                            })
                                        })
                                    }
                                    this.toggleEditionMode()
                                }}
                            >
                                Update Acquisition Subject
                            </Button>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        {keycloak.hasRealmRole('admin') &&
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <Typography variant='h6' style={{marginBottom: '0.2rem'}}>Acquisition's Subject: </Typography>
                                <IconButton onClick={() => {
                                    this.toggleEditionMode()
                                }}>
                                    <EditIcon />
                                </IconButton>
                                <Typography>
                                    {this.state.editMode}
                                </Typography>
                            </div>
                        }
                        {this.state.editMode && (
                            <div style={{backgroundColor: "yellowgreen"}}>
                                <Typography>CHANGE STUFF ABOUT ACQUISITION</Typography>
                            </div>
                        )}
                        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: '1.5rem'}}>
                            <div style={{marginRight: '2rem'}}>
                                <Typography variant='body1' style={{fontWeight: 'bold'}}>Subject's Name: </Typography>
                                <Typography variant='body1' style={{paddingLeft: '1.5rem'}}>
                                    {(this.state.firstName || this.state.lastName)
                                        ? this.state.firstName + ' ' + this.state.lastName 
                                        : 'unknown'
                                    }
                                </Typography>
                            </div>
                            <div style={{marginRight: '2rem'}}>
                                <Typography variant='body1' style={{fontWeight: 'bold'}}>Clinical History: </Typography>
                                <Typography variant='body1' style={{paddingLeft: '1.5rem'}}>{this.state.clinicalHistory || 'unknown'}</Typography>
                            </div>
                            <div style={{marginRight: '2rem'}}>
                                <Typography variant='body1' style={{fontWeight: 'bold'}}>Demographic Data: </Typography>
                                <Typography variant='body1' style={{paddingLeft: '1.5rem'}}>{this.state.demographicData || 'unknown'}</Typography>
                            </div>

                            <div style={{marginRight: '2rem'}}>
                                <Typography variant='body1' style={{fontWeight: 'bold'}}>Hemogram: </Typography>
                                {this.state.hemogramFile
                                    ? <img
                                        src={this.state.imgBlob}
                                        style={{maxWidth: '50%', marginLeft: '1.5rem'}}
                                        alt={'hemogram'}
                                    />
                                    : <Typography variant='body1' style={{paddingLeft: '1.5rem'}}>undefined</Typography>
                                }
                            </div>
                        </div>
                    </Fragment>
                )}
            </Fragment>
        )
    }
}

class MetadataRow extends Component {
    constructor (props) {
        super(props)
        this.state = {
            changed: false,
            nMDkey: undefined,
            nMDval: undefined
        }
    }

    componentDidMount() {
        this.setState({
            changed: false,
            nMDkey: this.props.mdkey,
            nMDval: this.props.mdvalue
        })
    }

    render () {
        return (
            <div className='metadataRow'>
                <form>
                    <TextField required
                        label="metadata key"
                        value={this.state.nMDkey}
                        onChange={event => this.setState({
                            nMDkey: event.target.value,
                            changed: true
                        })}
                        margin='normal'
                        variant='outlined'
                    />
                    <TextField required
                        label="metadata value"
                        value={this.state.nMDval}
                        onChange={event => this.setState({
                            nMDval: event.target.value,
                            changed: true
                        })}
                        margin='normal'
                        variant='outlined'
                    />
                    {this.state.changed && (
                        <IconButton onClick={() => {
                            this.props.updateMetadaDataRow(this.state.nMDkey, this.state.nMDval)
                            this.setState({changed: false})
                        }}>
                            <CheckIcon />
                        </IconButton>
                    )}
                    {this.state.changed && (
                        <IconButton onClick={() => {
                            this.setState({
                                nMDkey: this.props.mdkey,
                                nMDval: this.props.mdvalue,
                                changed: false
                            })
                        }}>
                            <UndoIcon />
                        </IconButton>
                    )}
                    <IconButton onClick={() => {
                        this.props.removeMetadata()
                    }}>
                        <ClearIcon />
                    </IconButton>
                </form>
            </div>
        )
    }
}

export default Acquisition