import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Rect, Group } from 'react-konva';
import { getPointerPositionThoughAnnotator } from '../../../utils/Utils';
import SelectionRectangle from './SelectionRectangle';

import store from './../../../models/annotations-store';
import appPropStore from './../../../models/app-properties-store';
import AnnotationFactory from '../AnnotationFactory';

class SelectionTool extends Component {
  constructor () {
    super();
    this.state = {
      coordDown: { x: 0, y: 0 },
      tempShape: null
    };
    this._onMouseDown = this._onMouseDown.bind(this);
    this._onMouseUp = this._onMouseUp.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onTouchStart = this._onTouchStart.bind(this);
    this._onTouchEnd = this._onTouchEnd.bind(this);
    this._onTouchMove = this._onTouchMove.bind(this);

    // Values to aid the calculation of the popup position
    this.mostLeft = this.mostRight = this.mostTop = this.mostBottom = null;
  }

  componentWillUnmount () {
    this.deselectAllAnnotations();
  }

  restartDrawing () {
    this.mostTop = this.mostLeft = this.mostRight = this.mostBottom = null;
    this.setState({ tempShape: null });
  }

  //Mouse Inputs
  _onMouseDown (e) {
    if (e.evt.shiftKey) {
      e.evt.stopPropagation();
      return;
    }
    if (!appPropStore.isDrawingKeyDown) {
      return;
    }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    this.setState({
      coordDown: { x: pointerPosition.x, y: pointerPosition.y },
      tempShape: { internalAttributes: { x: pointerPosition.x, y: pointerPosition.y } }
    });
  }

  _onMouseUp (e) {
    if (!appPropStore.isDrawingKeyDown) {
      this.restartDrawing();
      return;
    }
    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    let xCoord = pointerPosition.x;
    let yCoord = pointerPosition.y;
    let width = xCoord - this.state.coordDown.x;
    let height = yCoord - this.state.coordDown.y;

    //Size must be bigger than 5x5
    if (Math.abs(width) <= 2 || Math.abs(height) <= 2) {
      this.restartDrawing();
      return;
    }

    this.hitCheck();
  }

  calculatePopupPosition () {
    let position = {
      top: this.mostTop,
      left: this.mostRight + 10
    };
    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (this.mostTop + promptSize > window.innerHeight) {
      position.top = Math.max(0, this.mostBottom - promptSize);
    }
    if (this.mostRight + 360 > window.innerWidth) {
      if (this.mostLeft - 360 > 0) {
        position.left = this.mostLeft - 360;
      } else {
        position.left = ((this.mostRight + this.mostLeft) / 2) - 360 / 2;
        if (position.top === this.mostTop) {
          if (this.mostBottom + promptSize < window.innerHeight) { position.top = this.mostBottom + 10;} else {position.top = (this.mostBottom + this.mostTop) / 2 - 360 / 2;}
        }
      }
    }
    return position;
  }

  _onMouseMove (e) {
    if (this.state.tempShape == null) return;
    else if (!appPropStore.isDrawingKeyDown || e.evt.buttons !== 1) {this.setState({ tempShape: null }); return;}

    // Update positions for the calculation of the popup
    if (!this.mostTop || this.mostTop > e.evt.clientY) this.mostTop = e.evt.clientY;
    if (!this.mostBottom || this.mostBottom < e.evt.clientY) this.mostBottom = e.evt.clientY;
    if (!this.mostLeft || this.mostLeft > e.evt.clientX) this.mostLeft = e.evt.clientX;
    if (!this.mostRight || this.mostRight < e.evt.clientX) this.mostRight = e.evt.clientX;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    let xCoord = pointerPosition.x;
    let yCoord = pointerPosition.y;
    let width = xCoord - this.state.coordDown.x;
    let height = yCoord - this.state.coordDown.y;
    //if(Math.abs(width) <= 5 || Math.abs(height) <= 5) return;

    this.setState({
      tempShape: {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          height: height, width: width
        },
        color: 'white'
      }
    });
  }

  //Touch Inputs
  _onTouchStart (e) {
    if (e.evt.shiftKey) {
      e.evt.stopPropagation();
      return;
    }
    if (!appPropStore.isDrawingKeyDown || e.evt.touches.length !== 1) {
      return;
    }

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    this.setState({
      coordDown: { x: pointerPosition.x, y: pointerPosition.y },
      tempShape: { internalAttributes: { x: pointerPosition.x, y: pointerPosition.y } }
    });
  }

  _onTouchEnd (e) {
    if (!appPropStore.isDrawingKeyDown) {
      this.restartDrawing();
      return;
    }
    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    let xCoord = pointerPosition.x;
    let yCoord = pointerPosition.y;
    let width = xCoord - this.state.coordDown.x;
    let height = yCoord - this.state.coordDown.y;

    //Size must be bigger than 5x5
    if (Math.abs(width) <= 2 || Math.abs(height) <= 2) {
      this.restartDrawing();
      return;
    }

    this.hitCheck();
  }

  _onTouchMove (e) {
    if (this.state.tempShape == null) return;
    else if (!appPropStore.isDrawingKeyDown || e.evt.touches.length !== 1) {this.setState({ tempShape: null }); return;}

    // Update positions for the calculation of the popup
    if (!this.mostTop || this.mostTop > e.evt.clientY) this.mostTop = e.evt.clientY;
    if (!this.mostBottom || this.mostBottom < e.evt.clientY) this.mostBottom = e.evt.clientY;
    if (!this.mostLeft || this.mostLeft > e.evt.clientX) this.mostLeft = e.evt.clientX;
    if (!this.mostRight || this.mostRight < e.evt.clientX) this.mostRight = e.evt.clientX;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    let xCoord = pointerPosition.x;
    let yCoord = pointerPosition.y;
    let width = xCoord - this.state.coordDown.x;
    let height = yCoord - this.state.coordDown.y;
    //if(Math.abs(width) <= 5 || Math.abs(height) <= 5) return;

    this.setState({
      tempShape: {
        internalAttributes: {
          x: this.state.coordDown.x, y: this.state.coordDown.y,
          height: height, width: width
        },
        color: 'white'
      }
    });
  }

  hitCheck () {
    if (this.state.tempShape === null) return;
    let tempShape = { ...this.state.tempShape.internalAttributes };
    tempShape.x = tempShape.x * appPropStore.scale + appPropStore.offset.x;
    tempShape.y = tempShape.y * appPropStore.scale + appPropStore.offset.y;
    tempShape.width = tempShape.width * appPropStore.scale;
    tempShape.height = tempShape.height * appPropStore.scale;
    let tX = tempShape.width > 0 ? tempShape.x : tempShape.x + tempShape.width;
    let tA = tempShape.width > 0 ? tempShape.x + tempShape.width : tempShape.x;
    let tY = tempShape.height > 0 ? tempShape.y : tempShape.y + tempShape.height;
    let tB = tempShape.height > 0 ? tempShape.y + tempShape.height : tempShape.y;

    let annotations = this.props.shapesLayerRef.current.children;
    let selectedAnnotationsIds = [];
    for (let i = 0; i < annotations.length; i++) {
      if (annotations[i] === undefined) return;
      let annotationRect = annotations[i].getClientRect();

      let X = annotationRect.x;
      let A = annotationRect.x + annotationRect.width;
      let Y = annotationRect.y;
      let B = annotationRect.y + annotationRect.height;

      // if (A < tX || tA < X || B < tY || tB < Y) {
      //   //return null;
      // } else
      if (X > tX && A < tA && Y > tY && B < tB) {
        let id = annotations[i].children[0].children && annotations[i].children[0].children.length ? annotations[i].children[0].children[0].attrs.id : annotations[i].children[0].attrs.id;
        selectedAnnotationsIds.push(id);
      }
    }
    if (selectedAnnotationsIds.length === 0) return;

    this.highlightAnnotations(selectedAnnotationsIds, true);

    // let position = this.calculatePopupPosition();
    // // Open the popup
    // Popup.plugins().multipleAnnotationsSelected(
    //   position,
    //   store.annotationCategories,
    //   (notes, annotationType) => this.updateAllAnnotations(selectedAnnotationsIds, notes, annotationType),
    //   () => this.deleteAllAnnotations(selectedAnnotationsIds),
    //   () => {this.restartDrawing(); this.highlightAnnotations(selectedAnnotationsIds, false);}
    // );
  }

  highlightAnnotations (selectedAnnotationsIds, highlightValue) {
    for (let i = 0; i < selectedAnnotationsIds.length; i++) {
      store.getAnnotationById(selectedAnnotationsIds[i]).toggleSelected(highlightValue);
    }
  }

  deleteAllAnnotations (selectedAnnotationsIds) {
    for (let i = 0; i < selectedAnnotationsIds.length; i++) {
      this.props.removeAnnotation(selectedAnnotationsIds[i]);
    }
  }

  updateAllAnnotations (selectedAnnotationsIds, notes, annotationType) {
    for (let i = 0; i < selectedAnnotationsIds.length; i++) {
      let annotation = store.getAnnotationById(selectedAnnotationsIds[i]);
      this.props.updateAnnotationInfo(annotation, notes, annotationType);
    }
    this.forceUpdate();
  }

  calculatePopupPosition () {
    let position = {
      top: this.mostTop,
      left: this.mostRight + 10
    };
    let promptSize = 163 + 5 + 25 * store.annotationCategories.length;// componentSize=163, CategoriesMargin=5,*CategorySize=25;
    if (this.mostTop + promptSize > window.innerHeight) {
      position.top = Math.max(0, this.mostBottom - promptSize);
    }
    if (this.mostRight + 360 > window.innerWidth) {
      if (this.mostLeft - 360 > 0) {
        position.left = this.mostLeft - 360;
      } else {
        position.left = ((this.mostRight + this.mostLeft) / 2) - 360 / 2;
        if (position.top === this.mostTop) {
          if (this.mostBottom + promptSize < window.innerHeight) { position.top = this.mostBottom + 10;} else {position.top = (this.mostBottom + this.mostTop) / 2 - 360 / 2;}
        }
      }
    }
    return position;
  }

  _onClick (e) {
    if (this.state.tempShape !== null || e.evt.button !== 0) return;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    this.clickHitCheck(pointerPosition);
  }

  _onDblTap (e) {
    if (this.state.tempShape !== null) return;

    let pointerPosition = getPointerPositionThoughAnnotator(appPropStore.annotatorRef, appPropStore.scale, appPropStore.offset);
    this.clickHitCheck(pointerPosition);
  }

  clickHitCheck (position) {
    let tX = position.x * appPropStore.scale + appPropStore.offset.x;
    let tY = position.y * appPropStore.scale + appPropStore.offset.y;

    if (this.props.shapesLayerRef.current === null) return;
    let annotations = this.props.shapesLayerRef.current.children;
    let selectedAnnotationsIds = [];
    for (let i = 0; i < annotations.length; i++) {
      if (annotations[i] === undefined) return;
      let annotationRect = annotations[i].getClientRect();

      if (AnnotationFactory.isPointInsideAnnotation('Rect', annotationRect, { x: tX, y: tY })) {
        const id = annotations[i].children[0].children && annotations[i].children[0].children.length > 0 ? annotations[i].children[0].children[0].attrs.id : annotations[i].children[0].attrs.id;

        // Point is inside annotation bounding rect, but we should call preciseHitCheck to verify if it's inside the annotation
        if (this.preciseHitCheck({ x: position.x, y: position.y }, id)) { selectedAnnotationsIds.push(id); }
      }
    }
    if (selectedAnnotationsIds.length === 0) this.deselectAllAnnotations();
    else this.highlightAnnotations([selectedAnnotationsIds[selectedAnnotationsIds.length - 1]], null);
  }

  preciseHitCheck (hitPos, id) {
    const storeAnnotation = store.getAnnotationById(id);
    const annotation = { ...storeAnnotation.annotation };
    const internalAttributes = { ...storeAnnotation.internalAttributes };

    if (annotation.shape === 'Rect') return true; // Assumes the bounding box has the same limits as the annotation, this will save processing power
    else if (annotation.shape === 'Point') return AnnotationFactory.isPointInsideAnnotation('Circle', { ...internalAttributes, radius: appPropStore.dotSize / appPropStore.scale }, hitPos);
    else return AnnotationFactory.isPointInsideAnnotation(annotation.shape, internalAttributes, hitPos);
  }

  deselectAllAnnotations () {
    store.annotations.forEach(annotation => annotation.toggleSelected(false));
  }

  render () {
    return (
      <Group>
        {/*Temp Rect*/}
        {this.state.tempShape != null &&
            <SelectionRectangle {...this.state.tempShape} />
        }
        {/*Renders a canvas for drawing (listening to mouse inputs)*/}
        <Rect
          {...appPropStore.userImageDimensions}
          onMouseMove={this._onMouseMove} onMouseDown={this._onMouseDown} onMouseUp={this._onMouseUp} onClick={this._onClick.bind(this)}
          onTouchMove={this._onTouchMove} onTouchStart={this._onTouchStart} onTouchEnd={this._onTouchEnd} onTap={this._onDblTap.bind(this)} onDblTap={this._onDblTap.bind(this)}
          transformsEnabled={'none'}
        />
      </Group>
    );
  }
}

SelectionTool.propTypes = {
};


export default SelectionTool;
