import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import imageToolsStore from '../../../models/image-tools-store';
import { BsGearFill } from 'react-icons/bs';

import ImageToolsPopover from './ImageToolsPopover';

@observer
class ImageTools extends Component {
  static propTypes = {
  };

  constructor () {
    super();

    this.state = {
      anchorEl: null
    };
  }

  handleClick = event => {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  setAnchorEl = (val) => {
    this.setState({ anchorEl: val });
  }

  render () {
    const { anchorEl } = this.state;
    return (
      <div key="ImageTools">
        <div style={{ fontSize: 'x-small', marginTop: '10px', marginBottom: '10px' }}><b style={{ fontSize: 'x-small' }} >Image</b></div>
        <label
          onClick={this.handleClick}
          className={'btn btn-default btn-sm'} title="Image settings"
        >
          <BsGearFill />
        </label>

        <ImageToolsPopover
          anchorEl={anchorEl}
          onClose={this.handleClose}

          tools={imageToolsStore.availableImageTools}
        />
      </div>
    );
  }
}


ImageTools.propTypes = ImageTools.propTypes;
export default ImageTools;
